import React, { useState, useEffect } from "react";
import Header from "../../../Components/clubDashboard/Header/Header";
import "./EventManagement.scss";
import { Modal, ModalBody, ModalHeader, Button, ModalFooter } from "reactstrap";
import { NavLink, Link } from "react-router-dom";
import AddScore from "./AddScore";
import getLocalData from "../../../shared/localStorage/getData";
import combinedServices from "../../../shared/services/user-service";
import userConstants from "../../../shared/constants/user-constants";
import Pagination from "react-js-pagination";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { Spinner } from "reactstrap";
import SearchPage from "../../../Components/clubDashboard/Header/SearchPage";
import getRemoveData from "../../../shared/localStorage/removeData";
import Switch from "react-switch";
import moment from "moment-timezone";
import { CircularProgress } from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";

const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

moment.tz.setDefault("timeZone");
var eventArr = [];
var responses1 = {};
const EventManagement = () => {
  // Modal open state
  let navigate = useNavigate();
  const [modal, setModal] = React.useState(false);
  const [clubUserDetails, setUserClubDetail] = useState([]);
  const [hostClubIds, setHostClubIds] = useState("");
  const [eventDetails, setEventDetails] = useState([]);
  const [pageRecord, setPageRecord] = useState(10);
  const [page, setPage] = useState(1);
  const [totalRecord, setTotalRecord] = useState("");
  const [baseURL, setBaseUrl] = useState(userConstants.baseURL);
  const [currentDistric, setCurrentDistric] = useState("");
  const [eventId, setEventId] = useState("");
  const [isLoder, setIsLoder] = useState(true);
  const [checked, setChecked] = useState(false);
  const [eventStatus, setEventStatus] = useState([
    "ongoing",
    "upcoming",
    "completed",
    "archived",
  ]);

  const [userId, setUserId] = useState(0);
  const [getUserId, setGetUserId] = useState(0);
  const [districDirector, setDistricDirector] = useState(false);
  const [eStatus, setEStatus] = useState("event_status=upcoming");
  // const [eventDetails, setEventDetails] = useState([]);
  const [eventResponses, setEventResponses] = useState({});
  const [heatResponse, setHeatResponse] = useState({});
  const [timezone, setTimezone] = useState("");
  const [open, setOpen] = useState(false);
  const [endDate, setEndDate] = useState("");
  const [openDialogId, setOpenDialogId] = useState(null);
  const [currentEventStatus, setCurrentEventStatus] = useState("");

  const AltImg =
    "https://nambaweb.s3.ap-south-1.amazonaws.com/frontend-static-image/logo.png";
  // Toggle for Modal
  const toggle = (id) => {
    setEventId(id);
    setModal(!modal);
  };

  useEffect(async () => {
    var host_club_id = [];

    if (getLocalData("user_info", "isLogin")) {
      var userId = getLocalData("user_info", "all").userData.login_user_detail
        .data.user.id;
      var userDistricId = getLocalData("user_info", "all").userData
        .login_user_detail.data.user.district.uzip_namba_district;
      var districtDirectors = getLocalData("user_info", "all").userData
        .login_user_detail.data.user.districtDirector;
      setGetUserId(userId);

      if (districtDirectors) {
        setDistricDirector(true);
      }

      setCurrentDistric(userDistricId);
      setUserId(userId);
      var data = {
        user_id: userId,
        clubs: true,
        events: true,
        district: true,
      };
      let profileResponse = await combinedServices.getProfileDetails(data);
      if (profileResponse.status == 401) {
        swal(profileResponse.msg, "Please Re-login", "warning").then(() => {
          getRemoveData("user_info");
          localStorage.clear();
          window.location.href = "/login";
        });
      }
      if (profileResponse.status == 200) {
        let userDetailData = profileResponse.data;

        setUserClubDetail(userDetailData.club_members);
        userDetailData.club_members?.map((value) => {
          host_club_id.push(value.club.id);
        });

        setHostClubIds(host_club_id);
      }
      getAllEventDetails(userDistricId, host_club_id, userId);
    }

    // checkScheduler();
    // await checkheat();
  }, []);

  // useEffect(() => {
  //   if (eventDetails?.length > 0) {
  //     checkScheduler();
  //     checkheat();
  //   }
  // }, [eventDetails]);

  const handleLocationByDistric = async () => {
    var response = await combinedServices.getMyClubSite(currentDistric);

    if (response.data) {
      navigate("../event-management/create-event", { replace: true });
    } else if (response.status == 401) {
      swal("!Opps", response.msg, "error").then(async (value) => {
        getRemoveData("user_info");
        window.location.href = "/";
      });
    } else {
      swal("!Opps", "There is not any sanctioned lake is available", "error");
    }
  };

  // handle event Filter
  const handleEvent = async (e) => {
    setCurrentEventStatus(e.target.value);
    if (getLocalData("user_info", "isLogin")) {
      var userId = getLocalData("user_info", "all").userData.login_user_detail
        .data.user.id;
      var userDistricId = getLocalData("user_info", "all").userData
        .login_user_detail.data.user.district.uzip_namba_district;
      var districtDirectors = getLocalData("user_info", "all").userData
        .login_user_detail.data.user.districtDirector;

      var data = {
        host_club_id: "",
        // district_id: userDistricId,
        page: 1,
        records_per_page: pageRecord,
        user_id: userId,
        sort_by: "event_end",
        event_status: e.target.value,
      };
    }

    let eventResponse = await combinedServices.getAllEvent(data);

    if (eventResponse.status == 200) {
      eventArr = [];
      setTotalRecord(eventResponse.totalCount);

      eventResponse.data.map((events) => {
        var data = {
          boatsCount: events.boatsCount,
          image: events.event_image.startsWith("https://nambaweb")
            ? events.event_image
            : baseURL + "images/" + events.event_image,
          district_id: events.event_district_id,
          event_drivers_meeting_time: events.event_drivers_meeting_time,
          event_event_start: events.event_event_start,
          event_event_end: events.event_event_end,
          full_title: events.event_full_title,
          registration_close: events.event_registration_close,
          registration_open: events.event_registration_open,
          short_tile: events.event_short_tile,
          city: events.venue_clbsite_site_city,
          country: events.venue_clbsite_site_country,
          state: events.venue_clbsite_site_state,
          venue: events.venue_clbsite_site_name,
          hostClub_club_name: events.hostClub_club_name,
          racersCount: events.racersCount,
          id: events.event_id,
          event_status: events.event_event_status,
          event_is_published: events.event_is_published,
          time_zone: events.event_time_zone,
          scheduled_classes: events.event_scheduled_classes,
          heat_generated: events.event_heat_generated,
        };

        eventArr.push(data);
      });
      setEventDetails(eventArr);
      setIsLoder(false);
    }
  };

  const getAllEventDetails = async (dist_id, sortBy, user_id) => {
    var data = {
      host_club_id: "",
      // district_id: dist_id,
      page: 1,
      records_per_page: pageRecord,
      user_id: user_id,
      sort_by: "event_end",
      event_status: currentEventStatus,
    };

    let eventResponse = await combinedServices.getAllEvent(data);
    if (eventResponse.status == 200) {
      eventArr = [];
      setTotalRecord(eventResponse.totalCount);

      eventResponse.data.map((events) => {
        var data = {
          boatsCount: events.boatsCount,
          image: events.event_image.startsWith("https://nambaweb")
            ? events.event_image
            : events.event_image
            ? baseURL + "images/" + events.event_image
            : "https://nambaweb.s3.ap-south-1.amazonaws.com/frontend-static-image/logo.png",
          district_id: events.event_district_id,
          event_drivers_meeting_time: events.event_drivers_meeting_time,
          event_event_start: events.event_event_start,
          event_event_end: events.event_event_end,
          full_title: events.event_full_title,
          registration_close: events.event_registration_close,
          registration_open: events.event_registration_open,
          short_tile: events.event_short_tile,
          city: events.venue_clbsite_site_city,
          country: events.venue_clbsite_site_country,
          state: events.venue_clbsite_site_state,
          venue: events.venue_clbsite_site_name,
          hostClub_club_name: events.hostClub_club_name,
          racersCount: events.racersCount,
          id: events.event_id,
          event_status: events.event_event_status,
          event_is_published: events.event_is_published,
          time_zone: events.event_time_zone,
          scheduled_classes: events.event_scheduled_classes,
          heat_generated: events.event_heat_generated,
        };

        eventArr.push(data);
      });
      setEventDetails(eventArr);

      setIsLoder(false);
    }
  };

  const calculateDateDay = (startDate, closeDate) => {
    var data = "";
    var startDate = moment(startDate).format("YYYY-MM-DD");
    var endDate = moment(closeDate).format("YYYY-MM-DD");
    var todayDate = moment().format("YYYY-MM-DD");
    if (todayDate >= startDate && todayDate <= endDate) {
      data = "Ongoing";
    }
    if (todayDate > endDate) {
      data = "Closed";
    }
    if (todayDate > startDate) {
      data = "Open";
    }
  };

  const getTimeOfShift = (date) => {
    let splitTimeARray = date.split("T")[1].split(":");
    let hours = splitTimeARray[0];
    let minutes = splitTimeARray[1];
    var ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  };
  const getTimeStamp = (date) => {
    var timeStampValue = Math.floor(new Date(date).getTime() / 1000);
    return timeStampValue;
  };
  const handlePageChange = async (pageNumber) => {
    eventArr = [];
    setPage(pageNumber);
    setIsLoder(true);
    var data = {
      host_club_id: "",
      // district_id: currentDistric,
      page: pageNumber,
      records_per_page: pageRecord,
      user_id: userId,
    };
    let eventResponse = await combinedServices.getAllEvent(data);
    if (eventResponse.status == 200) {
      setTotalRecord(eventResponse.totalCount);

      eventResponse.data.map((events) => {
        var data = {
          boatsCount: events.boatsCount,
          image: events.event_image.startsWith("https://nambaweb")
            ? events.event_image
            : baseURL + "images/" + events.event_image,
          district_id: events.event_district_id,
          event_drivers_meeting_time: events.event_drivers_meeting_time,
          event_event_start: events.event_event_start,
          event_event_end: events.event_event_end,
          full_title: events.event_full_title,
          registration_close: events.event_registration_close,
          registration_open: events.event_registration_open,
          short_tile: events.event_short_tile,
          city: events.venue_clbsite_site_city,
          country: events.venue_clbsite_site_country,
          state: events.venue_clbsite_site_state,
          venue: events.venue_clbsite_site_name,
          hostClub_club_name: events.hostClub_club_name,
          racersCount: events.racersCount,
          id: events.event_id,
          event_is_published: events.event_is_published,
          time_zone: events.event_time_zone,
          event_status: events.event_event_status,
          scheduled_classes: events.event_scheduled_classes,
          heat_generated: events.event_heat_generated,
        };

        eventArr.push(data);
      });
      setEventDetails(eventArr);
      setIsLoder(false);
    }
  };
  const handleChangePublishStatus = async (nextChecked, id) => {
    //setChecked(nextChecked);

    let data = {
      is_published: nextChecked ? true : false,
    };

    let eventPublishStatus = await combinedServices.updateEventPublishStatus(
      data,
      id
    );
    if (eventPublishStatus.status == 200) {
      var isPublish = nextChecked ? "Published" : "UnPublished";
      swal("Event " + isPublish + " Successfully").then(() => {
        getAllEventDetails(currentDistric, hostClubIds, userId);
      });
    } else {
      swal("!Opps", eventPublishStatus.msg, "error");
    }
  };

  const handleEventStatus = async (e, id) => {
    let data = {
      event_status: e.target.value,
    };
    if (e.target.value == "archived") {
      swal({
        title: "Are you sure?",
        text: "Archiving a race will remove your ability to manage or change a race permenently, do you wish to contunue?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          let eventResponse = await combinedServices.updateEventStatus(
            data,
            id
          );
          if (eventResponse.status == 200) {
            swal("Event Archived Successfully").then(() => {
              getAllEventDetails(currentDistric, hostClubIds, userId);
            });
          } else {
            swal("!Opps", eventResponse.msg, "error");
          }
        } else {
          swal("Your event  is safe!");
          // window.location.reload();
        }
      });
    } else {
      let eventResponse = await combinedServices.updateEventStatus(data, id);

      if (eventResponse.status == 200) {
        swal("Event Status Update Successfully").then(() => {
          getAllEventDetails(currentDistric, hostClubIds, userId);
        });
      } else {
        swal("!Opps", eventResponse.msg, "error");
      }
    }
  };
  const handleCloseRegistrations = async (eventId, event) => {
    swal({
      title: "Are you sure?",
      text: "You want to close the registrations!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (close) => {
      if (close) {
        const datePart = moment();
        const utcDate = moment.utc(datePart);

        const data = {
          registration_close: utcDate.toISOString(),
        };
        try {
          const response = await combinedServices.closeOpenRegistration(
            data,
            eventId
          );
          if (response.status === 200) {
            swal("Event Registration Closed Successfully").then(() => {
              getAllEventDetails(currentDistric, hostClubIds, userId);
            });
          } else {
          }
        } catch (error) {
          console.log("Event  not closed", error);
        }
      } else {
        swal("Your event  is safe!");
      }
    });
  };

  //to open the registrations

  const handleOpenRegistration = (eventId, selectedTimeZone) => {
    swal({
      title: "Are you sure?",
      text: "You want to open the race!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (openRegistration) => {
      if (openRegistration) {
        const end_date = convertToUTCclose(String(endDate), selectedTimeZone);
        const data = {
          registration_close: end_date,
        };
        try {
          const response = await combinedServices.closeOpenRegistration(
            data,
            eventId
          );
          if (response.status === 200) {
            swal("Race Opened Successfully").then(() => {
              getAllEventDetails(currentDistric, hostClubIds, userId);
            });
          } else {
          }
        } catch (error) {
          console.log("Race  not opened", error);
        }
      } else {
        swal("Your event  is safe!");
      }
      setOpenDialogId(false);
    });
  };

  //  -----------------------------------

  // const handleDeleteEvent = async (id) => {
  //   swal({
  //     title: "Are you sure?",
  //     text: "Once deleted, you will not be able to recover this event!",
  //     icon: "warning",
  //     buttons: true,
  //     dangerMode: true,
  //   }).then(async (willDelete) => {
  //     if (willDelete) {
  //       let eventResponse = await combinedServices.deleteEventDetail(id);
  //       if (eventResponse.status == 200) {
  //         swal("Event Delete Successfully").then(() => {
  //           getAllEventDetails(currentDistric, hostClubIds, userId);
  //         });
  //       } else {
  //         swal("!Opps", eventResponse.msg, "error");
  //       }
  //     } else {
  //       swal("Your event  is safe!");
  //     }
  //   });
  // };

  const handleDeleteEvent = async (id, full_title) => {
    try {
      swal({
        title: "Once deleted, you will not be able to restore this event!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        const event = eventDetails.find((event) => event.id == id);

        const eventTitleToMatch = event.full_title;

        if (willDelete) {
          const { value: eventName } = await Swal.fire({
            title: "Verification",
            input: "text",
            inputLabel: "Event the name of the event",
            showCancelButton: true,
            inputValidator: (value) => {
              if (!value) {
                return "You need to write the event name!";
              } else if (value !== eventTitleToMatch) {
                return "You need to right the correct name of the event!";
              }
            },
          });
          if (eventName) {
            let eventDeleteResponse = await combinedServices.deleteEventDetail(
              id
            );
            if (eventDeleteResponse.status == 200) {
              getAllEventDetails(currentDistric, hostClubIds, userId);
              Swal.fire({
                title: "Deleted",
                text: "You have deleted the event!",
                icon: "success",
              });
            }
          } else {
            console.log("you have cancelled the event");
          }
        } else {
        }
      });
    } catch (err) {
      console.log("err :", err);
    }
  };

  const checkScheduler = async () => {
    try {
      var responses = {};

      if (eventDetails.length >= 1) {
        for (const event of eventDetails) {
          const eventId = event.id;
          const eventClassesData = await combinedServices.countByClasses(
            eventId
          );

          if (eventClassesData.status == 401) {
            swal(eventClassesData.msg, "Please Re-login", "warning").then(
              () => {
                getRemoveData("user_info");
                localStorage.clear();
                window.location.href = "/login";
              }
            );
          }

          // Perform response validation here
          let check = false;

          if (eventClassesData?.data.participants.length > 0) {
            const madeClassesData = eventClassesData?.data.participants.filter(
              (participant) => participant.class_status === "MADE"
            );
            const hasNullRunDate = madeClassesData.some(
              (participant) =>
                participant.run_date === null ||
                participant.run_date === undefined
            );
            check = !hasNullRunDate;
            responses[eventId] = { check };
          }
        }
        responses1 = responses;

        setEventResponses(responses); // Set the state with all the responses
      }
    } catch (error) {}
  };
  const checkheat = async () => {
    try {
      const heatres = {};

      if (eventDetails?.length > 0) {
        for (const event of eventDetails) {
          const eventId = Number(event.id);
          const heatdata = await combinedServices.getAllHeatSheet({
            event_id: eventId,
          });

          heatres[eventId] = { check: heatdata.data.length > 0 };
        }
      }

      setHeatResponse(heatres);
      return heatres;
    } catch (error) {}
  };

  const timeaccotolocation2 = (x, timeZone) => {
    if (timeZone == "") {
      timeZone = "Canada/Eastern";
    }
    const utcMoment = moment.utc(x);
    const localMoment = utcMoment.tz(timeZone);

    // const timeZoneAbbreviation = localMoment.format('z');
    const timeZoneOffset = localMoment.format("Z");
    return localMoment.format("ddd MMM DD YYYY HH:mm:ss");
  };

  const handleDateBox = (eventId) => {
    setOpenDialogId(eventId);
  };

  const handleClose = (eventId, event) => {
    setOpenDialogId(false);
  };

  const handleEndDate = (date, id) => {
    setEndDate(date);
  };

  //function to conver the date the given time zone and set the time to the end of the day
  function convertToUTCclose(dateTimeStr, selectedTimeZone) {
    if (selectedTimeZone == "Canada/Eastern") {
      selectedTimeZone = "EST";
    }
    const datePart =
      dateTimeStr.split(" ")[1] +
      " " +
      dateTimeStr.split(" ")[2] +
      " " +
      dateTimeStr.split(" ")[3];
    const dateOnly = moment
      .tz(datePart, "MMM DD YYYY", selectedTimeZone)
      .set({ hour: 23, minute: 59, second: 1 });
    const utcDate = moment.utc(dateOnly);
    return utcDate.toString();
  }

  // to open the race

  const handleOpenRace = (event__id, selectedTimeZone) => {
    swal({
      title: "Are you sure?",
      text: "You want to open the race!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (openRace) => {
      if (openRace) {
        //event start date
        const datePart = moment();
        const utcDate = moment.utc(datePart);

        //event end date
        const convertedDate = convertToUTCclose(
          String(endDate),
          selectedTimeZone
        );

        const data = {
          event_start: utcDate.toISOString(),
          event_end: convertedDate,
          event_status: "ongoing",
        };
        try {
          const response = await combinedServices.openCloseRace(
            data,
            event__id
          );
          if (response.status === 200) {
            swal("Race Opened Successfully").then(() => {
              getAllEventDetails(currentDistric, hostClubIds, userId);
            });
          } else {
          }
        } catch (error) {
          console.log("Race  not closed", error);
        }
      } else {
        swal("Your race  is safe!");
      }
    });
    setOpenDialogId(false);
  };

  // to close the race

  const handleCloseRace = (eventId, event) => {
    swal({
      title: "Are you sure?",
      text: "You want to close the race!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (closeRace) => {
      if (closeRace) {
        const datePart = moment();
        const utcDate = moment.utc(datePart);
        const data = {
          event_start: utcDate.toISOString(),
          event_end: utcDate.toISOString(),
          event_status: "completed",
        };
        try {
          const response = await combinedServices.openCloseRace(data, eventId);
          if (response.status === 200) {
            swal("Race Closed Successfully").then(() => {
              getAllEventDetails(currentDistric, hostClubIds, userId);
            });
          } else {
          }
        } catch (error) {
          console.log("Race  not closed", error);
        }
      } else {
        swal("Your race  is safe!");
      }
    });
  };
  

  const diffInMilliSeconds = (eventDate) => {
    const currentDate = new Date();
    const eventDateTime = new Date(eventDate).getTime();
    return eventDateTime - currentDate.getTime();
  };

  return (
    <>
      <Header />
      <div className="dbRight">
        <div className="dbMid dbInner">
          <div className="flexBox spacebetween topHdBox">
            <div className="left">
              <div className="dbMainHd">Event Management</div>
              <div className="text">Manage My Event</div>
              <select className="eventSelect" onChange={handleEvent}>
                {/* <option value={roundVal}>Round {roundVal}</option> */}
                <option value="" selected>
                  All Races
                </option>
                <option value="event_status=ongoing">Open Races</option>
                <option value="event_status=completed">Close Races</option>
                <option value="event_status=upcoming">Upcoming Races</option>
              </select>
            </div>
            <div className="right tar">
              <NavLink to="event-template" className="blueBtn">
                Event Template
              </NavLink>
              <NavLink
                className="blueBtn ml10"
                to="#"
                onClick={handleLocationByDistric}
              >
                Create New Event
              </NavLink>
              <br />
            </div>
          </div>
          {isLoder ? (
            <div className="text-center mt-4 loadMore">
              <Spinner color="primary" />
            </div>
          ) : (
            <>
              {eventDetails.length > 0 ? (
                <ul className="uocomingRaces ">
                  {eventDetails
                    .sort((a, b) => {
                      const diffA = diffInMilliSeconds(a.event_event_start);
                      const diffB = diffInMilliSeconds(b.event_event_start);
                      return diffB - diffA;
                    })
                    // .filter((x) => x.event_status != "archived")
                    .map((value) => {
                      const response = eventResponses?.[value.id];

                      const isResponseValid = response && response.check;

                      const isLoading = response === undefined;

                      const heatsres = heatResponse?.[value.id];

                      const isValid = heatsres && heatsres.check;

                      const isLoad = heatsres === undefined;

                      const currentDate = (desiredTimeZone) => {
                        desiredTimeZone =
                          desiredTimeZone == ""
                            ? "Canada/Eastern"
                            : desiredTimeZone;
                        return new Intl.DateTimeFormat("en-US", {
                          hour: "2-digit",
                          minute: "2-digit",
                          second: "2-digit",
                          timeZoneName: "short",
                          weekday: "short",
                          month: "short",
                          day: "2-digit",
                          year: "numeric",
                          timeZone: desiredTimeZone,
                          hour12: true,
                        }).format(new Date());
                      };
                      const registrationOpen =
                        moment(currentDate(value.time_zone)).format(
                          "YYMMDD HH:mm:ss"
                        ) <
                        moment(
                          timeaccotolocation2(
                            value.registration_open,
                            value.time_zone
                          )
                        ).format("YYMMDD HH:mm:ss");

                      const registrationOngoing =
                        moment(currentDate(value.time_zone)).format(
                          "YYMMDD HH:mm:ss"
                        ) >=
                          moment(
                            timeaccotolocation2(
                              value.registration_open,
                              value.time_zone
                            )
                          ).format("YYMMDD HH:mm:ss") &&
                        moment(currentDate(value.time_zone)).format(
                          "YYMMDD HH:mm:ss"
                        ) <=
                          moment(
                            timeaccotolocation2(
                              value.registration_close,
                              value.time_zone
                            )
                          ).format("YYMMDD HH:mm:ss");
                      const registrationClosed =
                        moment(currentDate(value.time_zone)).format(
                          "YYMMDD HH:mm:ss"
                        ) >
                        moment(
                          timeaccotolocation2(
                            value.registration_close,
                            value.time_zone
                          )
                        ).format("YYMMDD HH:mm:ss");
                      return (
                        <li className="showRaces" key={value.id}>
                          <div className="left rght">
                            <div className="icon">
                              <img src={value.image} alt="" />
                            </div>
                            <div className="det">
                              <span className="hd">
                                <Link to={"/event-detail/" + value.id}>
                                  {value.full_title}
                                </Link>
                                {registrationClosed && (
                                  <span>
                                    (Registration Closed{" "}
                                    {moment(
                                      timeaccotolocation2(
                                        value.registration_close,
                                        value.time_zone
                                      )
                                    ).format("ddd, MMM DD YYYY")}
                                    )
                                  </span>
                                )}
                                {registrationOpen && (
                                  <span className="black">
                                    (Registration Open{" "}
                                    {moment(
                                      timeaccotolocation2(
                                        value.registration_open,
                                        value.time_zone
                                      )
                                    ).format("ddd, MMM DD YYYY")}
                                    )
                                  </span>
                                )}
                                {registrationOngoing && (
                                  <span className="blue">
                                    (Registration Ongoing{" "}
                                    {moment(
                                      timeaccotolocation2(
                                        value.registration_close,
                                        value.time_zone
                                      )
                                    ).format("ddd, MMM DD YYYY")}
                                    )
                                  </span>
                                )}
                              </span>
                              {value.hostClub_club_name} |{" "}
                              {moment(
                                timeaccotolocation2(
                                  value.event_event_start,
                                  value.time_zone
                                )
                              ).format("ddd, MMM DD, YYYY") +
                                " - " +
                                moment(
                                  timeaccotolocation2(
                                    value.event_event_end,
                                    value.time_zone
                                  )
                                ).format("ddd, MMM DD, YYYY")}{" "}
                              | <a>{value.boatsCount} Boats </a>
                              <a>{value.racersCount} Racers</a>
                              <div
                                className="statsBx"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <select
                                  className="formSelect"
                                  onChange={(e) =>
                                    handleEventStatus(e, value.id)
                                  }
                                >
                                  <option>Select Status</option>
                                  {eventStatus.map((status) => (
                                    <option
                                      selected={value.event_status == status}
                                      name={status}
                                      value={status}
                                      key={status}
                                    >
                                      {status}
                                    </option>
                                  ))}
                                </select>

                                <span className="publishStatusText">
                                  UnPublished/Published
                                </span>

                                <Switch
                                  Name="react-switch1"
                                  offColor="#e70000"
                                  onChange={(e) =>
                                    handleChangePublishStatus(e, value.id)
                                  }
                                  checked={value.event_is_published}
                                  onColor="#1ce10d"
                                  onHandleColor="#fff"
                                  uncheckedIcon={false}
                                  checkedIcon={false}
                                  handleDiameter={14}
                                  height={22}
                                  width={48}
                                  className="react-switch publishStatus"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="rgt itemCenter lft">
                            <div className="flexBox">
                              {value.event_status != "completed" && (
                                <a
                                  onClick={() =>
                                    handleDeleteEvent(
                                      value.id,
                                      value.full_title
                                    )
                                  }
                                >
                                  Delete
                                </a>
                              )}
                              {registrationOngoing && (
                                <a
                                  onClick={() =>
                                    handleCloseRegistrations(value.id, value)
                                  }
                                >
                                  Close Race
                                </a>
                              )}
                              {registrationClosed && (
                                <a
                                  onClick={() => {
                                    handleDateBox(value.id);
                                  }}
                                >
                                  Open Race
                                </a>
                              )}
                              {value.event_status == "completed" && (
                                <Link to={`/run-race/${value.id}`}>
                                 Update Score
                                </Link>
                              )}

                              <BootstrapDialog
                                onClose={handleClose}
                                aria-labelledby="customized-dialog-title"
                                open={openDialogId === value.id}
                              >
                                <DialogTitle
                                  sx={{ m: 0, p: 2 }}
                                  id="customized-dialog-title"
                                >
                                  Select your race closing date
                                  {value.full_title}
                                </DialogTitle>
                                <IconButton
                                  aria-label="close"
                                  onClick={handleClose}
                                  sx={{
                                    position: "absolute",
                                    right: 8,
                                    top: 8,
                                    color: (theme) => theme.palette.grey[500],
                                  }}
                                >
                                  <CloseIcon />
                                </IconButton>
                                <DialogContent
                                  dividers
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Typography gutterBottom>
                                    <div
                                      className=""
                                      style={{
                                        height: "300px",
                                        margin: "10px auto",
                                      }}
                                    >
                                      <DatePicker
                                        open
                                        className="formInput datepicker"
                                        selected={endDate}
                                        onChange={(date) =>
                                          handleEndDate(date, value.id)
                                        }
                                        minDate={new Date()}
                                      />
                                    </div>
                                  </Typography>
                                </DialogContent>
                                <DialogActions>
                                  <Button
                                    autoFocus
                                    onClick={() => {
                                      handleOpenRegistration(
                                        value.id,
                                        value.time_zone
                                      );
                                    }}
                                  >
                                    Open Race
                                  </Button>
                                </DialogActions>
                              </BootstrapDialog>
                            </div>
                            {value.event_status != "completed" ? (
                              <>
                                <div className="smallLink">
                                  <NavLink to={"/event/edit-event/" + value.id}>
                                    <span className="material-icons">
                                      edit_note
                                    </span>{" "}
                                    Edit
                                  </NavLink>

                                  <NavLink to={"/manage-entries/" + value.id}>
                                    <span className="material-icons">
                                      edit_note
                                    </span>{" "}
                                    Manage Entries
                                  </NavLink>

                                  <>
                                    <NavLink
                                      to={"/race-interface/" + value.id}
                                      className={
                                        moment(
                                          timeaccotolocation2(
                                            value.registration_close,
                                            value.time_zone
                                          )
                                        ).format("YYMMDD HH:mm:ss") <
                                          moment(
                                            currentDate(value.time_zone)
                                          ).format("YYMMDD HH:mm:ss") &&
                                        value.boatsCount > 1
                                          ? ""
                                          : "disabled-link"
                                      }
                                    >
                                      <span className="material-icons">
                                        edit_note
                                      </span>
                                      Scheduler
                                    </NavLink>

                                    <NavLink
                                      to={"/manage-heatsheet/" + value.id}
                                      className={
                                        value.boatsCount > 1 &&
                                        moment(
                                          timeaccotolocation2(
                                            value.registration_close,
                                            value.time_zone
                                          )
                                        ).format("YYMMDD HH:mm:ss") <
                                          moment(
                                            currentDate(value.time_zone)
                                          ).format("YYMMDD HH:mm:ss") &&
                                        value.scheduled_classes
                                          ? ""
                                          : "disabled-link"
                                      }
                                    >
                                      <span className="material-icons">
                                        edit_note
                                      </span>{" "}
                                      Heats
                                    </NavLink>

                                    <NavLink
                                      to={"/run-race/" + value.id}
                                      className={
                                        value.boatsCount > 1 &&
                                        moment(
                                          timeaccotolocation2(
                                            value.registration_close,
                                            value.time_zone
                                          )
                                        ).format("YYMMDD HH:mm:ss") <
                                          moment(
                                            currentDate(value.time_zone)
                                          ).format("YYMMDD HH:mm:ss") &&
                                        value.scheduled_classes &&
                                        value.heat_generated
                                          ? ""
                                          : "disabled-link"
                                      }
                                    >
                                      <span className="material-icons">
                                        edit_note
                                      </span>{" "}
                                      SCORE
                                    </NavLink>
                                  </>
                                </div>
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        </li>
                      );
                    })}
                </ul>
              ) : (
                <div className="noEvent">Not Found Any Event.</div>
              )}
              {totalRecord > 10 && (
                <Pagination
                  activePage={page}
                  totalItemsCount={totalRecord}
                  onChange={handlePageChange}
                />
              )}
            </>
          )}
        </div>
      </div>
      <Modal className="scorePopup" isOpen={modal}>
        <ModalHeader className="scoreHeader">
          <Button className="close">
            <span className="material-icons" onClick={toggle}>
              close
            </span>
          </Button>
        </ModalHeader>
        <ModalBody className="scoreInner">
          <AddScore eventid={eventId} />
        </ModalBody>
      </Modal>
    </>
  );
};
export default EventManagement;

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
