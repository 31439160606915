function timeToSeconds(timeStr) {
  if (timeStr == "") {
    return "";
  }
  if (timeStr.split(":").length == 2) {
    let parsedMinutes = Number(timeStr.split(":")[0]) * 60;
    let parsedSeconds = Number(timeStr.split(":")[1]);
    let totalSeconds = parsedMinutes + parsedSeconds;
    return { time: timeStr, totalSeconds: totalSeconds };
  } else if (timeStr.split(":").length == 3) {
    let parsedHourse = Number(timeStr.split(":")[0]) * 3600;
    let parsedMinutes = Number(timeStr.split(":")[1]) * 60;
    let parsedSeconds = Number(timeStr.split(":")[2]);
    let totalSeconds = parsedHourse + parsedMinutes + parsedSeconds;


    return { time: timeStr, totalSeconds: totalSeconds };
  } else {
    return { time: timeStr, totalSeconds: "" };
  }
}

export function findMinFastTime(fastTimeArray) {
  let minTime = null; // Start with no minimum
  if (!fastTimeArray) return "";

  fastTimeArray.forEach((item) => {
    if (item.time !== "") {
      const convertedTime = timeToSeconds(item.time);
 
      if (convertedTime.time !== "") {
        if (minTime == null || convertedTime.time < minTime.time) {
          minTime = convertedTime;
        }
      }
    }
  });

  if (minTime === null) {
    return ""; // No times available
  }

  // Convert the minTime back to human-readable format (e.g., mm:ss.SSS)'
  if (minTime.totalSeconds == "") {
    return ""
  }


  if(minTime.time.split(":").length==3){
    minTime.time = minTime.time.split(":")[1] + ":" + Number(minTime.time.split(":")[2]).toFixed(2).padStart(5, '0');;
  }
  if(minTime.time.split(":").length==2){
    minTime.time = minTime.time.split(":")[0] + ":" + Number(minTime.time.split(":")[1]).toFixed(2).padStart(5, '0');;
  }


  return minTime.time;
}
