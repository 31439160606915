import React, { useState, useEffect } from "react";
import "./HomeContent.scss";
import Header from "../../../Components/adminDashboard/Header/Header";
import Switch from "react-switch";
import combinedServices from "../../../shared/services/user-service";
import getLocalData from "../../../shared/localStorage/getData";
import { useForm } from "react-hook-form";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import { NavLink, useParams } from "react-router-dom";
import { Spinner } from "reactstrap";
import RichTextEditor from "react-rte";
import userConstants from "../../../shared/constants/user-constants";
import {
  RichTextEditorComponent,
  Toolbar,
  Link,
  Table,
  QuickToolbar,
  Image,
  HtmlEditor,
  Inject,
} from "@syncfusion/ej2-react-richtexteditor";

const AdminDashboard = () => {
  let navigate = useNavigate();
  const params = useParams();

  const [allsmsData, setAllSmsData] = useState([]);
  const [isLoder, setIsLoder] = useState(true);
  const [isHideSubmitButton, setIsHideSubmitButton] = useState(false);
  const [contentDetails, setContentDetails] = useState(
    RichTextEditor.createEmptyValue()
  );
  const [uploadImageUrl, setUploadImageUrl] = useState("");
  const [id, setId] = useState(0);
  const [imageError, setImageError] = useState("");
  const [htmlError, setHtmlError] = useState("");
  const [orderError, setOrderError] = useState("");
  const [baseURL, setBaseUrl] = useState(userConstants.baseURL);
  const [previewImage, setPreviewImage] = useState("");
  const [orderContent, setOrderContent] = useState("");
  const [editorData, setEditorData] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [url,setUrl] = useState('')

  useEffect(async () => {
    if (getLocalData("admin_info", "isLogin")) {
      let smsResponse = await combinedServices.homeContentDataOne(params.id);
      if (smsResponse.status == 200) {
        setIsLoder(false);
        setEditorData(smsResponse.data.html);

        setPreviewImage(smsResponse.data.image);
        setOrderContent(smsResponse.data.ordercontent);
        setUrl(smsResponse.data.url)
        setId(params.id);
      }
    }
  }, []);

  const handleChangeContent = (value) => {
    setContentDetails(value);
  };

  const handleUploadImage = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      const objectUrl = URL.createObjectURL(img);
      const formdata = new FormData();
      formdata.append("image", img);

      setUploadImageUrl(formdata);
    }
  };
  const handleOrder = (value) => {
    console.log("value,", value.target.value);
    setOrderContent(Number(value.target.value));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    var checkImage = uploadImageUrl ? uploadImageUrl : previewImage;

    // if (!checkImage) {
    //     setImageError("Please select image")
    // }
    if (!orderContent) {
      setOrderError("Please enter order preference");
    }
    if (editorData.toString("html") == "<p><br></p>") {
      setHtmlError("Please enter some content");
    }

    if (editorData && orderContent) {
      // setImageError("");
      // setHtmlError("")
      // setOrderError(" ")
      // var image = '';
      // if(uploadImageUrl){
      //     let response = await combinedServices.uploadImageFromAdmin(uploadImageUrl);
      //     if (response.status == 200) {
      //         image = response.data.data.file_name;
      //     }

      // }

      var data = {
        image: imageUrl ? imageUrl : previewImage,
        html: editorData.toString("html"),
        url:url,
        ordercontent: Number(orderContent),
      };

      let responseHomeContent = await combinedServices.updateHomeContent(
        id,
        data
      );

      if (responseHomeContent.status == 200) {
        swal("Yes", "Home content Update Successfully", "success").then(
          (value) => {
            navigate("../home-content/", { replace: true });
          }
        );
      } else {
        swal("!Opps", responseHomeContent.msg, "error");
      }
    }
  };

  const handleDeleteHomeContent = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover home content!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        let response = await combinedServices.handleDeleteHomeContent(id);
        if (response.status == 200) {
          swal("Poof! Your home content  has been deleted!", {
            icon: "success",
          });

          // getAllHomeContent();
        } else {
          swal("!Opps", response.msg, "error");
        }
      } else {
        swal("Your home content is safe!");
      }
    });
  };

  const toolbarSettings = {
    items: [
      "Bold",
      "Italic",
      "Underline",
      "StrikeThrough",
      "FontName",
      "FontSize",
      "FontColor",
      "BackgroundColor",
      "LowerCase",
      "UpperCase",
      "|",
      "Formats",
      "Alignments",
      "OrderedList",
      "UnorderedList",
      "Outdent",
      "Indent",
      "|",
      "CreateLink",
      "Image",
      "|",
      "ClearFormat",
      "Print",
      "SourceCode",
      "FullScreen",
      "|",
      "Undo",
      "Redo",
      "createTable",
    ],
  };
  const quickToolbarSettings = {
    image: [
      "Replace",
      "Align",
      "Caption",
      "Remove",
      "InsertLink",
      "OpenImageLink",
      "-",
      "EditImageLink",
      "RemoveImageLink",
      "Display",
      "AltText",
      "Dimension",
    ],
    link: ["Open", "Edit", "UnLink"],
  };

  // const saveContent = (e) => {
  //   setEditorData(e.value);
  // };
  const saveContent = (e) => {
    // Create a temporary DOM element to work with the HTML content
    const tempElement = document.createElement("div");
    tempElement.innerHTML = e.value;

    // Loop through all the images in the content
    const images = tempElement.querySelectorAll("img");
    images.forEach((img) => {
      // Check if the image has a blob URL
      if (img.src.startsWith("blob:")) {
        // Replace the blob URL with the actual image URL
        img.src = imageUrl; // Replace with your actual image URL
      }
    });

    // Convert the modified HTML content back to a string and update the editor data
    const modifiedContent = tempElement.innerHTML;
    console.log("modifiedcontent", modifiedContent);
    setEditorData(modifiedContent);
  };

  const insertImageSettings = {
    saveUrl:
      "https://services.syncfusion.com/js/production/api/FileUploader/Save",
    removeUrl:
      "https://services.syncfusion.com/js/production/api/FileUploader/Remove",
    height: 165,
    width: 165,
  };
  const imageUploadHandler = async (args) => {
    try {
      const files = args.file.rawFile;
      const formData = new FormData();
      formData.append("image", files);

      const response = await combinedServices.uploadImageFromAdmin(formData);
      const imageUrl = response.data.location

      // Create a new image element
      const imageElement = document.createElement("img");
      imageElement.setAttribute("src", imageUrl);
      imageElement.setAttribute("alt", "Uploaded Image");
      imageElement.setAttribute("width", "165px"); // Adjust as needed
      imageElement.setAttribute("height", "165px"); // Adjust as needed
      setImageUrl(imageUrl);
      // Create a new paragraph element
      const paragraph = document.createElement("p");
      paragraph.appendChild(imageElement);

      // Append the new paragraph to the current editor content
      // const updatedContent = editorData + paragraph.outerHTML;
      // setEditorData(updatedContent);
      editorData.appendChild(paragraph);
    } catch (error) {
      console.error(error);
    }
  };

  const handleUrl=(e)=>{
    setUrl(e.target.value)
  }


  return (
    <>
      <Header />

      <div className="dbAdminRight">
        <div className="dbMid dbInner">
          <div className="flexBox spacebetween topHdBox">
            <div className="left">
              <div className="dbMainHd"> Update Home Content</div>
            </div>
          </div>
          <div className="flexBox spacebetween">
            <div className="stLeft whtBox">
              <div className="dbSubHd">Update Home Content</div>
              <form id="myForm">
                <ul className="flexBox editForm full">
                  {/* <li>
                                        <span className="fieldHd"> Images</span>
                                        <div className="uploadImg"><img src={baseURL + "images/"+previewImage}/></div>
                                        <input type="file" className='formInput' onChange={handleUploadImage} />
                                        {imageError ? imageError : ""}
                                    </li> */}

                  <li>
                    <span className="fieldHd"> URL</span>
                    <input
                      type="url"
                      className="formInput"
                      onChange={handleUrl}
                      value={url}
                    />
                  </li>

                  <li>
                    <span className="fieldHd">Order</span>

                    <input
                      type="text"
                      className="formInput"
                      onChange={handleOrder}
                      value={orderContent}
                    />
                    {orderError ? orderError : ""}
                  </li>
                  <li>
                    <span className="fieldHd">Content </span>
                    <RichTextEditorComponent
                      // ref={(richtexteditor) => { richtexteditor = richtexteditor }}
                      height={300}
                      width={700}
                      toolbarSettings={toolbarSettings}
                      quickToolbarSettings={quickToolbarSettings}
                      value={editorData}
                      change={saveContent}
                      imageUploadSuccess={imageUploadHandler}
                      insertImageSettings={insertImageSettings}
                    >
                      <Inject
                        services={[
                          Toolbar,
                          Image,
                          Link,
                          HtmlEditor,
                          QuickToolbar,
                          Table,
                        ]}
                      />
                    </RichTextEditorComponent>
                    {/* <RichTextEditor className="formInput" value={contentDetails} onChange={handleChangeContent} /> */}

                    {htmlError ? htmlError : ""}
                  </li>
                </ul>
                {isHideSubmitButton ? (
                  " "
                ) : (
                  <div className="tar">
                    <button
                      type="submit"
                      className="formBtn full"
                      onClick={handleFormSubmit}
                    >
                      Update
                    </button>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AdminDashboard;
