import React, { useState, useEffect } from 'react';

import Header from "../../../Components/adminDashboard/Header/Header";
import Switch from "react-switch";
import combinedServices from "../../../shared/services/user-service";
import getLocalData from "../../../shared/localStorage/getData";
import { NavLink, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import swal from 'sweetalert';
import { Spinner } from 'reactstrap';
import SelectSearch, { fuzzySearch } from 'react-select-search';
import getRemoveData from '../../../shared/localStorage/removeData'

const PaymentGateway = () => {
    let navigate = useNavigate();
    const [allClubDetails, setAllClubDetails] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [paymentGatewayId, setPaymentGatewayId] = useState("");
    const [isLoder, setIsLoder] = useState(true)

    const [allPayment, setAllPayment] = useState([]);
    const [allUsers,setAllUser] = useState([]);
   
    const [userId,setUserId] = useState("");
   



    useEffect(async () => {
        if (getLocalData("admin_info", "isLogin")) {
            var paymentArr = [];
            var payData = {
                user_id :""
            }
            let paymentAll = await combinedServices.getPaymentAdmin(payData);

            if(paymentAll.status==401){
                swal(paymentAll.msg, "Please Re-login", "warning").then(() => {
                  getRemoveData("user_info");
                  localStorage.clear();
                  window.location.href = "/login";
                });
              }
            //setAllClubDetails(clubResponse.data);
            if (paymentAll.status == 200) {
                setIsLoder(false)
                paymentAll?.data?.map((pay) => {
                    var full_title = pay?.event_participant?.event?.full_title ;
                    var user = pay.event_participant.user.user_firstname + " " + pay.event_participant.user.user_lastname ;
                   
                   
                    var data = {

                        payment_for: pay.payment_for,
                        amount: pay.amount,
                        payment_status: pay.payment_status,
                        payment_type: pay.payment_type,
                        full_title : full_title ,
                        user :user



                    }

                    

                    paymentArr.push(data)

                })


                setAllPayment(paymentArr)


            }

            let usersResponse = await combinedServices.getAllUser();
            var userArr = [] ;
            

            usersResponse.data.map((user)=>{

                var data = {
                    name : user.user_firstname+" "+user.user_lastname ,
                    value : user.id


                }
                userArr.push(data)
            
            })

            setAllUser(userArr) ;

        }
    }, [])

    const handleSearchBox = async(e) =>{
        setUserId(e)
       
        var data = {
            user_id: e

        }
        var paymentArr = [];
        let paymentAll = await combinedServices.getPaymentAdmin(data);
        if (paymentAll.status == 200) {
            setIsLoder(false)
            paymentAll.data.map((pay) => {
                var full_title = pay.event_participant.event.full_title ;
                var user = pay.event_participant.user.user_firstname + " " + pay.event_participant.user.user_lastname ;
               
               
                var data = {

                    payment_for: pay.payment_for,
                    amount: pay.amount,
                    payment_status: pay.payment_status,
                    payment_type: pay.payment_type,
                    full_title : full_title ,
                    user :user



                }

                

                paymentArr.push(data)

            })


            setAllPayment(paymentArr)


        }
        

    }



    return (
        <>
            <Header />
            <div className="dbAdminRight">
                <div className="dbMid dbInner">
                    <div className="flexBox spacebetween topHdBox">
                        <div className="left">
                        <SelectSearch
          options={allUsers}
          value={userId}
          
          onChange={handleSearchBox}
          search
          filterOptions={fuzzySearch}
          placeholder="Search something"
          className="select-search"
        />
                             




                        </div>

                    </div>
                    <div className='flexBox spacebetween'>
                        <div className='pgBox whtBox'>
                            <div className="interfaceTable">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Sr. No</th>
                                            <th>User Name</th>
                                            <th>Event Name</th>
                                           
                                            <th>Payment Amount</th>
                                            <th>Payment Status</th>
                                            <th>Payment Type </th>

                                        </tr>
                                    </thead>
                                    {isLoder ? <div className="text-center mt-4 loadMore"><Spinner color="primary" /></div> : <>
                                        <tbody>
                                            {allPayment.length > 0 ? allPayment.map((paymentDetails, index) => {
                                                return (
                                                    <tr>
                                                        <td>{index + 1}</td>
                                                        <td>{paymentDetails.user}</td>
                                                        <td>{paymentDetails.full_title}</td>
                                                        
                                                        

                                                        <td>
                                                            <div className='dec'>{paymentDetails.amount}</div>
                                                        </td>
                                                        <td>
                                                            <div className='dec'>{paymentDetails.payment_status}</div>
                                                        </td>
                                                        {paymentDetails.payment_type == "PAY_AT_RACE" &&
                                                            <td>
                                                                <div className='dec'>Pay At Race</div>
                                                            </td>
                                                        }
                                                         {paymentDetails.payment_type == "PAYPAL" &&
                                                            <td>
                                                                <div className='dec'>Paypal</div>
                                                            </td>
                                                        }
                                                         {paymentDetails.payment_type == "MAIL_IN" &&
                                                            <td>
                                                                <div className='dec'>  Mail IN</div>
                                                            </td>
                                                        }


                                                    </tr>


                                                )


                                            }) : <tr>
                                                <td></td>
                                                <td></td>
                                                <td><div className="noEvent">Not Found Any Payment</div></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
}


                                        </tbody>
                                    </>}
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )

}
export default PaymentGateway;