import React from 'react'
import Header from '../../../Components/clubDashboard/Header/Header'

function Trash() {
  return (
    <>
    <Header />
    <div>
    Trash
    </div>
    </>
  )
}

export default Trash