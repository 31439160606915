import React, { useState, useEffect } from "react";
import getLocalData from "../../../shared/localStorage/getData";
import getRemoveData from "../../../shared/localStorage/removeData";
import combinedServices from "../../../shared/services/user-service";
import { Spinner } from "reactstrap";
import setLocalData from "../../../shared/localStorage/setData";
import { useLocation } from "react-router-dom";

const Header = (props) => {
  const [isLogin, setIsLogin] = useState(false);
  const [loginID, setLoginId] = useState("");
  const [isLoder, setIsLoder] = useState(false);
  const [subMember, setSubMember] = useState([]);
  const [parentToken, setParentToken] = useState([]);
  const [data, setData] = useState();
  const location = useLocation().pathname.split("/")[1]

  const db_logo = "https://nambaweb.s3.ap-south-1.amazonaws.com/frontend-static-image/portal.png"

  useEffect(async () => {
    let eventSubMember;
    setIsLogin(getLocalData("user_info", "isLogin"));
    if (getLocalData("user_info", "isLogin")) {
      setParentToken(localStorage.getItem("parent_token"));
      var userId = getLocalData("user_info", "all").userData.login_user_detail
        .data.user.id;
      setLoginId(userId);
      eventSubMember = await combinedServices.getAllSubMemberList(
        localStorage.getItem("parent_token")
      );
      setSubMember(eventSubMember.data);
      const numIdArray = eventSubMember?.data?.map(
        (item) => item.user_nambanum_fkey
      );
      let response = await combinedServices.getNumberId(numIdArray);
      setData(response.data.data);
    }
  }, []);

  const handleEditProfile = async () => {
    setIsLoder(true);
    var tokenResponse = await combinedServices.updateToken();

    if (tokenResponse.status == 200) {
      setIsLoder(false);
      var token = tokenResponse.data.token;
      window.location.href = "https://member.namba.com/?token=" + token;
    }
  };

  const handleLoginBySubMemeber = async (e) => {
    // getRemoveData("user_info");

    let childLoginResponse = await combinedServices.loginWithUserId(
      e.target.value,
      parentToken
    );

    if (childLoginResponse.status == 200) {
      getRemoveData("user_info");
      let login_response_1 = {
        login_user_detail: childLoginResponse,
      };

      setLocalData("user_info", login_response_1);

      window.location.href = location;
    }
  };
  function nambe(val) {
    const data2 = data?.filter((value) => value.num_id === val);
  
    if (data2 && data2.length > 0) {
      return data2[0].num_number;
    }
  
    return null;  // Or handle the case when data2 is empty or undefined
  }
  

  return (
    <>
      <div className="flexBox spacebetween itemCenter">
        <div className="dbSubHd">Sub Members </div>
        <div
          className="searchBox flexBox spacebetween itemCenter nowrap"
          style={{ backgroundColor: "#ebedf5", margin: "0" }}
        >
          <select
            className="searchInput"
            value={loginID}
            onChange={handleLoginBySubMemeber}
          >
            <option>Select Sub Member</option>
            {subMember?.length > 0
              ? subMember?.map((value) => {
                  return (
                    <option
                      key={value.id}
                      value={value.id}
                      user_name={
                        value.user_firstname + " " + value.user_lastname
                      }
                    >
                      {nambe(value.user_nambanum_fkey) +
                        " - " +
                        "(" +
                        value.user_firstname +
                        " " +
                        value.user_lastname +
                        ")"}{" "}
                    </option>
                  );
                })
              : ""}
          </select>
        </div>

        {isLoder ? (
          <li key="member_portal" className="text-center mt-4 loadMore">
            <Spinner color="primary" />
          </li>
        ) : (
          ""
        )}
        <div className="addBtnNew">
          <button
            className="continueBtn flexBox itemCenter"
            type="button"
            style={{ backgroundColor: "#ffff" }}
          >
            {" "}
            <img
              src={db_logo}
              alt=""
              onClick={handleEditProfile}
              style={{ borderRadius: "2px", width: "45px" }}
            />
            <span style={{ color: "black", fontWeight: "600" }}>
              Member Portal
            </span>
          </button>
        </div>
      </div>
    </>
  );
};
export default Header;
