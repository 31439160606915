import React, { useState, useEffect, PropTypes } from "react";
import Header from "../../../Components/clubDashboard/Header/Header";
import "./CreateEvent.scss";
import DatePicker from "react-datepicker";
import { set, useForm } from "react-hook-form";
import combinedServices from "../../../shared/services/user-service";
import getLocalData from "../../../shared/localStorage/getData";
import getRemoveData from "../../../shared/localStorage/removeData";
import "react-datepicker/dist/react-datepicker.css";
import Multiselect from "multiselect-react-dropdown";
//import moment from "moment";
import RichTextEditor from "react-rte";
import swal from "sweetalert";
import userConstants from "../../../shared/constants/user-constants";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import moment from "moment-timezone";
import SearchPage from "../../../Components/clubDashboard/Header/SearchPage";

import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  RichTextEditorComponent,
  Toolbar,
  Link,
  Table,
  QuickToolbar,
  Image,
  HtmlEditor,
  Inject,
} from "@syncfusion/ej2-react-richtexteditor";
import Compressor from "compressorjs";

moment.tz.setDefault("timeZone");
const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
const CreateEvent = () => {
  let navigate = useNavigate();
  const params = useParams();

  const [content, setContent] = useState("");
  const [errorMsg, setErrorMsg] = useState([]);
  const [successMsg, setSuccessMsg] = useState("");
  const [eventId, setEventId] = useState("");
  const [distric, setDistric] = useState([]);
  const [location, setLocation] = useState([]);
  const [clubMemberDetails, setClubMemberDetails] = useState([]);
  const [classes, setClass] = useState([]);
  const [currentDistric, setCurrentDistric] = useState("");
  const [selectedClasses, setSelectedClass] = useState([]);
  const [imageUrl, setImageUrl] = useState("");

  const [selectedShootOutClasses, setSelectedShootOutClasses] = useState([]);
  const [locationAccordingToDistric, setLocationAccordingToDistric] =
    useState("");
  const [hotelCount, setHotelCount] = useState("1");
  const [hotelDetailForm, setHotelDetailForm] = useState([
    { name: "", url: "" },
  ]);
  const [eventClassDetails, setEventClassDetails] = useState([]);
  const [districId, setDistricId] = useState("");
  const [venueId, setVenueId] = useState(0);
  const [venueName, setVanueName] = useState("");
  const [edi, setEventDetails] = useState(RichTextEditor.createEmptyValue());
  const [eventText, setEventDetail] = useState("");

  const [clubMember, setClubMember] = useState([]);
  const [exbitionClasses, setExbitionClasses] = useState("");
  const [minimumBoatError, setMinimumBoatError] = useState("");
  const [roundsError, setRoundsError] = useState("");
  const [clubDetail, setClubDetails] = useState([]);

  const [uploadImageUrl, setUploadImageUrl] = useState("");
  const [eventName, setEventName] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [strdate, setStrdate] = useState(new Date());
  const [enate, setEnate] = useState(new Date());
  const [openingDate, setOpeningDate] = useState(new Date());
  const [closingDate, setClosingDate] = useState(new Date());
  const [firstBoatFee, setFirstBoatFee] = useState(0);
  const [firstBoaEntrytFee, setFirstBoatEntryFee] = useState(0);
  const [allSelectedClass, setAllSelectedClasses] = useState([]);
  const [allSelectedShootOutClass, setAllSelectedShootOUtClasses] = useState(
    []
  );
  const [largestEntries, setLargestEnteries] = useState(0);
  const [eventDate, setEventDate] = useState();
  const [minEntr, setMinEntr] = useState(0);
  const [maxEntr, setMAxEntr] = useState(0);
  const [classEv, setClassEv] = useState([]);
  const [roundEv, setRoundEv] = useState(0);
  const [minimumBoatToMakeAClass, setMinimumBoatToMakeAClass] = useState(0);
  const [maximumBoatToHeat, setMaximumBoatToHeat] = useState(0);
  const [numberOfRound, setNumberOfRound] = useState(0);
  const [memberOfShootoutRound, setMemberOfShootoutRound] = useState(0);
  const [contactDetails, setContactDetails] = useState("");
  const [mailInPayments, setMailInPayment] = useState(false);
  const [paypalEntries, setPaypalEntries] = useState(false);
  const [payAtRace, setPayAtRace] = useState(false);
  const [eventImage, setEventImage] = useState("");
  const [formValue, setFormValues] = useState({});
  const [baseURL, setBaseUrl] = useState(userConstants.baseURL);
  const [smsNotification, setSmsNotification] = useState(false);
  const [sactions, setSaction] = useState("");
  const [mailCountry, setMailCountry] = useState("");
  const [mailState, setMailState] = useState("");
  const [mailCity, setMailCity] = useState("");
  const [mailAddress, setMailAddress] = useState("");
  const [hostClubId, setHostClubId] = useState("");
  const [hostClubDetails, setHostClubDetails] = useState([]);
  const [editorData, setEditorData] = useState("");
  const [editorImage, setEditorImage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessage2, setErrorMessage2] = useState("");
  const [errorMessage1, setErrorMessage1] = useState("");
  const [errorMessage3, setErrorMessage3] = useState("");
  const [mailZipcode, setMailZipcode] = useState("");
  const [preview, setPreview] = useState();
  const [dateAllow, setDateAllow] = useState("");

  const [isPaypal, setIsPaypal] = useState(false);
  const [selectedClubMember, setSelectedClubMember] = useState([]);
  const [allSelectedClubMember, setAllSelectedClubMember] = useState([]);
  const [numberForMinimumHeat, setNumberforMinimumHeat] = useState([
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
  ]);
  const [numberForMaximumHeat, setNumberforMaximumHeat] = useState([
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
  ]);
  const [selectedOption, setSelectedOption] = useState("");
  const [maxEnteries, setMaxEnteries] = useState(0);
  const [transponderAllow, setTransponderallow] = useState(false);
  const [timeZones, setTimeZones] = useState([]);
  const [selectedTimeZone, setSelectedTimeZone] = useState("");
  const [scheduleClass, setScheduleClass] = useState(false);
  const [emailConfirmations, setEmailConfirmations] = useState(false);
  const [electricClassesTime, setElectricClassesTime] = useState("");
  const [nonElectricClassesTime, setNonElectricClassesTime] = useState("");
  const [electricClassesTimeOptions, setElectricClassesTimeOption] = useState([
    "0:30",
    "1:30",
    "2:00",
    "2:30",
  ]);
  const [nonElectricClassesTimeOptions, setnonElectricClassesTimeOption] =
    useState(["0:30", "1:30", "2:00", "2:30"]);

  const [activeAudios, setActiveAudios] = useState([]);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  useEffect(async () => {
    if (getLocalData("user_info", "isLogin")) {
      var loginUserData = getLocalData("user_info", "all").userData
        .login_user_detail.data.user;
      var userDistricId = getLocalData("user_info", "all").userData
        .login_user_detail.data.user.district.uzip_namba_district;
      var userId = getLocalData("user_info", "all").userData.login_user_detail
        .data.user.id;
      var userClubDetails = getLocalData("user_info", "all").userData
        .login_user_detail.data.user.clubOfficerIn;

      setCurrentDistric(userDistricId);
      handleLocationByDistric(userDistricId);
      var data = {
        user_id: loginUserData.id,
        clubs: true,
      };
      let profileResponse = await combinedServices.getProfileDetails(data);
      const clubNamesArray = profileResponse?.data?.club_members?.map(
        (item) => {
          if (item.club) {
            return item.club.club_name;
          } else {
            return item.club_name;
          }
        }
      );
      let clubByDistric = await combinedServices.getAllClubForDistric(
        userDistricId
      );
      if (clubByDistric.status == 401) {
        swal(clubByDistric.msg, "Please Re-login", "warning").then(() => {
          getRemoveData("user_info");
          localStorage.clear();
          window.location.href = "/login";
        });
      }
      if (clubByDistric.status == 200) {
        if (loginUserData.districtDirector != null) {
          setHostClubDetails(clubByDistric.data);
        } else {
          const filteredArray1 = clubByDistric?.data?.filter((item) =>
            clubNamesArray.includes(item.club_name)
          );

          setHostClubDetails(filteredArray1);
        }
        // setHostClubId(clubByDistric.data[0].id);
        getClubMembers(clubByDistric.data[0].id);
        // checkPaypalCredential(clubByDistric.data[0].id);
      }
      getHostClubDetails(params.id);
      getClubMembers();
      let response = await combinedServices.getAllDistric();
      let classResponse = await combinedServices.getAllClasses(userDistricId);
      const clsDropdown = [];
      classResponse.data.map((cls) => {
        var categoryName =
          cls.class_category_id == "6" ? "EX-" + cls.name : cls.name;
        var options = {
          name: categoryName,
          id: cls.id,
        };
        clsDropdown.push(options);
      });
      setClass(clsDropdown);

      setDistric(response.data);
    }
    let eventClassResponse = await combinedServices.getClassByEvent(params.id);
    if (eventClassResponse.status == 401) {
      swal(eventClassResponse.msg, "Please Re-login", "warning").then(() => {
        getRemoveData("user_info");
        localStorage.clear();
        window.location.href = "/login";
      });
    }
    setEventClassDetails(eventClassResponse?.data?.eventClasses);

    const usAndCanadaTimeZones = [
      "Canada/Eastern",
      "Canada/Central",
      "Canada/Mountain",
      "Canada/Pacific",
      "HST",
    ];
    setTimeZones(usAndCanadaTimeZones);

    const active_audios = await combinedServices.getActiveAudios();
    setActiveAudios(active_audios.data);
  }, []);

  useEffect(() => {
    if (maxEnteries === 0) {
      setSelectedOption("no");
    } else if (maxEnteries > 0) {
      setSelectedOption("yes");
    }
  }, [maxEnteries]);
  const checkPaypalCredential = async (club_id) => {
    let paymentGatewayDetails = await combinedServices.getClubPaypalByIdForUser(
      club_id
    );
    if (paymentGatewayDetails.status == 200) {
      setIsPaypal(true);
    } else {
      setIsPaypal(false);
    }
  };

  const handleSMSNotification = (e) => {
    setSmsNotification(e.target.checked);
    document.getElementById("enable_sms_notification").checked =
      e.target.checked;
  };
  const handleSactions = (e) => {
    setSaction(e.target.value);
    if (e.target.value == "namba") {
      document.getElementById("namba").checked = true;
      document.getElementById("unsaction").checked = false;
    } else {
      document.getElementById("namba").checked = false;
      document.getElementById("unsaction").checked = true;
    }
  };

  const getHostClubDetails = async (id) => {
    const timeaccotolocation2 = (x, timeZone) => {
      if (timeZone == "") {
        timeZone = "Canada/Eastern";
      }
      const utcMoment = moment.utc(x);
      const localMoment = utcMoment.tz(timeZone);

      // const timeZoneAbbreviation = localMoment.format('z');
      const timeZoneOffset = localMoment.format("Z");

      return localMoment.format("ddd MMM DD YYYY");
    };
    let eventClassesData = await combinedServices.getClassByEventId(id);
    if (eventClassesData.status == 200) {
      let clubDetails = eventClassesData.data;
      let clubRichText = clubDetails.detail;
      const eventdate = timeaccotolocation2(
        clubDetails.event_start,
        clubDetails.time_zone
      );
      const eventend = timeaccotolocation2(
        clubDetails.event_end,
        clubDetails.time_zone
      );
      const regisopen = timeaccotolocation2(
        clubDetails.registration_open,
        clubDetails.time_zone
      );
      const regisclose = timeaccotolocation2(
        clubDetails.registration_close,
        clubDetails.time_zone
      );
      // eventdate.split("T")
      setVenueId(clubDetails.venue_id);
      setEventId(clubDetails.id);
      console.log("eventimage,", clubDetails.image);
      setEventImage(clubDetails.image);
      setEventName(clubDetails.full_title);
      setContent(clubDetails.detail);
      setMaxEnteries(clubDetails.max_enteries);
      setTransponderallow(clubDetails.transponder_allow);
      setEmailConfirmations(clubDetails.email_confirmations);
      setMinEntr(clubDetails.min_boats_per_class);
      setMAxEntr(clubDetails.max_boats_per_heat);

      setFirstBoatFee(clubDetails.registration_charge);
      setFirstBoatEntryFee(clubDetails.boat_charge);
      setHotelDetailForm(clubDetails.hotels);

      setEditorData(clubRichText);

      setSmsNotification(clubDetails.enable_sms_notification);
      setSelectedTimeZone(clubDetails.time_zone);
      setSaction(clubDetails.sanctioned_by);
      setMinimumBoatToMakeAClass(clubDetails.min_boats_per_class);
      setMaximumBoatToHeat(clubDetails.max_boats_per_heat);
      setNumberOfRound(clubDetails.rounds_per_class);
      setRoundEv(clubDetails.rounds_per_class);
      setAllSelectedClasses(clubDetails.classes);
      setClassEv(clubDetails.classes);
      setAllSelectedShootOUtClasses(clubDetails.shootout_classes);

      setHostClubId(clubDetails.host_club_id);
      checkPaypalCredential(clubDetails.host_club_id);

      getClubMembers(clubDetails.host_club_id);

      setMailCountry(clubDetails.mail_country);
      setMailState(clubDetails.mail_state);
      setMailCity(clubDetails.mail_city);
      setMailAddress(clubDetails.mail_address);
      setMailZipcode(clubDetails.mail_zip);

      setMemberOfShootoutRound(clubDetails.members_in_shootout);
      setContactDetails(clubDetails.contacts);
      setMailInPayment(clubDetails.allow_mail_ins);
      setPaypalEntries(clubDetails.allow_paypal_entries);
      setPayAtRace(clubDetails.allow_pay_at_race_entries);
      setStartDate(new Date(eventdate));
      setStrdate(new Date(eventdate));
      setEnate(new Date(eventend));
      setEndDate(new Date(eventend));
      setOpeningDate(new Date(regisopen));
      setClosingDate(new Date(regisclose));
      setElectricClassesTime(clubDetails.electric_classes);
      setNonElectricClassesTime(clubDetails.non_electric_classes);
      getAllSelectedClubMember(
        clubDetails.host_club_id,
        clubDetails.authorized_user_ids
      );

      document.getElementById("enable_sms_notification").checked =
        clubDetails.enable_sms_notification;
      if (clubDetails.sanctioned_by == "namba") {
        document.getElementById("namba").checked = true;
      } else {
        document.getElementById("unsaction").checked = true;
      }

      document.getElementById("mailInEntries").checked =
        clubDetails.allow_mail_ins;

      document.getElementById("payAtRace").checked =
        clubDetails.allow_pay_at_race_entries;
      document.getElementById("paypal").checked =
        clubDetails.allow_paypal_entries;
    }
  };

  const handleHostClubId = (e) => {
    setHostClubId(e.target.value);
    getClubMembers();
    checkPaypalCredential(e.target.value);
  };

  const getClubMembers = async () => {
    let clubResponse = await combinedServices.getClubMember();

    if (clubResponse.status == 200) {
      var clubMembers = [];
      clubResponse.data.map((clubMember) => {
        return clubMember.user != null
          ? clubMembers.push({
              id: clubMember.user.id,
              name:
                clubMember.user.user_firstname +
                " " +
                clubMember.user.user_lastname,
            })
          : "";
      });

      setClubMemberDetails(clubMembers);
    }
  };
  const getAllSelectedClubMember = async (id, users) => {
    let clubResponse = await combinedServices.getClubMember();
    if (clubResponse.status == 200) {
      var clubMembers = [];
      clubResponse.data.map((clubMember) => {
        var user_firstname = "";
        var user_lastname = "";
        var id = "";
        if (clubMember.user) {
          user_firstname = clubMember.user.user_firstname;
          user_lastname = clubMember.user.user_lastname;
          id = clubMember.user.id;
        }
        var options = {
          name: user_firstname + " " + user_lastname,
          id: id,
        };

        clubMembers.push(options);
      });

      var newClubMemberArr = [];
      users.map((user) => {
        var clubMembersExit = clubMembers
          .filter((item) => item.id == user)
          .map(({ id, name }) => ({ id, name }));

        var clubOption = {
          name: clubMembersExit[0].name,
          id: clubMembersExit[0].id,
        };
        newClubMemberArr.push(clubOption);
      });

      setAllSelectedClubMember(newClubMemberArr);
    }
  };

  const onSelectClass = (selectedList, selectedItem) => {
    setSelectedClass(selectedList);
    setAllSelectedClasses(selectedList);
  };

  const onRemoveClass = (selectedList, removedItem) => {
    setSelectedClass(selectedList);
    setAllSelectedClasses(selectedList);
  };
  const onSelectClubMember = (selectedList, selectedItem) => {
    setSelectedClubMember(selectedList);
  };
  const onRemoveClubMember = (selectedList, selectedItem) => {
    setSelectedClubMember(selectedList);
  };
  const onSelectShootOutClass = (selectedList, selectedItem) => {
    setSelectedShootOutClasses(selectedList);
  };
  const onRemoveShootOutClass = (selectedList, removedItem) => {
    setSelectedShootOutClasses(selectedList);
  };

  const handleUploadImage = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      setErrorMessage("");

      new Compressor(img, {
        quality: 0.6, // Adjust compression quality as needed
        success(compressedImg) {
          const objectUrl = URL.createObjectURL(compressedImg);
          setPreview(objectUrl);

          const formdata = new FormData();
          formdata.append("image", compressedImg, compressedImg.name);
          setUploadImageUrl(formdata);
        },
        error(err) {
          console.error("Image compression error:", err);
          setErrorMessage("Error compressing image.");
        },
      });
    }
  };

  const uploadImage = async (formdata) => {
    let response = await combinedServices.uploadImage(formdata);
    if (response.status == 200) {
      setUploadImageUrl(response.data.file_name);
    }
  };

  const handleLocationByDistric = async (districId) => {
    var locationArr = [];
    var response = await combinedServices.getMyClubSite(districId);

    response.data.map((value) => {
      var data = {
        id: value.id,
        venue: value.clbsite_site_name,
        address:
          value.clbsite_site_address1 +
          "," +
          value.clbsite_site_city +
          "," +
          value.clbsite_site_state +
          "," +
          value.clbsite_site_country +
          "," +
          value.clbsite_site_zip,
      };

      locationArr.push(data);
    });
    setLocation(locationArr);
  };

  const handleLocation = (event) => {
    var address =
      event.target.options[event.target.selectedIndex].dataset.address;
    var venueName =
      event.target.options[event.target.selectedIndex].dataset.name;
    setVenueId(event.target.value);
    setVanueName(venueName);

    setLocationAccordingToDistric(address);
  };

  let handleChange = (i, e) => {
    let newFormValues = [...hotelDetailForm];
    newFormValues[i][e.target.name] = e.target.value;
    setHotelDetailForm(newFormValues);
  };

  let addFormFields = () => {
    setHotelDetailForm([...hotelDetailForm, { name: "", url: "" }]);
  };

  let removeFormFields = (i) => {
    let newFormValues = [...hotelDetailForm];
    newFormValues.splice(i, 1);
    setHotelDetailForm(newFormValues);
  };
  const handleChangeReportPeragraph = (value) => {
    setEventDetails(value);
  };
  const handleMailInPayment = (e) => {
    document.getElementById("mailInEntries").checked = e.target.checked;
    setMailInPayment(e.target.checked);
  };
  function convertToUTC(dateTimeStr) {
    const datePart =
      dateTimeStr.split(" ")[1] +
      " " +
      dateTimeStr.split(" ")[2] +
      " " +
      dateTimeStr.split(" ")[3];
    console.log("datepart", datePart);
    const dateOnly = moment
      .tz(datePart, "MMM DD YYYY", selectedTimeZone)
      .startOf("day");

    const utcDate = moment.utc(dateOnly);

    const timeZone = utcDate.format("z");

    return utcDate.toISOString();
  }

  function convertToUTCclose(dateTimeStr) {
    const datePart =
      dateTimeStr.split(" ")[1] +
      " " +
      dateTimeStr.split(" ")[2] +
      " " +
      dateTimeStr.split(" ")[3];

    const dateOnly = moment
      .tz(datePart, "MMM DD YYYY", selectedTimeZone)
      .set({ hour: 23, minute: 59, second: 1 });

    const utcDate = moment.utc(dateOnly);

    return utcDate.toISOString();
  }

  const handlePaypalEntries = (e) => {
    setPaypalEntries(e.target.checked);

    document.getElementById("paypal").checked = e.target.checked;
  };
  const handlePayAtRace = (e) => {
    document.getElementById("payAtRace").checked = e.target.checked;
    setPayAtRace(e.target.checked);
  };
  const handleAddExbition = (e) => {
    setExbitionClasses(e.target.value);
  };
  const handleExbitionClass = async () => {
    if (exbitionClasses) {
      var data = {
        name: exbitionClasses,
        exhibition_class: true,
        district_id: currentDistric,
      };

      let response = await combinedServices.addExbitionClasses(data);
      if (response.status !== "") {
        swal("Add Exbition Class Successfully").then(async (value) => {
          setExbitionClasses("");
          const clsDropdown = [];
          let classResponse = await combinedServices.getAllClasses(
            currentDistric
          );

          classResponse.data.map((cls) => {
            var categoryName =
              cls.class_category_id == "6" ? "EX-" + cls.name : cls.name;

            var options = {
              name: categoryName,
              id: cls.id,
            };
            clsDropdown.push(options);
          });
          setClass(clsDropdown);
        });
      } else {
        swal("Opps!", response.msg, "error");
      }
    } else {
      swal("Opps!", "Please Enter Exhibition Class", "error");
    }
  };
  const handleOptionClick = (option) => {
    if (option == "no") {
      setMaxEnteries(0);
      setLargestEnteries(0);
    } else {
      let largestEntriesCount = -1; // Initialize with a small value
      let largestEntriesCountObject = null;

      for (const obj of eventClassDetails) {
        const entriesCount = parseInt(obj.entriesCount); // Convert to integer
        if (entriesCount > largestEntriesCount) {
          largestEntriesCount = entriesCount;
          largestEntriesCountObject = obj;
        }
      }
      setLargestEnteries(parseInt(largestEntriesCountObject.entriesCount));
    }
    setSelectedOption(option);
  };
  const handleOptionClick1 = (option) => {
    setTransponderallow(option);
  };

  const addTemplateForm = async () => {
    let isDifferent = false;

    // Compare roundEv and numberOfRound
    if (roundEv !== numberOfRound) {
      isDifferent = true;
    }
    if (JSON.stringify(classEv) !== JSON.stringify(allSelectedClass)) {
      isDifferent = true;
    }
    if (moment(startDate).format() !== moment(strdate).format()) {
      isDifferent = true;
    }
    if (moment(endDate).format() !== moment(enate).format()) {
      isDifferent = true;
    }
    // Compare minEntr and minimumBoat
    if (minEntr !== minimumBoatToMakeAClass) {
      isDifferent = true;
    }

    // Compare maxEntr and maximumBoatToHeat
    if (maxEntr !== maximumBoatToHeat) {
      isDifferent = true;
    }

    // Show the SweetAlert confirmation popup
    if (isDifferent) {
      const confirmationResult = await swal({
        title: "Confirmation",
        text: "Changing event dates will result in all schedules and generated heats to be reset?",
        icon: "warning",
        buttons: ["Cancel", "OK"],
      });

      if (confirmationResult) {
        var uploadImageText = uploadImageUrl ? uploadImageUrl : eventImage;

        if (eventName == "") {
          swal("Opps!", "Please Enter The Event Name", "error");
        } else if (venueId == 0) {
          swal("Opps!", "Please Select Location", "error");
        } else if (maxEnteries < largestEntries) {
          swal(
            "Opps!",
            "Please input value greater than Enteries Count",
            "error"
          );
        } else if (
          selectedClasses.length == 0 &&
          allSelectedClass.length == 0
        ) {
          swal("Opps!", "Please Select Classes", "error");
        } else if (minimumBoatToMakeAClass == 0) {
          swal("Opps!", "Please Select Minimum Boat To Make A Class", "error");
        } else if (maximumBoatToHeat == 0) {
          swal("Opps!", "Please Select Maximum Boat To Heat", "error");
        } else if (maximumBoatToHeat <= minimumBoatToMakeAClass) {
          swal(
            "Opps!",
            "Please Select Maximum Boats From Minimum Boats ",
            "error"
          );
        } else if (numberOfRound == 0) {
          swal("Opps!", "Please Select No. Of Round", "error");
        } else if (contactDetails == "") {
          swal("Opps!", "Please Enter Contact Details", "error");
        } else if (!paypalEntries && !payAtRace && !mailInPayments) {
          swal("Opps!", "Please Check Atleast one Payment Type", "error");
        } else if (hotelDetailForm.length == 0) {
          swal("Opps!", "Please Enter Hotels", "error");
        } else {
          var image = uploadImageText;
          var classIds = [];
          var shootOutclassIds = [];
          var authorizedUser = [];
          if (uploadImageUrl) {
            let response = await combinedServices.uploadImage(uploadImageUrl);

            if (response.status == 200) {
              image = response.data.data.file_name;
            }
          }
          var clubMembers =
            selectedClubMember.length > 0
              ? selectedClubMember
              : allSelectedClubMember;
          var classDetails =
            selectedClasses.length > 0 ? selectedClasses : allSelectedClass;
          classDetails.map((value) => {
            classIds.push(value.id);
          });
          clubMembers.map((value) => {
            authorizedUser.push(value.id);
          });
          shootOutclassIds =
            selectedShootOutClasses.length > 0
              ? selectedShootOutClasses
              : allSelectedShootOutClass;

          var location = locationAccordingToDistric.split(",");
          const eventstart = convertToUTC(String(startDate, selectedTimeZone));
          const eventregisopen = convertToUTC(
            String(openingDate, selectedTimeZone)
          );
          //Fri Jan 19 2024 16:16:54 GMT+0530 (India Standard Time)
          const eventclose = convertToUTCclose(
            String(endDate, selectedTimeZone)
          );
          const eventregisclose = convertToUTCclose(
            String(closingDate, selectedTimeZone)
          );

          var data = {
            full_title: eventName,
            // venue: venueName,
            event_start: eventstart,
            event_end: eventclose,
            registration_open: eventregisopen,
            registration_close: eventregisclose,
            time_zone: selectedTimeZone,

            image: image,
            contacts: contactDetails,
            enable_sms_notification: smsNotification,
            classIds: classIds,
            min_boats_per_class: minimumBoatToMakeAClass,
            max_boats_per_heat: maximumBoatToHeat,
            rounds_per_class: numberOfRound,
            registration_charge: firstBoatFee,
            boat_charge: firstBoaEntrytFee,
            max_enteries: maxEnteries,
            transponder_allow: transponderAllow,
            date_allow: dateAllow,
            hotels: hotelDetailForm,
            shootout_classes: shootOutclassIds,
            frequency_dsm: true,
            detail: editorData.toString("html"),
            members_in_shootout: memberOfShootoutRound,
            alternate_frequency_required: true,
            max_boat_limit: 150,
            district_id: currentDistric,
            host_club_id: hostClubId,
            allow_paypal_entries: Boolean(paypalEntries ? true : false),
            allow_mail_ins: Boolean(mailInPayments ? true : false),
            allow_pay_at_race_entries: Boolean(payAtRace ? true : false),
            sanctioned_by: sactions,
            venue_id: Number(venueId),
            email_confirmations: emailConfirmations,
            authorized_user_ids: authorizedUser,
            event_id: eventId,
            electric_classes: electricClassesTime,
            non_electric_classes: nonElectricClassesTime,
          };

          if (mailInPayments) {
            data.mail_zip = mailZipcode;
            data.mail_state = mailState;
            data.mail_city = mailCity;
            data.mail_address = mailAddress;
          } else {
            data.mail_zip = "";
            data.mail_country = "";
            data.mail_state = "";
            data.mail_city = "";
            data.mail_address = "";
          }
          let eventResponse = await combinedServices.editEvent(eventId, data);

          if (eventResponse.status == 200) {
            setEditorData(eventResponse);
            swal("Event Updated Successfully.").then((value) => {
              navigate("../event-management", { replace: true });
            });
          } else {
            swal("!Opps", "You are not authorised", "error");
          }
        }
        // User clicked "OK," so proceed with the loop to remove heats

        for (let i = 1; i <= numberOfRound; i++) {
          let data = {
            event_id: Number(eventId),
            round: Number(i),
            scheduleClass: scheduleClass,
          };

          let removeHeatsResponse = combinedServices.removeHeats(data);
        }
      } else {
        // User clicked "Cancel," so show a message and stop here
        swal("Event not updated.", "Your event has not been updated.", "info");
        return;
      }
    } else {
      var uploadImageText = uploadImageUrl ? uploadImageUrl : eventImage;

      if (eventName == "") {
        swal("Opps!", "Please Enter The Event Name", "error");
      } else if (venueId == 0) {
        swal("Opps!", "Please Select Location", "error");
      } else if (maxEnteries < largestEntries) {
        swal(
          "Opps!",
          "Please input value greater than Enteries Count",
          "error"
        );
      } else if (selectedClasses.length == 0 && allSelectedClass.length == 0) {
        swal("Opps!", "Please Select Classes", "error");
      } else if (minimumBoatToMakeAClass == 0) {
        swal("Opps!", "Please Select Minimum Boat To Make A Class", "error");
      } else if (maximumBoatToHeat == 0) {
        swal("Opps!", "Please Select Maximum Boat To Heat", "error");
      } else if (maximumBoatToHeat <= minimumBoatToMakeAClass) {
        swal(
          "Opps!",
          "Please Select Maximum Boats From Minimum Boats ",
          "error"
        );
      } else if (numberOfRound == 0) {
        swal("Opps!", "Please Select No. Of Round", "error");
      } else if (contactDetails == "") {
        swal("Opps!", "Please Enter Contact Details", "error");
      } else if (!paypalEntries && !payAtRace && !mailInPayments) {
        swal("Opps!", "Please Check Atleast one Payment Type", "error");
      } else if (hotelDetailForm.length == 0) {
        swal("Opps!", "Please Enter Hotels", "error");
      } else if (selectedTimeZone == "") {
        swal("Opps!", "Please Select Time Zone", "error");
      } else {
        var image = uploadImageText;
        var classIds = [];
        var shootOutclassIds = [];
        var authorizedUser = [];
        if (uploadImageUrl) {
          let response = await combinedServices.uploadImage(uploadImageUrl);
          console.log("pppppppp", response);
          if (response.status == 200) {
            image = response.data.location;
          }
        }
        var clubMembers =
          selectedClubMember.length > 0
            ? selectedClubMember
            : allSelectedClubMember;
        var classDetails =
          selectedClasses.length > 0 ? selectedClasses : allSelectedClass;
        classDetails.map((value) => {
          classIds.push(value.id);
        });
        clubMembers.map((value) => {
          authorizedUser.push(value.id);
        });
        shootOutclassIds =
          selectedShootOutClasses.length > 0
            ? selectedShootOutClasses
            : allSelectedShootOutClass;

        var location = locationAccordingToDistric.split(",");

        const eventstart = convertToUTC(String(startDate));
        const eventregisopen = convertToUTC(String(openingDate));

        const eventclose = convertToUTCclose(String(endDate));
        const eventregisclose = convertToUTCclose(String(closingDate));
        var data = {
          full_title: eventName,
          event_start: eventstart,
          event_end: eventclose,
          registration_open: eventregisopen,
          registration_close: eventregisclose,
          time_zone: selectedTimeZone,
          image: image,
          contacts: contactDetails,
          enable_sms_notification: smsNotification,
          classIds: classIds,
          min_boats_per_class: minimumBoatToMakeAClass,
          max_boats_per_heat: maximumBoatToHeat,
          rounds_per_class: numberOfRound,
          registration_charge: firstBoatFee,
          boat_charge: firstBoaEntrytFee,
          max_enteries: maxEnteries,
          transponder_allow: transponderAllow,
          date_allow: dateAllow,
          hotels: hotelDetailForm,
          shootout_classes: shootOutclassIds,
          frequency_dsm: true,
          detail: editorData.toString("html"),
          members_in_shootout: memberOfShootoutRound,
          alternate_frequency_required: true,
          max_boat_limit: 150,
          district_id: currentDistric,
          host_club_id: hostClubId,
          allow_paypal_entries: Boolean(paypalEntries ? true : false),
          allow_mail_ins: Boolean(mailInPayments ? true : false),
          allow_pay_at_race_entries: Boolean(payAtRace ? true : false),
          sanctioned_by: sactions,
          venue_id: Number(venueId),
          electric_classes: electricClassesTime,
          non_electric_classes: nonElectricClassesTime,
          authorized_user_ids: authorizedUser,
          event_id: eventId,
          email_confirmations: emailConfirmations,
        };

        if (mailInPayments) {
          data.mail_zip = mailZipcode;
          data.mail_state = mailState;
          data.mail_city = mailCity;
          data.mail_address = mailAddress;
        } else {
          data.mail_zip = "";
          data.mail_country = "";
          data.mail_state = "";
          data.mail_city = "";
          data.mail_address = "";
        }
        let eventResponse = await combinedServices.editEvent(eventId, data);

        if (eventResponse.status == 200) {
          setEditorData(eventResponse);
          swal("Event Updated Successfully.").then((value) => {
            navigate("../event-management", { replace: true });
          });
        } else {
          swal("!Opps", eventResponse.msg, "error");
        }
      }
    }
  };
  const handleFormData = (e) => {
    e.preventDefault();

    setFormValues(([e.target.name] = e.target.value));
  };
  const handleContactDetail = (e) => {
    setContactDetails(e.target.value);
  };
  const handleFormDataEventName = (e) => {
    setEventName(e.target.value);
  };
  const handleFormDataMailCountry = (e) => {
    setMailCountry(e.target.value);
  };
  const handleFormDataMailState = (e) => {
    setMailState(e.target.value);
  };
  const handleFormDataMailCity = (e) => {
    setMailCity(e.target.value);
  };

  const handleFormDataMailAddress = (e) => {
    setMailAddress(e.target.value);
  };

  const handleFormDataMailZip = (e) => {
    setMailZipcode(e.target.value);
  };
  const handleFirstBoatEntryFee = (event) => {
    const inputValue = event.target.value;
    setFirstBoatEntryFee(inputValue);
  };
  const handleFirstBoatFee = (event) => {
    const inputValue = event.target.value;
    setFirstBoatFee(inputValue);
  };
  const handleMinimumBOatToMakeAClass = (e) => {
    setMinimumBoatToMakeAClass(e.target.value);
    var new_arr = numberForMaximumHeat.filter(function (x) {
      return x > e.target.value;
    });
    setNumberforMaximumHeat(new_arr);
  };
  const handleMemberOfShootoutRound = (e) => {
    setMemberOfShootoutRound(e.target.value);
  };
  const handleMaximumBoatFotHeat = (e) => {
    setMaximumBoatToHeat(e.target.value);
  };
  const handleNumberOfRound = (e) => {
    setNumberOfRound(e.target.value);
  };

  const handleStartDate = (date) => {
    setStartDate(date);
    setScheduleClass(true);
  };
  const handleOpeningDate = (date) => {
    setOpeningDate(date);
  };

  const toolbarSettings = {
    items: [
      "Bold",
      "Italic",
      "Underline",
      "StrikeThrough",
      "FontName",
      "FontSize",
      "FontColor",
      "BackgroundColor",
      "LowerCase",
      "UpperCase",
      "|",
      "Formats",
      "Alignments",
      "OrderedList",
      "UnorderedList",
      "Outdent",
      "Indent",
      "|",
      "CreateLink",
      "Image",
      "|",
      "ClearFormat",
      "Print",
      "SourceCode",
      "FullScreen",
      "|",
      "Undo",
      "Redo",
      "createTable",
    ],
  };
  const quickToolbarSettings = {
    image: [
      "Replace",
      "Align",
      "Caption",
      "Remove",
      "InsertLink",
      "OpenImageLink",
      "-",
      "EditImageLink",
      "RemoveImageLink",
      "Display",
      "AltText",
      "Dimension",
    ],
    link: ["Open", "Edit", "UnLink"],
  };
  const saveContent = (e) => {
    // Create a temporary DOM element to work with the HTML content
    const tempElement = document.createElement("div");
    tempElement.innerHTML = e.value;

    // Loop through all the images in the content
    const images = tempElement.querySelectorAll("img");
    images.forEach((img) => {
      // Check if the image has a blob URL
      if (img.src.startsWith("blob:")) {
        // Replace the blob URL with the actual image URL
        img.src = imageUrl; // Replace with your actual image URL
      }
    });

    // Convert the modified HTML content back to a string and update the editor data
    const modifiedContent = tempElement.innerHTML;
    setEditorData(modifiedContent);
  };

  const insertImageSettings = {
    saveUrl:
      "https://services.syncfusion.com/js/production/api/FileUploader/Save",
    removeUrl:
      "https://services.syncfusion.com/js/production/api/FileUploader/Remove",
    height: 165,
    width: 165,
  };
  const imageUploadHandler = async (args) => {
    try {
      const files = args.file.rawFile;
      const formData = new FormData();
      formData.append("image", files);

      const response = await combinedServices.uploadImage(formData);
      const imageUrl =
        (await baseURL) + "images/" + response.data.data.file_name;

      // Create a new image element
      const imageElement = document.createElement("img");
      imageElement.setAttribute("src", imageUrl);
      imageElement.setAttribute("alt", "Uploaded Image");
      imageElement.setAttribute("width", "165px"); // Adjust as needed
      imageElement.setAttribute("height", "165px"); // Adjust as needed
      setImageUrl(imageUrl);
      // Create a new paragraph element
      const paragraph = document.createElement("p");
      paragraph.appendChild(imageElement);
      editorData.append(paragraph);
    } catch (error) {
      console.error(error);
    }
  };

  const handleMaxEnteries = (event) => {
    const inputValue = event.target.value;
    if (inputValue < largestEntries) {
      setMaxEnteries(inputValue);
      setErrorMessage3(`You cannot put value less then ${largestEntries}`);
    } else {
      setMaxEnteries(inputValue);
      setErrorMessage3("");
    }
  };

  const handleImageUpload = async (file) => {
    try {
      const formData = new FormData();
      formData.append("image", file);
      const response = await combinedServices.uploadImage(formData);
      const imageUrl =
        (await baseURL) + "images/" + response.data.data.file_name;
      const editor = document.querySelector("#ckeditor");
      const imageElement = document.createElement("img");
      imageElement.setAttribute("src", imageUrl);
      return imageUrl;
      editor.appendChild(imageElement);
    } catch (error) {
      console.error(error);
    }
  };
  const handleTimeZoneChange = (event) => {
    setSelectedTimeZone(event.target.value);
  };
  const timeaccotolocation = (x) => {
    const utcMoment = moment.utc(x);
    const localMoment = utcMoment.local();
    return localMoment.toString();
  };

  const handleClockChange = (e, electric_nonElectric) => {
    if (electric_nonElectric == "electric") {
      console.log("electric", e.target.value);
      setElectricClassesTime(e.target.value);
    } else {
      setNonElectricClassesTime(e.target.value);
      console.log("nonelectric", e.target.value);
    }
  };

  const handleEmailCheck = (e) => {
    setEmailConfirmations(e.target.checked);
  };

  return (
    <>
      <Header />
      <div className="dbRight">
        <div className="dbMid dbInner">
          <div className="flexBox spacebetween topHdBox">
            <div className="left ecTop">
              <div className="dbMainHd">Create Race</div>

              <div className="text">
                NOTE: Only 'Mail In' transactions can be processed until you
                provide a PayPal email address to receive funds.
              </div>
            </div>
          </div>

          <div className="dbWhtBox">
            <div className="memberOptionBox d-flex align-items-end mb30">
              <div className="uploadImg">
                <img
                  src={
                    preview
                      ? preview
                      : eventImage.startsWith("https://nambaweb")
                      ? eventImage
                      : baseURL + "images/" + eventImage
                  }
                  alt=""
                  className="icon"
                />
              </div>
              <div>
                {errorMessage && (
                  <div style={{ color: "red" }}>{errorMessage}</div>
                )}
                <span className="uploadBtn">
                  Upload Image{" "}
                  <input
                    type="file"
                    className="fileInput"
                    name="uploadImage"
                    onChange={handleUploadImage}
                  />
                </span>
              </div>
            </div>
            <div className="d-flex flex-wrap topColumn">
              <div className="topColumn1">
                <div className="dbSubHd">Event Name</div>
                <ul className="flexBox createEventForm">
                  <li className="full">
                    <span className="fieldHd">Event Name</span>
                    <input
                      type="text"
                      className="formInput"
                      placeholder="Enter Event Name"
                      name="full_title"
                      value={eventName}
                      onChange={handleFormDataEventName}
                    />
                  </li>
                </ul>
              </div>
              <div className="topColumn3">
                <div className="dbSubHd">Event Time Zone</div>
                <ul className="flexBox createEventForm smallForm">
                  <li className="full">
                    <span className="fieldHd">Time Zone</span>
                    <select
                      className="formSelect"
                      onChange={handleTimeZoneChange}
                      value={selectedTimeZone}
                      required
                    >
                      <option value="" disabled>
                        Select a time zone
                      </option>
                      {timeZones.map((zone) => (
                        <option key={zone} value={zone}>
                          {zone == "HST" ? "Hawaiin" : zone.split("/")[1]}
                        </option>
                      ))}
                    </select>
                  </li>
                </ul>
              </div>

              <div className="topColumn2">
                <div className="dbSubHd">Event Date</div>
                <ul className="flexBox createEventForm">
                  <li>
                    <span className="fieldHd">Start Date</span>
                    <div className="pr">
                      <DatePicker
                        className="formInput datepicker"
                        selected={startDate}
                        onChange={(date) => handleStartDate(date)}
                        minDate={new Date()}
                      />
                    </div>
                  </li>
                  <li>
                    <span className="fieldHd">End Date</span>
                    <div className="pr">
                      <DatePicker
                        className="formInput datepicker"
                        selected={endDate}
                        // selected={timeaccotolocation(endDate)}
                        onChange={(date) => setEndDate(date)}
                        minDate={startDate}
                      />
                    </div>
                  </li>
                </ul>
              </div>

              <div className="topColumn3">
                <div className="dbSubHd">Event Location</div>
                <ul className="flexBox createEventForm">
                  <li className="full">
                    <span className="fieldHd">Location</span>

                    <select
                      className="formSelect"
                      name="location"
                      value={venueId}
                      onChange={handleLocation}
                    >
                      <option value="0" disabled>
                        Select Location
                      </option>
                      {location.map((val) => {
                        return (
                          <option
                            data-address={val.address}
                            data-name={val.venue}
                            value={val.id}
                          >
                            {val.address}
                          </option>
                        );
                      })}
                    </select>
                  </li>
                </ul>
              </div>
            </div>
            <div className="dbSubHd">Event Registration</div>
            <ul className="flexBox createEventForm smallForm">
              <li>
                <span className="fieldHd">Opening Date</span>
                <div className="pr">
                  <DatePicker
                    className="formInput datepicker"
                    selected={openingDate}
                    onChange={(date) => handleOpeningDate(date)}
                    minDate={new Date()}
                    maxDate={startDate}
                  />
                </div>
              </li>
              <li>
                <span className="fieldHd">Closing Date</span>
                <div className="pr">
                  <DatePicker
                    className="formInput datepicker"
                    selected={closingDate}
                    onChange={(date) => setClosingDate(date)}
                    minDate={new Date()}
                    maxDate={startDate}
                  />
                </div>
              </li>
            </ul>
            <div className="dbSubHd">Entry Fees</div>
            <ul className="flexBox createEventForm smallForm">
              <li>
                <span className="fieldHd">Entry Fee</span>
                <div className="feeBox d-flex align-items-center">
                  <span>$</span>
                  <input
                    type="text"
                    className="formInput"
                    name="boat_charge"
                    value={firstBoatFee}
                    onChange={handleFirstBoatFee}
                    placeholder="15"
                  />
                </div>
                {errorMessage1 && (
                  <div className="errorContainer">
                    <span className="errorText">{errorMessage1}</span>
                  </div>
                )}
              </li>
              <li>
                <span className="fieldHd">Per Boat</span>
                <div className="feeBox d-flex align-items-center">
                  <span>$</span>
                  <input
                    type="text"
                    className="formInput"
                    name="registration_charge"
                    value={firstBoaEntrytFee}
                    onChange={handleFirstBoatEntryFee}
                    placeholder="10"
                  />
                </div>
                {errorMessage2 && (
                  <div className="errorContainer">
                    <span className="errorText">{errorMessage2}</span>
                  </div>
                )}
              </li>
            </ul>
            <ul className="flexBox createEventForm smallForm">
              <li className="">
                <span className="fieldHd">Host Club Name</span>

                <select
                  className="formSelect"
                  name="host_club_id"
                  value={hostClubId}
                  onChange={handleHostClubId}
                >
                  <option value="">Select</option>

                  {hostClubDetails.length > 0
                    ? hostClubDetails.map((value) => {
                        return (
                          <option
                            selected={value.id == hostClubId ? "selected" : ""}
                            value={value.id}
                          >
                            {value.club_name}
                          </option>
                        );
                      })
                    : ""}
                </select>
              </li>
              <li className="">
                <span className="fieldHd">Contest Directors</span>
                <Multiselect
                  options={clubMemberDetails}
                  onSelect={onSelectClubMember}
                  onRemove={onRemoveClubMember}
                  selectedValues={allSelectedClubMember}
                  displayValue="name"
                />
              </li>
            </ul>
            <div className="dbSubHd">Classes</div>
            <ul className="flexBox createEventForm smallForm">
              <li className="full">
                <span className="fieldHd">Classes</span>
                <Multiselect
                  options={classes}
                  onSelect={onSelectClass}
                  onRemove={onRemoveClass}
                  selectedValues={allSelectedClass}
                  displayValue="name"
                />
              </li>
            </ul>

            <div className="dbSubHd">Race Clock</div>
            <ul className="flexBox createEventForm smallForm">
              <li className="">
                <span className="fieldHd">Non Electric Classes</span>
                <select
                  className="formSelect"
                  name="non_electric_classes_time"
                  value={nonElectricClassesTime}
                  onChange={(e) => {
                    handleClockChange(e, "nonElectric");
                  }}
                >
                  <option value="">Select</option>
                  {nonElectricClassesTimeOptions.map((value) => (
                    <option value={value}>{value}</option>
                  ))}
                </select>
              </li>

              <li className="">
                <span className="fieldHd">Electric Classes</span>
                <select
                  className="formSelect"
                  name="electric_classes_time"
                  value={electricClassesTime}
                  onChange={(e) => {
                    handleClockChange(e, "electric");
                  }}
                >
                  <option value="">Select</option>
                  {electricClassesTimeOptions.map((value) => (
                    <option value={value}>{value}</option>
                  ))}
                </select>
              </li>
              {activeAudios?.map((aud) => (
                <div
                  style={{
                    display: "flex",
                    width: "60%",
                    alignItems: "center",
                  }}
                >
                  <audio
                    controls
                    style={{ height: "40px", width: "100%", margin: "5px" }}
                  >
                    <source src={aud.audio_url} type="audio/mpeg" />
                  </audio>
                  <span>{aud.audio_len}</span>
                </div>
              ))}
            </ul>

            <div className="email-notifications">
        
              <input
                type="checkbox"
                name="allow_mail_ins"
                id="mailInEntries"
                checked={selectedOption == "yes"}
                onChange={(e) =>
                  handleOptionClick(e.target.checked ? "yes" : "no")
                } // Use onChange instead of onClick
              />{" "}
          <label for="mailInEntries">Limit Entries</label>
              <br />
            </div>

            {selectedOption == "yes" && (
              <>
                <ul className="flexBox createEventForm">
                  <li className="entriesField">
                    <span className="fieldHd">Maximum Entries</span>
                    <input
                      type="text"
                      className="formInput"
                      name="max_Enteries"
                      value={maxEnteries}
                      onChange={handleMaxEnteries}
                    />
                    {errorMessage3 && (
                      <div className="errorContainer">
                        {/* <FontAwesomeIcon icon={faInfoCircle} /> */}
                        <span className="errorText">{errorMessage3}</span>
                      </div>
                    )}
                  </li>
                </ul>
              </>
            )}
            
              
            <div className="email-notifications">
                    <input
                      type="checkbox"
                      name="allow_mail_ins"
                      id="transponder-allow"
                      checked={transponderAllow == true}
                      onChange={(e) => handleOptionClick1(e.target.checked)} // Use onChange instead of onClick
                    />{" "}
                     <label for="transponder-allow">Use Transponder</label>
              <br />
            </div>
                    
             

            <div className="email-notifications">
              <input
                type="checkbox"
                id="emailConfirmations"
                name="emailConfirmations"
                checked={emailConfirmations}
                onChange={(e) => handleEmailCheck(e)}
              />
              <label for="emailConfirmations">Email Confirmations</label>
              <br />
            </div>

            <div className="dbSubHd">Exhibition Classes </div>
            <ul className="flexBox createEventForm smallForm">
              <li className="full">
                <span className="fieldHd">Exhibition Classes</span>

                <div className="flexBox itemCenter nowrap">
                  <input
                    type="text"
                    className="formInput"
                    name="exhibitionClass"
                    onChange={handleAddExbition}
                    value={exbitionClasses}
                  />
                  <div className="addBtnNew">
                    <button type="button" onClick={handleExbitionClass}>
                      Add
                    </button>
                  </div>
                </div>
              </li>
            </ul>
            <div className="dbSubHd">Boat Per Heat </div>
            <ul className="flexBox createEventForm smallForm">
              <li className="">
                <span className="fieldHd">Minimum Entries</span>
                <select
                  className="formSelect"
                  name="min_boats_per_class"
                  value={minimumBoatToMakeAClass}
                  onChange={handleMinimumBOatToMakeAClass}
                >
                  <option value="">Select</option>
                  {numberForMinimumHeat.map((value) => (
                    <option value={value}>{value}</option>
                  ))}
                </select>
              </li>

              <li className="">
                <span className="fieldHd">Maximum Boats Per Heat</span>
                <select
                  className="formSelect"
                  name="max_boats_per_heat"
                  value={maximumBoatToHeat}
                  onChange={handleMaximumBoatFotHeat}
                >
                  <option value="">Select</option>
                  {numberForMaximumHeat.map((value) => (
                    <option value={value}>{value}</option>
                  ))}
                </select>
              </li>
            </ul>
            <div className="dbSubHd">No. of Rounds</div>
            <ul className="flexBox createEventForm smallForm">
              <li className="full">
                <select
                  className="formSelect"
                  name="rounds_per_class"
                  value={numberOfRound}
                  onChange={handleNumberOfRound}
                >
                  <option value="">Select</option>

                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                </select>
              </li>
            </ul>
            <div className="dbSubHd">Shootout Classes</div>
            <ul className="flexBox createEventForm smallForm">
              <li className="full">
                <span className="fieldHd">Classes</span>

                <Multiselect
                  options={classes}
                  onSelect={onSelectShootOutClass}
                  onRemove={onRemoveShootOutClass}
                  selectedValues={allSelectedShootOutClass}
                  displayValue="name"
                />
              </li>
            </ul>
            {(() => {
              if (
                selectedShootOutClasses.length > 0 ||
                allSelectedShootOutClass.length > 0
              ) {
                return (
                  <>
                    <div className="dbSubHd">
                      No. of Candidates are eligible for Shootout round
                    </div>
                    <ul className="flexBox createEventForm smallForm">
                      <li className="full">
                        <select
                          className="formSelect"
                          name="members_in_shootout"
                          value={memberOfShootoutRound}
                          onChange={handleMemberOfShootoutRound}
                        >
                          <option value="0">Select</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                        </select>
                      </li>
                    </ul>
                  </>
                );
              }

              return null;
            })()}

            <div className="dbSubHd">Contacts</div>
            <ul className="flexBox createEventForm smallForm">
              <li className="full">
                <span className="fieldHd">Contacts</span>
                <textarea
                  rows="4"
                  cols="4"
                  className="formInput"
                  name="contacts"
                  value={contactDetails}
                  onChange={handleContactDetail}
                ></textarea>

                <div className="exText">
                  Please enter only one contact name and number per line.
                </div>
              </li>
            </ul>
            <div className="dbSubHd">Payment Type</div>
            <ul className="flexBox createEventForm smallForm">
              <li className="full maxWidth100">
                <div className="memberOptionBox">
                  {isPaypal && (
                    <label className="labelText">
                      <input
                        type="checkbox"
                        name="allow_paypal_entries"
                        id="paypal"
                        checked={paypalEntries == true}
                        onClick={handlePaypalEntries}
                      />{" "}
                      Paypal
                    </label>
                  )}
                  <label className="labelText">
                    <input
                      type="checkbox"
                      name="allow_mail_ins"
                      id="mailInEntries"
                      checked={mailInPayments == true}
                      onClick={handleMailInPayment}
                    />{" "}
                    Mail in entries
                  </label>
                  <label className="labelText">
                    <input
                      type="checkbox"
                      name="pay_at_race"
                      id="payAtRace"
                      checked={payAtRace == true}
                      onClick={handlePayAtRace}
                    />{" "}
                    Pay at Race
                  </label>
                </div>
              </li>
            </ul>

            {mailInPayments == true && (
              <>
                <ul className="flexBox createEventForm">
                  <li className="entriesField">
                    <span className="fieldHd">Address</span>
                    <input
                      type="text"
                      className="formInput"
                      name="mail_address"
                      value={mailAddress}
                      onChange={handleFormDataMailAddress}
                    />
                  </li>
                  <li className="entriesField">
                    <span className="fieldHd">City</span>
                    <input
                      type="text"
                      className="formInput"
                      name="mail_city"
                      value={mailCity}
                      onChange={handleFormDataMailCity}
                    />
                  </li>
                  <li className="entriesField">
                    <span className="fieldHd">State</span>
                    <input
                      type="text"
                      className="formInput"
                      name="mail_state"
                      value={mailState}
                      onChange={handleFormDataMailState}
                    />
                  </li>
                  <li className="entriesField">
                    <span className="fieldHd">Zipcode</span>
                    <input
                      type="text"
                      className="formInput"
                      name="mail_zip"
                      value={mailZipcode}
                      onChange={handleFormDataMailZip}
                    />
                  </li>
                </ul>
              </>
            )}

            <div className="dbSubHd">Event Details</div>
            <ul className="flexBox createEventForm smallForm">
              <li className="full">
                <RichTextEditorComponent
                  height={300}
                  width={800}
                  toolbarSettings={toolbarSettings}
                  quickToolbarSettings={quickToolbarSettings}
                  value={editorData}
                  change={saveContent}
                  imageUploadSuccess={imageUploadHandler}
                  insertImageSettings={insertImageSettings}
                >
                  <Inject
                    services={[
                      Toolbar,
                      Image,
                      Link,
                      HtmlEditor,
                      QuickToolbar,
                      Table,
                    ]}
                  />
                </RichTextEditorComponent>

                <div className="exText">
                  Please enter only one event detail per line.
                </div>
              </li>
            </ul>

            <div className="dbSubHd">Hotel Reservation</div>
            <ul className="hotelResList d-flex flex-wrap">
              {hotelDetailForm.map(
                (value, index) =>
                  value.name != "" && (
                    <>
                      <li>
                        <div className="inner">
                          <div className="top">{value.name}</div>
                          <div className="bot d-flex">
                            <a href="#">Edit</a>
                            <a
                              className="remove"
                              onClick={() => removeFormFields(index)}
                            >
                              Remove
                            </a>
                          </div>
                        </div>
                      </li>
                    </>
                  )
              )}
            </ul>

            {hotelDetailForm.map((element, index) => (
              <ul
                className="flexBox createEventForm smallForm"
                id="hotelDetailsDiv"
              >
                <li className="full">
                  <span className="fieldHd">Hotel Name</span>
                  <input
                    type="text"
                    className="formInput"
                    name="name"
                    value={element.name}
                    onChange={(e) => handleChange(index, e)}
                  />
                </li>
                <li className="full">
                  <span className="fieldHd">Enter URL</span>
                  <input
                    type="text"
                    className="formInput"
                    name="url"
                    value={element.url}
                    onChange={(e) => handleChange(index, e)}
                  />
                  {index ? (
                    <div className="addBtn">
                      <button
                        type="button"
                        onClick={() => removeFormFields(index)}
                      >
                        Remove
                      </button>
                    </div>
                  ) : null}
                </li>
              </ul>
            ))}
            <div className="addBtn">
              <button type="button" onClick={() => addFormFields()}>
                Add New
              </button>
            </div>
            <div>
              <button
                type="button"
                className="formBtn"
                onClick={addTemplateForm}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CreateEvent;
