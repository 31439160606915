import Header from "../../Components/Layout/Header/Header";
import Footer from "../../Components/Layout/Footer/Footer";
import "./AddEvent.scss";
import React, { useState, useEffect, useReducer } from "react";
import axios from "axios";
import moment from "moment";
import { NavLink, Link, useNavigate, useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import combinedServices from "../../shared/services/user-service";
import userConstants from "../../shared/constants/user-constants";
import swal from "sweetalert";
import getRemoveData from "../../shared/localStorage/removeData";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import EditableForm from "./editTransponders";
import { Spinner } from "reactstrap";

const AddEvent = (props) => {
  const params = useParams().id;
  let navigate = useNavigate();
  const [userID, setUserId] = useState(props.participateId);

  const [eventId, setEventId] = useState("");
  const [eventDetails, setEventDetails] = useState([]);
  const [eventClassDetails, setEventClassDetails] = useState([]);
  const [registrationCount, setRegistrationCount] = useState(0);
  const [classIds, setClassIds] = useState([]);
  const [cancelClassIds, setCancelClassIds] = useState([]);
  const [total, setTotal] = useState(0);
  const [errorMsg, setErrorMsg] = useState("");
  const [editopen, setEditOpen] = React.useState(false);

  const [baseURL, setBaseUrl] = useState(userConstants.baseURL);
  const [hostClubName, setHostClubName] = useState("");
  const [participateIds, setParticipantIds] = useState(props.participateId);
  const [participantClassData, setParticipantClassData] = useState([]);
  const [eventClassData, setEventClassData] = useState([]);
  const [allClassDetails, setAllClassDetails] = useState([]);
  const [isLoder, setIsLoder] = useState(false);
  const [addLoder, setAddLoder] = useState(false);
  const [transponder, setTransponder] = useState(false);
  const [transponders1, setTransponders1] = useState(false);
  const [array, setArray] = useState([]);
  const [transponders, setTransponders] = useState([]);
  const [selectedSubMemberId, setSelectedSubMemberId] = useState();
  const [classSelected, setClassSelected] = useState(false);
  const [transponderSelected, setTransponderSelected] = useState(false);
  const participantId = useLocation().pathname.split("/")[3];
  const [selectedClasses, setSelectedClasses] = useState([]);
  const [unselectedClasses, setUnselectedClasses] = useState([]);
  const [classesAndIndex, setClassesAndIndex] = useState([]);
  const [transponderAndClasses, setTransponderAndClasses] = useState([]);
  const [transponderData, setTransponderData] = useState({
    event_participant_id: Number(props.participateId)
      ? Number(props.participateId)
      : participantId,
    class_ids: [],
  });

  const [refund, setRefund] = useState(0);
  const [newPayment, setNewPayment] = useState(0);
  const [oldPayment, setOldPayment] = useState(0);
  const [boatCharge, setBoatCharge] = useState(0);
  const [paymentType, setPaymentType] = useState("");

  const [dropdownValues, setDropdownValues] = useState(
    Array(transponders1.length).fill("")
  );
  useEffect(async () => {
    //setParticipantIds(participateId)
    calculateEnterClassPrice();
  }, [props.participateId, participantId]);

  useEffect(() => {}, [selectedClasses, unselectedClasses]);

  const calculateEnterClassPrice = async () => {
    let eventParticipateResponse =
      await combinedServices.getEventParticipateClass(
        props.participateId ? props.participateId : participantId
      );
    if (eventParticipateResponse.status == 401) {
      swal(eventParticipateResponse.msg, "Please Re-login", "warning").then(
        () => {
          getRemoveData("user_info");
          localStorage.clear();
          window.location.href = "/login";
        }
      );
    }

    let eventParticipateData = eventParticipateResponse.data;
    let eventDetails = eventParticipateData.event;
    setEventDetails(eventDetails);
    let eventParticipateDetails = eventParticipateData.participantsData;

    let response = await combinedServices.getTransponders(
      eventParticipateDetails[0].eventParticipant.user_id
    );
    const oldPaymentResult = await combinedServices.getPaymentUser({
      last_payment: true,
      event_id: params,
      user_id: eventParticipateDetails[0].eventParticipant.user_id,
    });
    setOldPayment(oldPaymentResult?.data[0]?.amount);
    setPaymentType(oldPaymentResult?.data[0]?.payment_type);
    setBoatCharge(
      oldPaymentResult?.data[0]?.event_participant.event.boat_charge
    );
    setSelectedSubMemberId(eventParticipateDetails[0].eventParticipant.user_id);
    setTransponders1(response.data.data);
    //setParticipantIds(eventParticipateDetails);
    let eventClassResponse = await combinedServices.getClassByEvent(
      eventDetails.id
    );
    // bug 58

    var selectedParticipantClasses = [];
    var selectedEventClasses = [];

    eventParticipateDetails.map((value) => {
      value.eventParticipantClasses.map((classes) => {
        if (classes.participation_status == "ENTERED") {
          var classData = {
            class_id: classes.class_id,
            id: classes.id,
            class_name: classes.class.name,
            status: classes.participation_status,
            transponder_id: classes.transponder_id,
            transponder_name: classes.transponder_name,
          };
          selectedParticipantClasses.push(classData);
        }
      });
    });
    setParticipantClassData(selectedParticipantClasses);
    // -----------------------------------
    var registeredData = {
      event_id: eventDetails.id,
      user_id: eventParticipateDetails[0].eventParticipant.user_id,
    };
    let registeredUser = await combinedServices.checkIfUserJoined(
      registeredData
    );
    // -----------------------------------
    if (
      selectedParticipantClasses[0]?.transponder_id != 0 &&
      selectedParticipantClasses[0]?.transponder_id != "" &&
      selectedParticipantClasses[0]?.transponder_id != undefined
    ) {
      setTransponder(true);
      // -----------------------
    } else if (registeredUser.status == 200) {
      setTransponder(true);
    }
    // ---------------------

    eventClassResponse.data.eventClasses.map((value) => {
      var classData = {
        class_id: value.class_Class_Name_ID,
        class_name: value.class_Class_Name,
        status: "",
        id: "",
      };

      selectedEventClasses.push(classData);
    });

    //fixing bug 58
    if (eventClassResponse.data.event.transponder_allow == false) {
      setTransponder(false);
    }

    var filteredArray = selectedEventClasses.filter(function (array_el) {
      return (
        selectedParticipantClasses.filter(function (anotherOne_el) {
          return anotherOne_el.class_id == array_el.class_id;
        }).length == 0
      );
    });
    // const  arrry=filteredArray.filter((x)=>x.status!="")

    setEventClassData(filteredArray);

    var totalCount = 0;
    var totalCost = 0;
    if (selectedParticipantClasses.length == 0) {
      setTotal(0);
      setRegistrationCount(0);
    }

    selectedParticipantClasses.map((value) => {
      if (value.status == "ENTERED") {
        totalCount = totalCount + 1;

        const totalCost =
          Number(eventDetails.registration_charge) +
          Number(eventDetails.boat_charge * totalCount);

        setTotal(totalCost.toFixed(2));
        setRegistrationCount(totalCount);
      } else {
        totalCount = totalCount - 1;

        const totalCost =
          Number(eventDetails.registration_charge) +
          Number(eventDetails.boat_charge * totalCount);

        setTotal(totalCost.toFixed(2));
        setRegistrationCount(totalCount);
      }
    });
  };

  const handleRegistionCost = async (e, classDetails, index) => {
    var classArr = [];
    var classIdArr = [];
    var classtr = [];

    if (classDetails.status == "ENTERED") {
      classIdArr.push(classDetails.id);
      var data = {
        ids: classIdArr,
        index,
      };
      let eventClassResponse =
        await combinedServices.cancleEventParticipateClassMany(data);
      if (eventClassResponse.status == 200) {
        //getting all the unselected classes
        var selectedEventClasses = [];

        let eventClassResponse = await combinedServices.getClassByEvent(
          eventDetails.id
        );

        if (eventClassResponse.data.event.transponder_allow == false) {
          setTransponder(false);
        }

        var filteredArray = selectedEventClasses.filter(function (array_el) {
          return (
            selectedParticipantClasses.filter(function (anotherOne_el) {
              return anotherOne_el.class_id == array_el.class_id;
            }).length == 0
          );
        });
        // const  arrry=filteredArray.filter((x)=>x.status!="")

        setEventClassData(filteredArray);
      } else {
        swal("!Opps", eventClassResponse.data, "error");
      }
      calculateEnterClassPrice();
    } else {
      if (e.target.checked == false) {
        setClassSelected(false);
      } else if (e.target.checked) {
        setClassSelected(true);
      }
      setTransponderSelected(false);

      var data2 = {
        class_ids: classDetails.class_id,
        index,
      };
      classtr.push(data2);

      // Using setArray with a callback to log the updated state
      setArray((prevArray) => {
        var newClasstr = [...prevArray, data2];

        return newClasstr;
      });

      classArr.push(classDetails.class_id);

      var data = {
        event_participant_id: Number(props.participateId)
          ? Number(props.participateId)
          : participantId,
        class_ids: classArr,

        //participation_status: "CANCELLED"
      };
      if (transponder == false) {
        if (e.target.checked == true) {
          //when transponder is now allowed and checking the checkbox to enter the class
          let eventClassResponse =
            await combinedServices.addEventParticipateClassMany(data);
        } else if (e.target.checked == false) {
          // getting selected classes from  db

          var selectedParticipantClasses = [];

          //when transponder is now allowed and unchecking the checkbox to unenter the class
          let eventParticipateResponse =
            await combinedServices.getEventParticipateClass(
              props.participateId ? props.participateId : participantId
            );

          let eventParticipateDetails =
            eventParticipateResponse.data.participantsData;

          eventParticipateDetails.map((value) => {
            value.eventParticipantClasses.map((classes) => {
              if (classes.participation_status == "ENTERED") {
                var classData = {
                  class_id: classes.class_id,
                  id: classes.id,
                  class_name: classes.class.name,
                  status: classes.participation_status,
                  transponder_id: classes.transponder_id,
                  transponder_name: classes.transponder_name,
                };
                selectedParticipantClasses.push(classData);
              }
            });
          });
          setParticipantClassData(selectedParticipantClasses);
          const class_name = classDetails.class_name;
          let passingId = "";
          for (let item of selectedParticipantClasses) {
            if (item.class_name == class_name) {
              passingId = item.id;
            }
          }
          classIdArr.push(passingId);
          var data = {
            ids: classIdArr,
            index,
          };
          let eventClassResponse =
            await combinedServices.cancleEventParticipateClassMany(data);

          if (eventClassResponse.status == 200) {
            //getting all the unselected classes
            var selectedEventClasses = [];

            let eventClassResponse = await combinedServices.getClassByEvent(
              eventDetails.id
            );

            if (eventClassResponse.data.event.transponder_allow == false) {
              setTransponder(false);
            }

            var filteredArray = selectedEventClasses.filter(function (
              array_el
            ) {
              return (
                selectedParticipantClasses.filter(function (anotherOne_el) {
                  return anotherOne_el.class_id == array_el.class_id;
                }).length == 0
              );
            });
            // const  arrry=filteredArray.filter((x)=>x.status!="")

            setEventClassData(filteredArray);
          } else {
            swal("!Opps", eventClassResponse.data, "error");
          }
          calculateEnterClassPrice();
        }
      }
    }
    //  calculateEnterClassPrice();
  };

  const calculateDaysBetweenTwoDate = () => {
    var future = moment(eventDetails.registration_close);
    var start = moment(eventDetails.registration_open);
    var d = future.diff(start, "days") + 1; // 9

    return d;
  };
  const handleTransponderChange1 = async (e, i, a, b) => {
    const val = e.target.value;
    var data = JSON.parse(val);
    var classids = [];

    // Access the 'id' and 'transponder_name' fields
    var transponderId = data.id;
    var transponderName = data.transponder_name;
    var classdetails = {
      class_id: b,
      transponder_id: transponderId,
      transponder_name: transponderName,
    };
    classids.push(classdetails);
    var data2 = {
      event_participant_id: Number(props.participateId)
        ? Number(props.participateId)
        : participantId,
      class_ids: classids,
    };
    let eventClassResponse =
      await combinedServices.addEventParticipateClassMany(data2);
    // calculateEnterClassPrice();
  };
  const handleTransponderChange = async (e, i) => {
    const val = e.target.value;
    var data = JSON.parse(val);

    // Access the 'id' and 'transponder_name' fields
    var transponderId = data.id;
    var transponderName = data.transponder_name;

    const newArray = array.map((item) => {
      if (item.index == i) {
        var data = {
          class_id: item.class_ids, // Assuming you want to use class_ids from the original array
          transponder_id: transponderId, // Use an empty string if transponderName is undefined
          transponder_name: transponderName, // Use an empty string if transponderId is undefined
        };
      }
      // calculateEnterClassPrice();
      return data;
      // calculateEnterClassPrice();
    });

    const para = newArray.filter((data) => data !== undefined);
    if (para.length == 0) {
      swal("Select Corresponding Transponder!!");
      setDropdownValues((prevValues) => {
        const newValues = [...prevValues];
        newValues[i] = ""; // Set to an initial value or an empty string
        return newValues;
      });
    } else {
      var data2 = {
        event_participant_id: Number(props.participateId)
          ? Number(props.participateId)
          : participantId,
        class_ids: para,
        // transponder_id:para.transponder_id,
        // transponder_name:para.transponder_name

        //participation_status: "CANCELLED"
      };
    }
    let eventClassResponse =
      await combinedServices.addEventParticipateClassMany(data2);
    setTransponderSelected(false);
    setClassSelected(false);
  };
  const handleUpdateSendEmail = async () => {
    if (classSelected == false && transponderSelected == false) {
      setIsLoder(true);
      let eventClassResponse = await combinedServices.sendEmailForParticipant(
        props.participateId ? props.participateId : participantId
      );
      if (eventClassResponse.status == 200) {
        setIsLoder(false);
        swal({
          text: "Your entry update successfully. Please check all update details on your mail",
          icon: "success",
        }).then(() => {
          setTransponder(false);

          setClassSelected(false);
          window.location.reload();
        });
      } else if (eventClassResponse.status == 403) {
        setIsLoder(false);
        swal({
          text: eventClassResponse.data.msg,
          icon: "success",
        }).then(() => {
          setTransponder(false);

          setClassSelected(false);
          window.location.reload();
        });
      }
    } else if (transponder == false) {
      setIsLoder(true);
      let eventClassResponse = await combinedServices.sendEmailForParticipant(
        props.participateId ? props.participateId : participantId
      );

      if (eventClassResponse.status == 200) {
        setIsLoder(false);
        swal({
          text: "Your entry update successfully. Please check all update details on your mail",
          icon: "success",
        }).then(() => {
          setTransponder(false);

          setClassSelected(false);
          window.location.reload();
        });
      } else if (eventClassResponse.status == 403) {
        setIsLoder(false);
        swal({
          text: eventClassResponse.data.msg,
          icon: "success",
        }).then(() => {
          setTransponder(false);

          setClassSelected(false);
          window.location.reload();
        });
      }
    } else if (classSelected == true && transponderSelected == false) {
      swal("oops", "Select Transponder", "error");
    } else if (classSelected == true && transponderSelected == true) {
      setIsLoder(true);
      let eventClassResponse = await combinedServices.sendEmailForParticipant(
        props.participateId ? props.participateId : participantId
      );
      if (eventClassResponse.status == 200) {
        setIsLoder(false);
        swal({
          text: "Your entry update successfully. Please check all update details on your mail",
          icon: "success",
        }).then(() => {
          setTransponder(false);
          setClassSelected(false);
          window.location.reload();
        });
      } else if (eventClassResponse.status == 403) {
        setIsLoder(false);
        swal({
          text: eventClassResponse.data.msg,
          icon: "success",
        }).then(() => {
          setTransponder(false);

          setClassSelected(false);
          window.location.reload();
        });
      }
    }
  };
  const handleclickopen1 = () => {
    setEditOpen(true);
  };
  const handleClose2 = async () => {
    setEditOpen(false);
    let response = await combinedServices.getTransponders(selectedSubMemberId);
    setTransponders1(response.data.data);
    // calculateEnterClassPrice()
  };

  const handleSelectClasses = (e, index, classDetails) => {
    //logic to show check uncheck

    // const oldSelectedClasses = [...participantClassData]
    // if(e.target.checked){
    // oldSelectedClasses[index].status='ENTERED'
    // setParticipantClassData(oldSelectedClasses)
    // }else{
    // oldSelectedClasses[index].status='NOT ENTERED'
    // setParticipantClassData(oldSelectedClasses)
    // }

    //if block is to enter into the classes
    if (e.target.checked) {
      if (classDetails.status == "NOT ENTERED") {
        let unselectedClassesNew = [...unselectedClasses];
        unselectedClassesNew = unselectedClassesNew.filter(
          (item) => item != classDetails.id
        );
        setUnselectedClasses(unselectedClassesNew);
        const oldSelectedClasses = [...participantClassData];
        oldSelectedClasses[index].status = "ENTERED";
        setParticipantClassData(oldSelectedClasses);
        setNewPayment((prev) => {
          const updatedPayment = Number(prev) + Number(boatCharge);
          return Number(updatedPayment.toFixed(2)); // Ensuring the result is rounded to 2 decimal places
        });
      } else {
        let selectedClassIds = [...selectedClasses, classDetails.class_id];

        setSelectedClasses(selectedClassIds);

        let classIdsAndIndex = [
          ...classesAndIndex,
          { classId: classDetails.class_id, classIndex: index },
        ];

        setClassesAndIndex(classIdsAndIndex);

        setNewPayment((prev) => {
          const updatedPayment = Number(prev) + Number(boatCharge);
          return Number(updatedPayment.toFixed(2)); // Ensuring the result is rounded to 2 decimal places
        });
      }
    } else {
      // else block is to cancell the class
      if (classDetails.status === "ENTERED") {
        //this if block is to cancel the classes in the database in the api

        let unselectedClassIds = [...unselectedClasses, classDetails.id];
        const oldSelectedClasses = [...participantClassData];
        oldSelectedClasses[index].status = "NOT ENTERED";
        setParticipantClassData(oldSelectedClasses);
        setUnselectedClasses(unselectedClassIds);
        setNewPayment((prev) => {
          const updatedPayment = Number(prev) - Number(boatCharge);
          return Number(updatedPayment.toFixed(2)); // Ensuring the result is rounded to 2 decimal places
        });
      } else {
        // this else block is to cancel the classes which we selected locally but are not save to the database

        let selectedClassIds = [...selectedClasses];
        selectedClassIds = selectedClassIds.filter(
          (id) => id !== classDetails.class_id
        );

        setSelectedClasses(selectedClassIds);
        setClassesAndIndex((prev) =>
          prev.filter((item) => item.classIndex !== index)
        );
        setNewPayment((prev) => {
          const updatedPayment = Number(prev) - Number(boatCharge);
          return Number(updatedPayment.toFixed(2)); // Ensuring the result is rounded to 2 decimal places
        });
      }
    }
  };

  const handleSelectTransponderForClass = async (e, index) => {
    const transponderDetails = JSON.parse(e.target.value);
    const selectedClassesWithIndex = [...classesAndIndex];
    const checkIfClassSelected = selectedClassesWithIndex.find(
      (item) => item.classIndex == index
    );
    if (checkIfClassSelected == undefined) {
      swal("Select The Class First!");
    } else {
      let selectedTransponderAndClasses = [...transponderAndClasses];
      let data = {
        class_id: checkIfClassSelected.classId,
        transponder_id: transponderDetails.id,
        transponder_name: transponderDetails.transponder_name,
      };
      let newTransponderData = [...transponderData.class_ids, data];

      setTransponderData({
        event_participant_id: Number(props.participateId)
          ? Number(props.participateId)
          : participantId,
        class_ids: newTransponderData,
      });
    }
  };

  const handleSubmitEntries = async () => {
    // To cancel the already existing entries ("ENTERED")
    //cancellation process of both with transponder and without transponder are same
    // so we will not to write a seperate code for cancellation of with and without transponder
    if (unselectedClasses.length > 0) {
      let data = {
        ids: unselectedClasses,
      };

      let eventClassResponse =
        await combinedServices.cancleEventParticipateClassMany(data);
    }

    //To make the new entries
    //making the new entries in the case of with transponder and without transponder is different
    // because in case of with transponders we will have to sent transponder details as well
    if (selectedClasses.length > 0) {
      if (transponder) {
        let eventClassResponse =
          await combinedServices.addEventParticipateClassMany(transponderData);
      } else {
        let data = {
          class_ids: selectedClasses,
          event_participant_id: Number(props.participateId)
            ? Number(props.participateId)
            : participantId,
        };
        let eventClassResponse =
          await combinedServices.addEventParticipateClassMany(data);
      }
    }

    handleUpdateSendEmail();

    //logic to make the new payment or to refund

    // if(newPayment>0){
    //   if(paymentType=='PAYPAL'){
    //     // to check if the payment type is paypal then we will prompt to the new payment
    //     let eventClassResponse = await axios.post('http://localhost:3002/event-participant/paypal-link-for-update-entry',{
    //       newAmount:newPayment,
    //       eventParticipantId:Number(props.participateId)
    //       ? Number(props.participateId)
    //       : participantId,
    //     })
    //     if(eventClassResponse.status==200){
    //       window.location.href=eventClassResponse.data.payment_link
    //     }
    //   }

    // }else if(newPayment<0){
    //   console.log('refund for the old old classes')
    // }
  };

  return (
    <>
      <div className="addEventMid">
        <div className="addEventTable">
          <table>
            <tr>
              <th>CLASS</th>
              {transponder == true ? <th>Transponder</th> : ""}
              <th>Class Status</th>
              <th></th>
            </tr>

            {participantClassData
              .sort((a, b) => {
                const nameA = a.class_name.toUpperCase();
                const nameB = b.class_name.toUpperCase();
                if (nameA < nameB) {
                  return -1;
                } else if (nameB < nameA) {
                  return 1;
                } else {
                  return 0;
                }
              })
              .map((classes, classIndex) => {
                return (
                  <tr>
                    <td>
                      <input
                        type="checkbox"
                        checked={classes.status == "ENTERED" ? "true" : ""}
                        className="checkInpt"
                        name="checkInpt"
                        onChange={(e) =>
                          handleSelectClasses(e, classIndex, classes)
                        }

                        // onChange={(e) =>
                        // handleRegistionCost(e, classes, classIndex)
                        // }
                      />
                      {classes.class_name}
                    </td>
                    {transponder == true ? (
                      <td>
                        <div className="smllInput" style={{ display: "flex" }}>
                          <div
                            className="listLeft"
                            style={{
                              width: "70%",
                              textAlign: "initial",
                            }}
                          >
                            <ul
                              // className="flexBox eventForm"
                              disabled={selectedSubMemberId === 0}
                            >
                              <li>
                                <select
                                  className="formSelect"
                                  id="sub_member_name"
                                  onChange={(e) =>
                                    handleTransponderChange1(
                                      e,
                                      classIndex,
                                      classes.class_name,
                                      classes.class_id
                                    )
                                  }
                                >
                                  <option value="">Select Transponder</option>
                                  {transponders1.length > 0
                                    ? transponders1.map((value) => {
                                        return (
                                          <option
                                            key={value.id}
                                            value={JSON.stringify(value)}
                                            selected={
                                              value.id == classes.transponder_id
                                            }
                                          >
                                            {value.transponder_name +
                                              " - " +
                                              "(" +
                                              value.id +
                                              ")"}{" "}
                                          </option>
                                        );
                                      })
                                    : ""}
                                </select>
                              </li>
                            </ul>
                          </div>
                          <div
                            style={{
                              width: "30%",
                              paddingRight: "5px",
                              textAlign: "initial",
                            }}
                          >
                            <Button
                              variant="outlined"
                              onClick={handleclickopen1}
                            >
                              +
                            </Button>

                            <Dialog open={editopen} onClose={handleClose2}>
                              <DialogTitle>Transponders</DialogTitle>
                              <DialogContent>
                                <EditableForm data={selectedSubMemberId} />
                              </DialogContent>
                              <DialogActions>
                                <Button onClick={handleClose2}>Save</Button>
                              </DialogActions>
                            </Dialog>
                          </div>
                        </div>
                      </td>
                    ) : (
                      ""
                    )}
                    <td>{classes.status}</td>
                    <td></td>
                  </tr>
                );
              })}

            {eventClassData
              .sort((a, b) => {
                const nameA = a.class_name.toUpperCase();
                const nameB = b.class_name.toUpperCase();
                if (nameA < nameB) {
                  return -1;
                } else if (nameB < nameA) {
                  return 1;
                } else {
                  return 0;
                }
              })
              .map((classes, classIndex) => {
                return (
                  <tr>
                    <td>
                      <input
                        type="checkbox"
                        // checked={classes.status == "ENTERED"}
                        className="checkInpt"
                        name="checkInpt"
                        onChange={(e) =>
                          handleSelectClasses(e, classIndex, classes)
                        }
                        // onChange={(e) =>
                        // handleRegistionCost(e, classes, classIndex)
                        // }
                      />
                      {classes.class_name}
                    </td>
                    {transponder == true ? (
                      <td>
                        {" "}
                        <div className="smllInput" style={{ display: "flex" }}>
                          <div
                            className="listLeft"
                            style={{
                              width: "70%",
                              textAlign: "initial",
                            }}
                          >
                            <ul
                            // className="flexBox eventForm"
                            >
                              <li>
                                <select
                                  className="formSelect"
                                  id="sub_member_name"
                                  onChange={(e) =>
                                    handleSelectTransponderForClass(
                                      e,
                                      classIndex
                                    )
                                  }
                                  // onChange={(e) =>
                                  // handleTransponderChange(e, classIndex)
                                  // }
                                >
                                  <option value="">Select Transponder</option>
                                  {transponders1.length > 0
                                    ? transponders1.map((value) => {
                                        return (
                                          <option
                                            key={value.id}
                                            value={JSON.stringify(value)}
                                          >
                                            {value.transponder_name +
                                              " - " +
                                              "(" +
                                              value.id +
                                              ")"}{" "}
                                          </option>
                                        );
                                      })
                                    : ""}
                                </select>
                              </li>
                            </ul>
                          </div>
                          <div
                            style={{
                              width: "30%",
                              paddingRight: "5px",
                              textAlign: "initial",
                            }}
                          >
                            <Button
                              variant="outlined"
                              onClick={handleclickopen1}
                            >
                              +
                            </Button>

                            <Dialog open={editopen} onClose={handleClose2}>
                              <DialogTitle>Transponders</DialogTitle>
                              <DialogContent>
                                <EditableForm data={selectedSubMemberId} />
                              </DialogContent>
                              <DialogActions>
                                <Button onClick={handleClose2}>Save</Button>
                              </DialogActions>
                            </Dialog>
                          </div>
                        </div>
                      </td>
                    ) : (
                      ""
                    )}
                    <td>
                      {classes.status == "" ? "NOT ENTERED" : classes.status}
                    </td>
                    <td></td>
                  </tr>
                );
              })}

            <tr className="paymentMetHd">
              <td>Total</td>
              <td></td>

              <td>{total}</td>
            </tr>
            <tr className="paymentMetHd">
              <td>Payment At Registration</td>
              <td></td>

              <td>{oldPayment}</td>
            </tr>
            <tr className="paymentMetHd">
              <td>
                {newPayment >= 0
                  ? `New Payment To Make`
                  : `Refund To Inititate`}
              </td>
              <td></td>
              <td>{newPayment >= 0 ? newPayment : newPayment}.00</td>
            </tr>
            {/* <tr className="paymentMetHd">
              
              <td>New Payment To Make</td>
              <td></td>
              <td>{newPayment}</td>
              
            </tr> */}
          </table>
        </div>
      </div>
      <div className="addEventBot">
        <div className="paymentType">
          <div className="addEventBot">
            <div className="text-center mt-4 loadMore">
              {isLoder ? (
                <div className="text-center mt-4 loadMore">
                  <Spinner color="primary" />
                </div>
              ) : (
                <button
                  className="continueBtn"
                  style={{ margin: "20px" }}
                  onClick={handleSubmitEntries}
                >
                  Update Entry
                </button>
              )}
              {/* {isLoder ? <div className="text-center mt-4 loadMore">
                    <Spinner color="primary" />
                  </div>: <button
                type="button"
                className="continueBtn"
                onClick={handleUpdateSendEmail}
              >
                Update Entry
              </button>} */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AddEvent;
