import React, { useState, useEffect } from "react";
import "./deak-heat.scss";
import Header from "../../Components/clubDashboard/Header/Header";
import { useParams, useNavigate } from "react-router-dom";
import MaskedInput from "react-text-mask";
import combinedServices from "../../shared/services/user-service";
import { Table, Input } from "reactstrap";
import swal from "sweetalert";
import getRemoveData from "../../shared/localStorage/removeData";
import DownloadScoreExcel from "./DownloadScoreExcel";
import DownloadScorePdf from "./DownloadScorePdf";
import MetaDecorator from "../../utils/metaDecorator";
import moment from "moment";
import { FacebookIcon, FacebookShareButton } from "react-share";

const fastTimeMask = [
  /\d/, // first digit of minutes (m)
  /\d/, // second digit of minutes (m)
  ":", // colon separator
  /\d/, // first digit of seconds (s)
  /\d/, // second digit of seconds (s)
  ".", // second digit of seconds (s)
  /\d/, // second digit of decimal seconds (s)
  /\d/, // second digit of decimal seconds (s)
];
export default function Deakheat() {
  let eventId = useParams().id;
  const [data, setData] = useState([]);
  const [rounds, setRounds] = useState([]);
  const [classes, setClasses] = useState([]);
  const [eventParticipant, setEventParticipant] = useState([]);
  const [eventDetails, setEventDetails] = useState([]);
  const [metaDecoratorData, setMetaDecoratorData] = useState("");
  const [round2Data, setRound2Data] = useState(false);
  const [round3Data, setRound3Data] = useState(false);
  const [round4Data, setRound4Data] = useState(false);
  const [dates, setDates] = useState([]);
  const [eventname, setEventname] = useState("");
  const [fees, setFees] = useState("");
  const [regischarge, setRegischarge] = useState("");
  const [masterarr, setMasterarr] = useState({});
  const [scoredata1, setScoredata1] = useState([]);
  const [scoredata3, setScoredata3] = useState([]);
  const [arrayScr, setArrayScr] = useState([]);
  const [fields, setFields] = useState(0);
  const [ind, setInd] = useState(0);
  const [roud, setRoud] = useState("");
  const [totalRounds, setTotalRounds] = useState(4);
  const [hideFastestTime, setHideFastestTime] = useState("none");
  const [timeFlag, setTimeFlag] = useState(0);
  const [fbIcon, setFbIcon] = useState("true");
  const navigate = useNavigate();

  const options = [
    { value: 400, label: "1st Place-400 points" },
    { value: 300, label: "2nd Place-300 points" },
    { value: 225, label: "3rd Place-225 points" },
    { value: 169, label: "4th Place-169 points" },
    { value: 127, label: "5th Place-127 points" },
    { value: 96, label: "6th Place-96 points" },
    { value: 72, label: "7th Place-72 points" },
    { value: 54, label: "8th Place-54 points" },
    { value: 25, label: "Did Not Finish(DNF)-25 points" },
    { value: 0, label: "Did Not Start(DNS)-0 points" },
    { value: -1, label: "Disqualified(DQ)-0 points" },
    // { value: -2, label: "SCR-0 points" },
  ];

  useEffect(async () => {
    let res = await combinedServices.getClassByEventId(eventId);
    setMetaDecoratorData(res.data);
  }, []);

  useEffect(async () => {
    let finalArr = [];
    let classArr = [];
    let dateArr = [];
    await combinedServices
      .getClassByEventId(eventId)
      .then((res) => {
        if (res.status == 401) {
          swal(res.msg, "Please Re-login", "warning").then(() => {
            getRemoveData("user_info");
            localStorage.clear();
            window.location.href = "/login";
          });
        }
        setEventDetails(res.data);

        //to set total number of rounds

        setTotalRounds(res.data.rounds_per_class);

        setRegischarge(res.data.registration_charge);
        setFees(res.data.boat_charge);
        setEventname(res.data.full_title);

        let round = [];
        for (var i = 1; i <= res.data.rounds_per_class; i++) {
          round.push(i);
        }
        setRounds(round);
      })
      .catch((err) => {
        alert(err);
      });

    await combinedServices
      .getEventParticipantForHeat({ event_id: eventId })
      .then((res) => {
        if (res.status == 401) {
          swal(res.msg, "Please Re-login", "warning").then(() => {
            getRemoveData("user_info");
            localStorage.clear();
            window.location.href = "/login";
          });
        }
        setEventParticipant(res.data);
      })
      .catch((err) => {
        alert(err);
      });
    //getting class only
    var eventClassesData1 = await combinedServices.countByClasses(eventId);
    var participantClassData = eventClassesData1.data.participants;
    if (participantClassData) {
      participantClassData.map((classVal) => {
        if (classVal.run_date != null) {
          var data = {
            id: classVal.class_id,
            name: classVal.class_name,
            rundate: classVal.run_date,
            event_class_id: classVal.event_class_id,
            order_key: classVal.order_key,
          };
          classArr.push(data);
          classArr.sort((a, b) => {
            const dateA = new Date(a.run_date);
            const dateB = new Date(b.run_date);

            if (dateA.getTime() === dateB.getTime()) {
              return a.order_key - b.order_key;
            } else {
              return dateA - dateB;
            }
          });
        }
        if (classVal.run_date != null) {
          var data1 = {
            run_date: classVal.run_date,
            class: classVal.class_name,
          };
          dateArr.push(data1);
        }
      });
      classArr.sort((a, b) => {
        if (a.rundate === b.rundate) {
          return a.order_key - b.order_key;
        }
        return new Date(a.rundate) - new Date(b.rundate);
      });
      setClasses(classArr);
      const uniqueArr = [];
      dateArr.forEach((obj) => {
        if (!uniqueArr.some((item) => item.run_date === obj.run_date)) {
          uniqueArr.push(obj);
        }
      });
      setDates(uniqueArr);
    }
    await combinedServices
      .getAllHeatSheet({ event_id: eventId })
      .then(async (res) => {
        if (res.status == 401) {
          swal(res.msg, "Please Re-login", "warning").then(() => {
            getRemoveData("user_info");
            localStorage.clear();
            window.location.href = "/login";
          });
        }
        res.data.map((val, i) => {
          return val.participants.map((itm, i) => {
            return finalArr.push({
              class_id: itm.event_participant_class.class.id,
              class: itm.event_participant_class.class.name,
              heat: val.heat_number,
              name:
                itm.event_participant_class.event_participant.user
                  .user_firstname +
                " " +
                itm.event_participant_class.event_participant.user
                  .user_lastname,
              score: "",
              round: itm.round,
              id: itm.event_participant_class.event_participant.user.id,
              scratch: itm.scratch,
            });
          });
        });
        let uniqueChars = [...new Set(finalArr)];

        finalArr?.map((val) => {
          if (val.round == 2) return setRound2Data(true);
          if (val.round == 3) return setRound3Data(true);
          if (val.round == 4) return setRound4Data(true);
        });
        setData(finalArr);
      })

      .catch((err) => {
        alert(err);
      });
  }, []);
  useEffect(async () => {
    const result2 = {};

    data.forEach((item) => {
      const {
        class: className,
        heat,
        name,
        round,
        id,
        class_id,
        isInvalid,
        scratch,
      } = item;

      if (!result2[className]) {
        result2[className] = [];
      }

      const existingEntry = result2[className].find(
        (entry) => entry.username === name
      );

      if (existingEntry) {
        existingEntry[`Round${round}`] = { heat: heat, scratch: scratch };
      } else {
        const newEntry = {
          username: name,
          [`Round${round}`]: { heat: heat, scratch: scratch },
          id: id,
          class_id: class_id,
          isInvalid: false,
          scratch: scratch,
        };
        result2[className].push(newEntry);
      }
    });

    setMasterarr(result2);

    const classNames = classes.map((cls) => cls.name);

    const filteredarray = Object.keys(result2)
      .filter((className) => classNames.includes(className))
      .reduce((obj, className) => {
        obj[className] = result2[className];
        return obj;
      }, {});

    const transformedData = Object.values(filteredarray).flatMap(
      (participants) =>
        participants.map((participant, i) => ({
          class_Id: participant.class_id,
          participant_Id: participant.id,
          total: 0, // Placeholder, you'll need to calculate the total score
          round1_Score: 0,
          round2_Score: 0,
          round3_Score: 0,
          round4_Score: 0,
          round5_Score: 0,
          round6_Score: 0,
          round7_Score: 0,
          isInvalid: false,
          key: i + 1,
        }))
    );
    const transformedData1 = transformedData.map((obj, index) => ({
      ...obj,
      key: index,
    }));

    setScoredata3(transformedData1);

    let scoreres = await combinedServices.getScoreByEventID({
      event_id: eventId,
    });

    if (scoreres.status == "200") {
      setArrayScr(scoreres.data);
    }

    if (Object.keys(scoreres.data).length !== 0) {
      for (var key in scoreres.data) {
        const participant_Id = scoreres.data[key].participant_Id;
        const class_Id = scoreres.data[key].class_Id;

        for (var transformedKey in transformedData) {
          if (
            transformedData[transformedKey].participant_Id === participant_Id &&
            transformedData[transformedKey].class_Id === class_Id
          ) {
            transformedData[transformedKey] = scoreres.data[key];

            for (let item in transformedData[transformedKey]) {
              if (
                item.startsWith("round") &&
                transformedData[transformedKey][String(item)] == 0
              ) {
                if (transformedData[transformedKey].score_with_reason == null) {
                  transformedData[transformedKey][String(item)] = "";
                } else {
                  const findElement = transformedData[
                    transformedKey
                  ].score_with_reason.find((obj) => obj.round == item);
                  if (findElement == -1) {
                    transformedData[transformedKey][String(item)] = "";
                  }
                }
              }
            }
            break; // Exit the loop once the match is found
          }
        }
      }
      const transformedData1 = transformedData.map((obj, index) => ({
        ...obj,
        key: index,
      }));

      setScoredata1(transformedData1);
    } else {
      setScoredata1(transformedData1);
    }
  }, [data]);
  const handleInput = async (index, value, e, roundScore, x, y, key) => {
    if (value == 0) {
      e.target.value = null;
      const inputValue = "";
      value = "";
    }
  };

  const handleScoreChange1 = async (
    index,
    value,
    e,
    roundScore,
    x, //class id
    y, //user id
    key,
    i
  ) => {
    setFields(e.target.value);
    if (e.target.value == -1 || e.target.value == -2) {
      setFields(0);
    }
    setInd(key);
    setRoud(roundScore);

    let reason = "Normal";
    let enteredValue = value.trim() !== "" ? parseFloat(value) : null; // Allow empty value or parse as float

    const updatedScores1 = [...scoredata1];

    const foundIndex = updatedScores1.findIndex(
      (item) => item.class_Id === x && item.participant_Id === y
    );

    if (foundIndex !== -1) {
      if (enteredValue == 0) {
        reason = "DNS";
      } else if (enteredValue == -1) {
        enteredValue = 0;
        reason = "DQ";
      } else if (enteredValue == -2) {
        enteredValue = 0;
        reason = "SCR";
      }

      updatedScores1[foundIndex][roundScore] = enteredValue;
      updatedScores1[foundIndex].isInvalid = false;

      let totalScore = 0;
      for (const scoreKey in updatedScores1[foundIndex]) {
        if (
          scoreKey.startsWith("round") &&
          !isNaN(updatedScores1[foundIndex][scoreKey])
        ) {
          totalScore += updatedScores1[foundIndex][scoreKey];
        }
      }
      updatedScores1[foundIndex].total = totalScore;
      setScoredata1(updatedScores1);

      var data2 = {
        event_Id: eventId,
        class_Id: x,
        participant_Id: y,
        reason: reason,
      };

      data2[roundScore] = enteredValue;
      let data = [];
      data.push(data2);

      let responseHomeContent = await combinedServices.updateScore(data);
    }
  };

  const handleKeyPress = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    const isValidInput = /^-?\d*\.?\d*$/.test(keyValue); // Updated regex to allow negative numbers and decimal points

    if (!isValidInput) {
      event.preventDefault();
    }
  };

  const fastestTimeStyle = {
    borderRadius: "0.25rem",
    width: "35px",
    marginLeft: "3px",
    border: "1.5px solid lightgray",
    display: hideFastestTime,
    textAlign: "center",
    fontSize: "12px",
  };
  const handleDisplayFastestTime = (e) => {
    if (e.target.checked) {
      setHideFastestTime("block");
    } else {
      setHideFastestTime("none");
    }
  };

  const handleFastTime = async (e, userId, classId, round, key) => {
    const updatedTimes = [...scoredata1];
    setInd(key);
    const foundIndex = updatedTimes.findIndex(
      (item) => item.class_Id === classId && item.participant_Id === userId
    );
    if (updatedTimes[foundIndex].fast_time == null) {
      updatedTimes[foundIndex].fast_time = [
        { round: round, time: e.target.value },
      ];
    } else {
      for (let item of updatedTimes[foundIndex]?.fast_time) {
        if (item.round == round) {
          item.time = e.target.value;
        }
      }
    }

    setScoredata1(updatedTimes);
    let data = {
      round: round,
      time: e.target.value,
      participant_Id: userId,
      class_Id: classId,
      event_Id: eventId,
    };
    // _ _ : _ _ . _ _
    let inputValue = e.target.value;
    if (!inputValue.includes("_")) {
      let response = await combinedServices.enterFastTime(data);
    }
  };

  const handleFacebookShare = () => {
    setFbIcon("true");
    navigate(`../download-pdf-score/${eventId}?facebook=${fbIcon}`);
  };

  const timeaccotolocation2 = (x, timeZone) => {
    if (x == undefined) {
      return;
    }
    if (timeZone == "") {
      timeZone = "Canada/Eastern";
    }
    const utcMoment = moment.utc(x);
    const localMoment = utcMoment.tz(timeZone);
    const timeZoneOffset = localMoment.format("Z");
    return localMoment.format("ddd MMM DD YYYY HH:mm:ss");
  };

  return (
    <>
      <Header />
      {metaDecoratorData !== "" && (
        <div className="dbRight">
          <div className="dbMid dbInner">
            <div className="flexBox spacebetween topHdBox">
              <div className="score">
                <div
                  className="bradcrumb"
                  style={{ backgroundColor: "#1c60a6" }}
                >
                  <a href="/">Home</a>
                  <a href="/event-management">Event Management</a>
                </div>
                <h3>{eventname}</h3>
                <div className="dbMainHd">Score</div>
                <div
                  className="fastest-time-checkbox"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "20px",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <label for="fastTime">Enable Fast Time</label>
                    <input
                      type="checkbox"
                      id="fastTime"
                      onClick={(e) => handleDisplayFastestTime(e)}
                    ></input>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "20px",
                      alignItems: "center",
                    }}
                  >
                    <MetaDecorator
                      title={metaDecoratorData?.full_title}
                      description={moment(
                        timeaccotolocation2(
                          metaDecoratorData?.event_start,
                          metaDecoratorData?.time_zone
                        )
                      ).format("YYYY-MM-DD")}
                      description1={moment(
                        timeaccotolocation2(
                          metaDecoratorData?.event_end,
                          metaDecoratorData?.time_zone
                        )
                      ).format("YYYY-MM-DD")}
                      description3={moment(
                        timeaccotolocation2(
                          metaDecoratorData?.registration_open,
                          metaDecoratorData?.time_zone
                        )
                      ).format("YYYY-MM-DD")}
                      description4={metaDecoratorData?.venue.clbsite_site_city}
                      description5={metaDecoratorData?.venue.clbsite_site_state}
                      description6={metaDecoratorData?.venue.clbsite_site_name}
                      keywords={""}
                      image={metaDecoratorData?.image}
                    />
                    <FacebookShareButton
                          url={"https://race.namba.com/club-result/" + eventId}
                          quote={"racenamba"}
                          hashtag="#namba"
                          description={
                            "Event Start" +
                            " | " +
                            timeaccotolocation2(
                              metaDecoratorData?.event_event_start,
                              metaDecoratorData?.time_zone
                            ) +
                            " | " +
                            "Registration Start" +
                            " | " +
                            timeaccotolocation2(
                              metaDecoratorData?.event_registration_open,
                              metaDecoratorData?.time_zone
                            ) +
                            " | " +
                            "Venue" +
                            " | " +
                            metaDecoratorData?.event_venue +
                            " | " +
                            metaDecoratorData?.event_state +
                            " | " +
                            metaDecoratorData?.event_zip
                          }
                          title={metaDecoratorData?.full_title}
                          image={metaDecoratorData?.image}
                          className="m-2"
                        >
                          <FacebookIcon size={32} round={true} />
                        </FacebookShareButton>

                    <DownloadScoreExcel />
                    <DownloadScorePdf />
                  </div>
                </div>

                <div>
                  {classes.map((cls, i) => {
                    return (
                      <>
                        <Table className="tablescore">
                          <thead>
                            <tr>
                              <th>{cls.name}</th>
                              <th className="tac">Round 1</th>
                              <th className="tac">Round 2</th>
                              <th className="tac">Round 3</th>
                              {totalRounds >= 4 ? (
                                <th className="tac">Round 4</th>
                              ) : (
                                ""
                              )}
                              {totalRounds >= 5 ? (
                                <th className="tac">Round 5</th>
                              ) : (
                                ""
                              )}
                              {totalRounds >= 6 ? (
                                <th className="tac">Round 6</th>
                              ) : (
                                ""
                              )}
                              {totalRounds >= 7 ? (
                                <th className="tac">Round 7</th>
                              ) : (
                                ""
                              )}

                              <th className="tac">Total</th>
                            </tr>
                          </thead>

                          <tbody>
                            {masterarr[cls.name]?.map((user, i) => {
                              return (
                                <tr key={i}>
                                  <td>{user.username}</td>
                                  {scoredata1
                                    ?.filter(
                                      (x) =>
                                        x.class_Id === user.class_id &&
                                        x.participant_Id === user.id
                                    )
                                    .map((x, index) => {
                                      const isScratch =
                                        masterarr[cls.name][i].scratch ===
                                        "true";
                                      return (
                                        <>
                                          {/* round 1 */}
                                          <td>
                                            <div
                                              className="score-inputs-wrapper"
                                            >
                                              <div className="select-wrapper scoreinputbox">
                                                <Input
                                                  type="text"
                                                  // value={x.round2_Score}
                                                  value={
                                                    fields == 0
                                                      ? x.round1_Score
                                                      : ind == x.key &&
                                                        roud === "round1_Score"
                                                      ? fields
                                                      : x.round1_Score
                                                  }
                                                  disabled={user.Round1.scratch}
                                                  onChange={(e) =>
                                                    handleScoreChange1(
                                                      i,
                                                      e.target.value,
                                                      e,
                                                      "round1_Score",
                                                      user.class_id,
                                                      user.id,
                                                      x.key
                                                    )
                                                  }
                                                  onFocus={(e) =>
                                                    handleInput(
                                                      index,
                                                      e.target.value,
                                                      e,
                                                      "round1_Score",
                                                      user.class_id,
                                                      user.id,
                                                      x.key
                                                    )
                                                  }
                                                  onKeyPress={handleKeyPress}
                                                />

                                                <select
                                                  tabIndex="-1"
                                                  value="" // Set value attribute to an empty string
                                                  onChange={(e) =>
                                                    handleScoreChange1(
                                                      index,
                                                      e.target.value,
                                                      e,
                                                      "round1_Score",
                                                      user.class_id,
                                                      user.id,
                                                      x.key
                                                    )
                                                  }
                                                  disabled={user.Round1.scratch}
                                                >
                                                  <option value=""></option>
                                                  {options.map((option) => (
                                                    <option
                                                      key={option.value}
                                                      value={option.value}
                                                    >
                                                      {option.label}
                                                    </option>
                                                  ))}
                                                </select>

                                                {x.isInvalid == true &&
                                                  x.round1_Score != fields &&
                                                  //  x.round1_Score == null &&
                                                  x.key == ind &&
                                                  roud == "round1_Score" && (
                                                    <div className="error-message">
                                                      Invalid input
                                                    </div>
                                                  )}
                                                {/* <div className="caret">&#9650;</div>{" "} */}
                                                {/* Upward caret symbol */}
                                              </div>
                                              <div className="fast-time-input-container">
                                                <MaskedInput
                                                  className="fast-time-input"
                                                  type="text"
                                                  style={fastestTimeStyle}
                                                  mask={fastTimeMask}
                                                  onChange={(e) =>
                                                    handleFastTime(
                                                      e,
                                                      user.id,
                                                      user.class_id,
                                                      "1"
                                                    )
                                                  }
                                                  value={
                                                    x.fast_time &&
                                                    x.fast_time?.find(
                                                      (item) =>
                                                        item.round == "1"
                                                    )
                                                      ? x.fast_time?.find(
                                                          (item) =>
                                                            item.round == "1"
                                                        )?.time
                                                      : ind == x.key
                                                      ? timeFlag
                                                      : x.fast_time?.find(
                                                          (item) =>
                                                            item.round == "1"
                                                        )?.time
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </td>

                                          {/* round 2 */}
                                          <td>
                                            <div
                                               className="score-inputs-wrapper"
                                            >
                                              <div className="select-wrapper scoreinputbox">
                                                <Input
                                                  type="text"
                                                  // value={x.round2_Score}
                                                  value={
                                                    fields == 0
                                                      ? x.round2_Score
                                                      : ind == x.key &&
                                                        roud === "round2_Score"
                                                      ? fields
                                                      : x.round2_Score
                                                  }
                                                  disabled={user.Round2.scratch}
                                                  onChange={(e) =>
                                                    handleScoreChange1(
                                                      i,
                                                      e.target.value,
                                                      e,
                                                      "round2_Score",
                                                      user.class_id,
                                                      user.id,
                                                      x.key
                                                    )
                                                  }
                                                  onFocus={(e) =>
                                                    handleInput(
                                                      index,
                                                      e.target.value,
                                                      e,
                                                      "round2_Score",
                                                      user.class_id,
                                                      user.id,
                                                      x.key
                                                    )
                                                  }
                                                  onKeyPress={handleKeyPress}
                                                />

                                                <select
                                                  tabIndex="-1"
                                                  value="" // Set value attribute to an empty string
                                                  onChange={(e) =>
                                                    handleScoreChange1(
                                                      index,
                                                      e.target.value,
                                                      e,
                                                      "round2_Score",
                                                      user.class_id,
                                                      user.id,
                                                      x.key
                                                    )
                                                  }
                                                  disabled={user.Round2.scratch}
                                                >
                                                  <option value=""></option>
                                                  {options.map((option) => (
                                                    <option
                                                      key={option.value}
                                                      value={option.value}
                                                    >
                                                      {option.label}
                                                    </option>
                                                  ))}
                                                </select>

                                                {x.isInvalid &&
                                                  x.round2_Score != fields &&
                                                  //  x.round2_Score == null &&
                                                  x.key == ind &&
                                                  roud == "round2_Score" && (
                                                    <div className="error-message">
                                                      Invalid input
                                                    </div>
                                                  )}
                                                {/* <div className="caret">&#9650;</div>{" "} */}
                                                {/* Upward caret symbol */}
                                              </div>
                                              <div className="fast-time-input-container">
                                                <MaskedInput
                                                  className="fast-time-input"
                                                  mask={fastTimeMask}
                                                  type="text"
                                                  style={fastestTimeStyle}
                                                  onChange={(e) =>
                                                    handleFastTime(
                                                      e,
                                                      user.id,
                                                      user.class_id,
                                                      "2"
                                                    )
                                                  }
                                                  value={
                                                    x.fast_time &&
                                                    x.fast_time?.find(
                                                      (item) =>
                                                        item.round == "2"
                                                    )
                                                      ? x.fast_time?.find(
                                                          (item) =>
                                                            item.round == "2"
                                                        )?.time
                                                      : ind == x.key
                                                      ? timeFlag
                                                      : x.fast_time?.find(
                                                          (item) =>
                                                            item.round == "2"
                                                        )?.time
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </td>

                                          {/* round 3 */}
                                          <td>
                                            <div
                                               className="score-inputs-wrapper"
                                            >
                                              <div className="select-wrapper scoreinputbox">
                                                <Input
                                                  type="text"
                                                  // value={x.round2_Score}
                                                  value={
                                                    fields == 0
                                                      ? x.round3_Score
                                                      : ind == x.key &&
                                                        roud === "round3_Score"
                                                      ? fields
                                                      : x.round3_Score
                                                  }
                                                  disabled={user.Round3.scratch}
                                                  onChange={(e) =>
                                                    handleScoreChange1(
                                                      i,
                                                      e.target.value,
                                                      e,
                                                      "round3_Score",
                                                      user.class_id,
                                                      user.id,
                                                      x.key
                                                    )
                                                  }
                                                  onFocus={(e) =>
                                                    handleInput(
                                                      index,
                                                      e.target.value,
                                                      e,
                                                      "round3_Score",
                                                      user.class_id,
                                                      user.id,
                                                      x.key
                                                    )
                                                  }
                                                  onKeyPress={handleKeyPress}
                                                />

                                                <select
                                                  tabIndex="-1"
                                                  value="" // Set value attribute to an empty string
                                                  onChange={(e) =>
                                                    handleScoreChange1(
                                                      index,
                                                      e.target.value,
                                                      e,
                                                      "round3_Score",
                                                      user.class_id,
                                                      user.id,
                                                      x.key
                                                    )
                                                  }
                                                  disabled={user.Round3.scratch}
                                                >
                                                  <option value=""></option>
                                                  {options.map((option) => (
                                                    <option
                                                      key={option.value}
                                                      value={option.value}
                                                    >
                                                      {option.label}
                                                    </option>
                                                  ))}
                                                </select>

                                                {x.isInvalid &&
                                                  x.round3_Score != fields &&
                                                  // x.round3_Score == null &&
                                                  x.key == ind &&
                                                  roud == "round3_Score" &&
                                                  x.key == ind && (
                                                    <div className="error-message">
                                                      Invalid input
                                                    </div>
                                                  )}
                                                {/* <div className="caret">&#9650;</div>{" "} */}
                                                {/* Upward caret symbol */}
                                              </div>
                                              <div className="fast-time-input-container">
                                                <MaskedInput
                                                  className="fast-time-input"
                                                  mask={fastTimeMask}
                                                  type="text"
                                                  style={fastestTimeStyle}
                                                  onChange={(e) =>
                                                    handleFastTime(
                                                      e,
                                                      user.id,
                                                      user.class_id,
                                                      "3"
                                                    )
                                                  }
                                                  value={
                                                    x.fast_time &&
                                                    x.fast_time?.find(
                                                      (item) =>
                                                        item.round == "3"
                                                    )
                                                      ? x.fast_time?.find(
                                                          (item) =>
                                                            item.round == "3"
                                                        )?.time
                                                      : ind == x.key
                                                      ? timeFlag
                                                      : x.fast_time?.find(
                                                          (item) =>
                                                            item.round == "3"
                                                        )?.time
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </td>

                                          {/* round 4 */}
                                          {totalRounds >= 4 ? (
                                            <td>
                                              <div
                                               className="score-inputs-wrapper"
                                              >
                                                <div className="select-wrapper scoreinputbox">
                                                  <Input
                                                    type="text"
                                                    // value={x.round2_Score}
                                                    value={
                                                      fields == 0
                                                        ? x.round4_Score
                                                        : ind == x.key &&
                                                          roud ===
                                                            "round4_Score"
                                                        ? fields
                                                        : x.round4_Score
                                                    }
                                                    disabled={
                                                      user.Round4.scratch
                                                    }
                                                    onChange={(e) =>
                                                      handleScoreChange1(
                                                        i,
                                                        e.target.value,
                                                        e,
                                                        "round4_Score",
                                                        user.class_id,
                                                        user.id,
                                                        x.key
                                                      )
                                                    }
                                                    onFocus={(e) =>
                                                      handleInput(
                                                        index,
                                                        e.target.value,
                                                        e,
                                                        "round4_Score",
                                                        user.class_id,
                                                        user.id,
                                                        x.key
                                                      )
                                                    }
                                                    onKeyPress={handleKeyPress}
                                                  />

                                                  <select
                                                    tabIndex="-1"
                                                    value="" // Set value attribute to an empty string
                                                    onChange={(e) =>
                                                      handleScoreChange1(
                                                        index,
                                                        e.target.value,
                                                        e,
                                                        "round4_Score",
                                                        user.class_id,
                                                        user.id,
                                                        x.key
                                                      )
                                                    }
                                                    disabled={
                                                      user.Round4.scratch
                                                    }
                                                  >
                                                    <option value=""></option>
                                                    {options.map((option) => (
                                                      <option
                                                        key={option.value}
                                                        value={option.value}
                                                      >
                                                        {option.label}
                                                      </option>
                                                    ))}
                                                  </select>

                                                  {x.isInvalid &&
                                                    x.round4_Score != fields &&
                                                    // x.round4_Score == null &&
                                                    x.key == ind &&
                                                    roud == "round4_Score" &&
                                                    x.key == ind && (
                                                      <div className="error-message">
                                                        Invalid input
                                                      </div>
                                                    )}
                                                  {/* <div className="caret">&#9650;</div>{" "} */}
                                                  {/* Upward caret symbol */}
                                                </div>
                                                <div className="fast-time-input-container">
                                                  <MaskedInput
                                                    className="fast-time-input"
                                                    mask={fastTimeMask}
                                                    type="text"
                                                    style={fastestTimeStyle}
                                                    onChange={(e) =>
                                                      handleFastTime(
                                                        e,
                                                        user.id,
                                                        user.class_id,
                                                        "4"
                                                      )
                                                    }
                                                    value={
                                                      x.fast_time &&
                                                      x.fast_time?.find(
                                                        (item) =>
                                                          item.round == "4"
                                                      )
                                                        ? x.fast_time?.find(
                                                            (item) =>
                                                              item.round == "4"
                                                          )?.time
                                                        : ind == x.key
                                                        ? timeFlag
                                                        : x.fast_time?.find(
                                                            (item) =>
                                                              item.round == "4"
                                                          )?.time
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            </td>
                                          ) : (
                                            ""
                                          )}

                                          {/* round 5 */}

                                          {totalRounds >= 5 ? (
                                            <td>
                                              <div
                                               className="score-inputs-wrapper"
                                              >
                                                <div className="select-wrapper scoreinputbox">
                                                  <Input
                                                    type="text"
                                                    // value={x.round2_Score}
                                                    value={
                                                      fields == 0
                                                        ? x.round5_Score
                                                        : ind == x.key &&
                                                          roud ===
                                                            "round5_Score"
                                                        ? fields
                                                        : x.round5_Score
                                                    }
                                                    disabled={
                                                      user.Round5.scratch
                                                    }
                                                    onChange={(e) =>
                                                      handleScoreChange1(
                                                        i,
                                                        e.target.value,
                                                        e,
                                                        "round5_Score",
                                                        user.class_id,
                                                        user.id,
                                                        x.key
                                                      )
                                                    }
                                                    onFocus={(e) =>
                                                      handleInput(
                                                        index,
                                                        e.target.value,
                                                        e,
                                                        "round5_Score",
                                                        user.class_id,
                                                        user.id,
                                                        x.key
                                                      )
                                                    }
                                                    onKeyPress={handleKeyPress}
                                                  />

                                                  <select
                                                    tabIndex="-1"
                                                    value="" // Set value attribute to an empty string
                                                    onChange={(e) =>
                                                      handleScoreChange1(
                                                        index,
                                                        e.target.value,
                                                        e,
                                                        "round5_Score",
                                                        user.class_id,
                                                        user.id,
                                                        x.key
                                                      )
                                                    }
                                                    disabled={
                                                      user.Round5.scratch
                                                    }
                                                  >
                                                    <option value=""></option>
                                                    {options.map((option) => (
                                                      <option
                                                        key={option.value}
                                                        value={option.value}
                                                      >
                                                        {option.label}
                                                      </option>
                                                    ))}
                                                  </select>

                                                  {x.isInvalid &&
                                                    x.round5_Score != fields &&
                                                    // x.round5_Score == null &&
                                                    x.key == ind &&
                                                    roud == "round5_Score" &&
                                                    x.key == ind && (
                                                      <div className="error-message">
                                                        Invalid input
                                                      </div>
                                                    )}
                                                  {/* <div className="caret">&#9650;</div>{" "} */}
                                                  {/* Upward caret symbol */}
                                                </div>
                                                <div className="fast-time-input-container">
                                                  <MaskedInput
                                                    className="fast-time-input"
                                                    type="text"
                                                    style={fastestTimeStyle}
                                                    mask={fastTimeMask}
                                                    onChange={(e) =>
                                                      handleFastTime(
                                                        e,
                                                        user.id,
                                                        user.class_id,
                                                        "5"
                                                      )
                                                    }
                                                    value={
                                                      x.fast_time &&
                                                      x.fast_time?.find(
                                                        (item) =>
                                                          item.round == "5"
                                                      )
                                                        ? x.fast_time?.find(
                                                            (item) =>
                                                              item.round == "5"
                                                          )?.time
                                                        : ind == x.key
                                                        ? timeFlag
                                                        : x.fast_time?.find(
                                                            (item) =>
                                                              item.round == "5"
                                                          )?.time
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            </td>
                                          ) : (
                                            ""
                                          )}

                                          {/* round 6 */}

                                          {totalRounds >= 6 ? (
                                            <td>
                                              <div
                                               className="score-inputs-wrapper"
                                              >
                                                <div className="select-wrapper scoreinputbox">
                                                  <Input
                                                    type="text"
                                                    // value={x.round2_Score}
                                                    value={
                                                      fields == 0
                                                        ? x.round6_Score
                                                        : ind == x.key &&
                                                          roud ===
                                                            "round6_Score"
                                                        ? fields
                                                        : x.round6_Score
                                                    }
                                                    disabled={
                                                      user.Round6.scratch
                                                    }
                                                    onChange={(e) =>
                                                      handleScoreChange1(
                                                        i,
                                                        e.target.value,
                                                        e,
                                                        "round6_Score",
                                                        user.class_id,
                                                        user.id,
                                                        x.key
                                                      )
                                                    }
                                                    onFocus={(e) =>
                                                      handleInput(
                                                        index,
                                                        e.target.value,
                                                        e,
                                                        "round6_Score",
                                                        user.class_id,
                                                        user.id,
                                                        x.key
                                                      )
                                                    }
                                                    onKeyPress={handleKeyPress}
                                                  />

                                                  <select
                                                    tabIndex="-1"
                                                    value="" // Set value attribute to an empty string
                                                    onChange={(e) =>
                                                      handleScoreChange1(
                                                        index,
                                                        e.target.value,
                                                        e,
                                                        "round6_Score",
                                                        user.class_id,
                                                        user.id,
                                                        x.key
                                                      )
                                                    }
                                                    disabled={
                                                      user.Round6.scratch
                                                    }
                                                  >
                                                    <option value=""></option>
                                                    {options.map((option) => (
                                                      <option
                                                        key={option.value}
                                                        value={option.value}
                                                      >
                                                        {option.label}
                                                      </option>
                                                    ))}
                                                  </select>

                                                  {x.isInvalid &&
                                                    x.round6_Score != fields &&
                                                    // x.round6_Score == null &&
                                                    x.key == ind &&
                                                    roud == "round6_Score" &&
                                                    x.key == ind && (
                                                      <div className="error-message">
                                                        Invalid input
                                                      </div>
                                                    )}
                                                  {/* <div className="caret">&#9650;</div>{" "} */}
                                                  {/* Upward caret symbol */}
                                                </div>
                                                <div className="fast-time-input-container">
                                                  <MaskedInput
                                                    className="fast-time-input"
                                                    type="text"
                                                    mask={fastTimeMask}
                                                    style={fastestTimeStyle}
                                                    onChange={(e) =>
                                                      handleFastTime(
                                                        e,
                                                        user.id,
                                                        user.class_id,
                                                        "6"
                                                      )
                                                    }
                                                    value={
                                                      x.fast_time &&
                                                      x.fast_time?.find(
                                                        (item) =>
                                                          item.round == "6"
                                                      )
                                                        ? x.fast_time?.find(
                                                            (item) =>
                                                              item.round == "6"
                                                          )?.time
                                                        : ind == x.key
                                                        ? timeFlag
                                                        : x.fast_time?.find(
                                                            (item) =>
                                                              item.round == "6"
                                                          )?.time
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            </td>
                                          ) : (
                                            ""
                                          )}

                                          {/* round 7 */}

                                          {totalRounds >= 7 ? (
                                            <td>
                                              <div
                                                 className="score-inputs-wrapper"
                                              >
                                                <div className="select-wrapper scoreinputbox">
                                                  <Input
                                                    type="text"
                                                    // value={x.round2_Score}
                                                    value={
                                                      fields == 0
                                                        ? x.round7_Score
                                                        : ind == x.key &&
                                                          roud ===
                                                            "round7_Score"
                                                        ? fields
                                                        : x.round7_Score
                                                    }
                                                    disabled={
                                                      user.Round7.scratch
                                                    }
                                                    onChange={(e) =>
                                                      handleScoreChange1(
                                                        i,
                                                        e.target.value,
                                                        e,
                                                        "round7_Score",
                                                        user.class_id,
                                                        user.id,
                                                        x.key
                                                      )
                                                    }
                                                    onFocus={(e) =>
                                                      handleInput(
                                                        index,
                                                        e.target.value,
                                                        e,
                                                        "round7_Score",
                                                        user.class_id,
                                                        user.id,
                                                        x.key
                                                      )
                                                    }
                                                    onKeyPress={handleKeyPress}
                                                  />

                                                  <select
                                                    tabIndex="-1"
                                                    value="" // Set value attribute to an empty string
                                                    onChange={(e) =>
                                                      handleScoreChange1(
                                                        index,
                                                        e.target.value,
                                                        e,
                                                        "round7_Score",
                                                        user.class_id,
                                                        user.id,
                                                        x.key
                                                      )
                                                    }
                                                    disabled={
                                                      user.Round7.scratch
                                                    }
                                                  >
                                                    <option value=""></option>
                                                    {options.map((option) => (
                                                      <option
                                                        key={option.value}
                                                        value={option.value}
                                                      >
                                                        {option.label}
                                                      </option>
                                                    ))}
                                                  </select>

                                                  {x.isInvalid &&
                                                    x.round7_Score != fields &&
                                                    // x.round7_Score == null &&
                                                    x.key == ind &&
                                                    roud == "round7_Score" &&
                                                    x.key == ind && (
                                                      <div className="error-message">
                                                        Invalid input
                                                      </div>
                                                    )}
                                                </div>
                                                <div className="fast-time-input-container">
                                                  <MaskedInput
                                                    className="fast-time-input"
                                                    type="text"
                                                    style={fastestTimeStyle}
                                                    mask={fastTimeMask}
                                                    onChange={(e) =>
                                                      handleFastTime(
                                                        e,
                                                        user.id,
                                                        user.class_id,
                                                        "7",
                                                        x.key
                                                      )
                                                    }
                                                    value={
                                                      x.fast_time &&
                                                      x.fast_time?.find(
                                                        (item) =>
                                                          item.round == "7"
                                                      )
                                                        ? x.fast_time?.find(
                                                            (item) =>
                                                              item.round == "7"
                                                          )?.time
                                                        : ind == x.key
                                                        ? timeFlag
                                                        : x.fast_time?.find(
                                                            (item) =>
                                                              item.round == "7"
                                                          )?.time
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            </td>
                                          ) : (
                                            ""
                                          )}

                                          <td style={{ fontWeight: "600" }}>
                                            {x.total}
                                          </td>
                                        </>
                                      );
                                    })}
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </>
                    );
                    // })}
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div></div>
    </>
  );
}
