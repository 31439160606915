import React, { useEffect, useRef, useState } from "react";
import combinedServices from "../../../shared/services/user-service";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useLocation } from "react-router-dom";
import { Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import Swal from "sweetalert2";
function EditHeatsheet() {
  const [rounds, setRounds] = useState([
    { label: "Round 1", value: "1" },
    { label: "Round 2", value: "2" },
    { label: "Round 3", value: "3" },
  ]);
  const [selectedRound, setSelectedRound] = useState({
    label: "Round 1",
    value: "1",
  });
  const [eventTotalRounds, setEventTotalRounds] = useState(3);
  const [heatSheets, setHeatSheets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [ids, setIds] = useState([]);
  const [newHeats, setNewHeats] = useState([]);
  const saveButtonRef = useRef("");
  const eventId = useLocation().pathname.split("/")[2];

  useEffect(async () => {
    const eventDetails = await combinedServices.getEventDetail(eventId);
    const eventRounds = eventDetails.data.event_rounds_per_class;
    setEventTotalRounds(eventRounds);
    const roundLabelsValues = [];
    for (let i = 1; i <= eventRounds; i++) {
      roundLabelsValues.push({ label: `Round ${i}`, value: i });
    }
    setRounds(roundLabelsValues);

    //fetching the heatsheets
    const heatSheetResponse = await combinedServices.getAllHeatSheet({
      event_id: eventId,
    });
    if (heatSheetResponse.data.length > 0) {
      //fetching the classes of events
      const eventClasses = await combinedServices.countByClasses(eventId);

      let heatArray = [];
      for (let cls of eventClasses.data.participants) {
        if (cls.class_status == "MADE") {
          let item = cls;
          item["heats"] = [];
          for (let heat of heatSheetResponse.data) {
            if (heat.classId == item.class_id) {
              item.heats.push(heat);
            }
          }
          heatArray.push(item);
        }
      }
      heatArray.sort((a, b) => {
        let dateA = new Date(a.run_date).getTime();
        let dateB = new Date(b.run_date).getTime();
        if (dateA === dateB) {
          return a.order_key - b.order_key;
        } else {
          return dateA - dateB;
        }
      });
      setHeatSheets(heatArray);
    }
  }, []);

  const handleChange = (e) => {
    setSelectedRound(() => {
      return { label: `Round ${e.target.value}`, value: e.target.value };
    });
  };

  const generateHeatNumbers = async () => {
    setLoading(true);
    try {
      const distinctRunDates = [
        ...new Set(heatSheets.map((item) => String(item.run_date))),
      ];

      const oldNewHeats = [];

      let heatNumber = 1;
      let heatIdsAndNumbers = [];
      const cloneHeatSheets = [
        ...heatSheets.sort((a, b) => {
          let dateA = new Date(a.run_date).getTime();
          let dateB = new Date(b.run_date).getTime();
          if (dateA === dateB) {
            return a.order_key - b.order_key;
          } else {
            return dateA - dateB;
          }
        }),
      ];

      for (let i = 0; i < distinctRunDates.length; i++) {
        let classesOfRunDate = [];
        for (let item of cloneHeatSheets) {
          if (String(item.run_date) == distinctRunDates[i]) {
            classesOfRunDate.push(item);
          }
        }

        for (let eventRound = 1; eventRound <= eventTotalRounds; eventRound++) {
          for (const cls of classesOfRunDate) {
            for (let heat of cls.heats) {
              if (heat.round == eventRound) {
                oldNewHeats.push({
                  oldHeatNumber: heat.heat_number,
                  newHeatNumber: heatNumber,
                  classId: heat.classId,
                  round: heat.round,
                });
                heat.heat_number = heatNumber;
                // let heatIds = heat.participants.map((participant)=>{ return {id:participant.id,event_heat_number:heatNumber}})
                heatNumber += 1;
                //  heatIdsAndNumbers=[...heatIdsAndNumbers,...heatIds]
              }
            }
          }
        }
      }
      setNewHeats(oldNewHeats);
      setHeatSheets(cloneHeatSheets);
      if (saveButtonRef.current) {
        const res = await saveHeats();
        if(res.status==200){
          Swal.fire({
            title: "Done",
            text: "New Heat Numbers Have Been Generated!",
            icon: "success",
          }).then(() => {
            window.location.reload(); // Refresh the page after clicking OK
          });
        }else if(res.status==422){
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Event must be upcoming",
          });
        }
        
      }
      
    } catch (err) {
      console.log("err ", err);
    } finally {
      setLoading(false);
    }
  };

  const saveHeats = async () => {
    setLoading(true);
    try {
      let heatIds = [];

      for (const cls of heatSheets) {
        for (let heat of cls.heats) {
          for (let participant of heat.participants) {
            heatIds.push({
              id: participant.id,
              event_heat_number: heat.heat_number,
            });
          }
        }
      }
      const response = await combinedServices.updateHeatNumbers({
        ids: heatIds,
        eventId: eventId,
        oldNewHeats: newHeats,
      });
      
      return response
    } catch (err) {
      console.log("err :", err);
    } finally {
      setLoading(false);
    }
  };

  const handleHeatNumberChange = async (e, classIndex, heatIndex) => {
    try {
      const newHeatSheets = [...heatSheets];
      newHeatSheets[classIndex].heats[heatIndex].heat_number = e.target.value;
      setHeatSheets(newHeatSheets);
    } catch (err) {
      console.log("err", err);
    }
  };

  return (
    <>
      <button
        onClick={generateHeatNumbers}
        disabled={loading}
        className="renumber-heats"
      >
        Manage Heat No.
      </button>
      <div style={{ padding: "2%", display: "none" }}>
        {loading && (
          <Stack
            spacing={2}
            direction="row"
            alignItems="center"
            sx={{ position: "absolute", top: "50%", left: "50%" }}
          >
            <CircularProgress size="3rem" />
          </Stack>
        )}
        <div className="mhLeft">
          <div className="dbMainHd">Heat Heats</div>
          <div className="bradcrumb" style={{ backgroundColor: "#1c60a6" }}>
            <a href="/">Home</a>
            <a href="/event-management">Event Management</a>
            <a href={`/manage-heatsheet/${eventId}`}>Manage Heats</a>
          </div>
        </div>
        <Box
          sx={{
            my: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <FormControl fullWidth sx={{ maxWidth: 200 }}>
            <InputLabel id="demo-simple-select-label">Round</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedRound.value}
              label="Round"
              onChange={handleChange}
            >
              {rounds.map((round) => (
                <MenuItem value={round.value} key={round.value}>
                  {round.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        {heatSheets.length > 0 && (
          <>
            {heatSheets.map((cls, classIndex) => (
              <Box
                component="form"
                noValidate
                autoComplete="off"
                sx={{ my: 1 }}
                key={classIndex}
              >
                <Typography variant="h6" gutterBottom>
                  {cls.class_name}
                </Typography>
                {cls.heats.map(
                  (heat, heatIndex) =>
                    heat.round == selectedRound.value && (
                      <TextField
                        id="outlined-basic"
                        label="Heat Number"
                        variant="outlined"
                        value={heat.heat_number}
                        sx={{ mx: 1, my: 0.5 }}
                        key={heatIndex}
                        onChange={(e) =>
                          handleHeatNumberChange(e, classIndex, heatIndex)
                        }
                      />
                    )
                )}
              </Box>
            ))}
            <Button
              variant="contained"
              sx={{ minWidth: 120, mx: 1, my: 2 }}
              onClick={saveHeats}
              ref={saveButtonRef}
            >
              Save
            </Button>
          </>
        )}
      </div>
    </>
  );
}

export default EditHeatsheet;
