import React, { useState, useEffect, useRef } from "react";
import Header from "../../Components/Layout/Header/Header";
import Footer from "../../Components/Layout/Footer/Footer";
import combinedServices from "../../shared/services/user-service";
import getLocalData from "../../shared/localStorage/getData";
import Pagination from "react-js-pagination";
import { NavLink } from "react-router-dom";
import { Spinner } from "reactstrap";
import moment from "moment-timezone";
import { useReactToPrint } from "react-to-print";
import "./Club_result.scss";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { findMinFastTime } from "../../utils/MinimumFastTime";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
const initialOpenDialogIndex = -1;
const Club_result = () => {
  const componentRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [classes, setClasses] = useState();
  const [selectedClass, setSelectedClass] = useState();
  const [scoreRacer, setScore] = useState();
  const [userId, setUserId] = useState("");
  const [clubDetails, setClubDetails] = useState([]);
  const [distric, setDistric] = useState([]);
  const [pageRecord, setPageRecord] = useState(10);
  const [page, setPage] = useState(1);
  const [totalRecord, setTotalRecord] = useState("");
  const [leaderLoaderDetails, setLeaderLoaderDetails] = useState([]);
  const [title, setTitle] = useState("");
  const [currentDistric, setCurrentDistric] = useState("");
  const [isLoder, setIsLoder] = useState(true);
  const [scrimg, setScrimg] = useState("");
  const [selectedClub, setSelectedClub] = useState(null);
  const [clubname, setClubname] = useState();
  const [open, setOpen] = React.useState(false);
  const [feedData, setFeedData] = useState(null);
  const [openDialogIndex, setOpenDialogIndex] = useState(
    initialOpenDialogIndex
  );

  const [totalRounds, setTotalRounds] = useState(3);
  const scorenImg =
    "https://nambaweb.s3.ap-south-1.amazonaws.com/frontend-static-image/scorenotfound.png";

  const uniqueClassNames = [];
  useEffect(async () => {
    var data = {
      page: page,
      records_per_page: pageRecord,
    };
    if (getLocalData("user_info", "isLogin")) {
      var user_id = getLocalData("user_info", "all").userData.login_user_detail
        .data.user.id;
      var userDistricId = getLocalData("user_info", "all").userData
        .login_user_detail.data.user.district.uzip_namba_district;
      setCurrentDistric(userDistricId);
      setUserId(user_id);
      data.district_id = userDistricId;
    }

    let clubResponse = await combinedServices.getEventNamesWithClass(data);
    if (clubResponse.status == 200) {
      setIsLoder(false);
      let clubres = clubResponse.data.filter(
        (x) => x.event_status == "completed"
      );
      clubres.map((myResult, index) => {
        if (index == 0) {
          handleClubResult(myResult.id, myResult.full_title);
        }
      });
      setClubDetails(clubres);
      setTotalRecord(clubres.length);
    }

    getAllDistricList();
  }, []);

  const getAllDistricList = async () => {
    let response = await combinedServices.getAllDistric();
    setDistric(response.data);
  };

  const handleSelectClass = async (e) => {
    setSelectedClass(e.target.value);
  };
  const handleDistricFilter = async (e) => {
    var clubDetails = [];
    setTitle("");
    setIsLoder(true);
    setCurrentDistric(e.target.value);
    var data = {
      page: 1,
      records_per_page: pageRecord,
    };
    if (e.target.value) {
      data.district_id = e.target.value;
    }
    let clubResponse = await combinedServices.getEventNamesWithClass(data);
    if (clubResponse.status == 200) {
      let clubres = clubResponse.data.filter(
        (x) => x.event_status == "completed"
      );
      setIsLoder(false);
      setClubDetails(clubres);
      setTotalRecord(clubres.length);
      clubres.map((myResult, index) => {
        if (index == 0) {
          handleClubResult(myResult.id, myResult.full_title);
        }
      });
    }
  };

  const handleClubResult = async (event_id, event_title) => {
    setTitle(event_title);
    setSelectedClub(event_id);

    var data = {
      event_id: event_id,
      //user_id: userId
    };

    let myLeaderBoardResponse = await combinedServices.getLeaderBoardDetails(
      data
    );
    let score = await combinedServices.getScore(event_id);
    if (score.status == 200) {
      setIsLoder(false);
    }
    if (score.length == 0) {
      setScrimg(scorenImg);
    }

    let val = score;
    const sortedData = score.sort((a, b) => {
      if (a.class_Name === b.class_Name) {
        return b.total - a.total;
      }
      return a.class_Name.localeCompare(b.class_Name);
    });

    //to remove 0 and add src/dq/dns

  
    for (let item of sortedData) {

      if (item.score_with_reason != null) {
        for (let item1 in item) {
          if (item1.startsWith("round") && item[String(item1)] == 0) {
            for (let item2 of item.score_with_reason) {
              if (item2.round == item1 && item2.reason !== "Normal") {
                item[String(item1)] = item2.reason;
              }
            }
          }
        }
      }
    }

    setScore(sortedData);

    let event = await combinedServices.getClassByEventId(event_id);
    if (event.status == 200) {
      setIsLoder(false);
    }
    setClasses(event.data.classes);
    setClubname(event.data.host_club.club_name);

    //setting the total number of rounds
    setTotalRounds(event.data.rounds_per_class);

    setLeaderLoaderDetails(myLeaderBoardResponse.data.leaderboard);
  };
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const handlePageChange = async (pageNumber) => {
    var clubDetails = [];
    setPage(pageNumber);
    setIsLoder(true);

    var data = {
      page: pageNumber,
      records_per_page: pageRecord,
    };
    if (currentDistric) {
      data.district_id = currentDistric;
    }

    let clubResponse = await combinedServices.getEventNamesWithClass(data);
    if (clubResponse.status == 200) {
      let clubres = clubResponse.data.filter(
        (x) => x.event_status == "completed"
      );
      setIsLoder(false);
      setClubDetails(clubres);
      setTotalRecord(clubres.length);
      clubres.map((myResult, index) => {
        if (index == 0) {
          handleClubResult(myResult.id, myResult.full_title);
        }
      });
    }
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpenDialog = async (index) => {
    setOpenDialogIndex(index);
    try {
      const response = await combinedServices.getFeed(selectedClub);

      const sortedData = response.sort((a, b) => {
        const dateA = new Date(a.created_at);
        const dateB = new Date(b.created_at);
        return dateB - dateA;
      });

      // const data = await response.json();
      setFeedData(sortedData);
      setIsLoading(false);
      // setPopupVisible(true);
    } catch (error) {
      console.error("Error fetching feed data:", error);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialogIndex(initialOpenDialogIndex);
  };
  function formatDateTime(timestamp) {
    const dateObject = new Date(timestamp);

    const optionsDate = { year: "numeric", month: "long", day: "numeric" };
    const formattedDate = dateObject.toLocaleDateString("en-US", optionsDate);

    const optionsTime = {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };
    const formattedTime = dateObject.toLocaleTimeString("en-US", optionsTime);

    const time = `${formattedDate} ${formattedTime}`;
    return time;
  }
  function processData(data, time) {
    const updatedtime = formatDateTime(time);
    const lines = data?.split("\n").filter((line) => line.trim() !== "");
    const table1 = [];

    for (let i = 0; i < lines.length; i += 3) {
      const category = lines[i];
      const participant = lines[i + 1];
      const scores = lines[i + 2]?.match(/Round \d+: \d+/g);

      if (category && participant && scores) {
        const participantData = {
          Category: category,
          Participant: participant,
        };

        scores.forEach((score) => {
          const [round, points] = score.split(": ");
          participantData[round] = parseInt(points);
        });

        participantData.Total = scores.reduce((total, score) => {
          const [, points] = score.split(": ");
          return total + parseInt(points);
        }, 0);

        table1.push(participantData);
      }
    }

    // Return the JSX here
    return (
      <>
        <table className="table">
          <thead>
            <tr>
              <th>Class</th>
              <th>Participants</th>
              <th>Round 1</th>
              <th>Round 2</th>
              <th>Round 3</th>
              <th>Round 4</th>
              <th>Round 5</th>
              <th>Round 6</th>
              <th>Round 7</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {table1.map((data, index) => {
              return (
                <tr key={index}>
                  <td>{data.Category}</td>
                  <td>{data.Participant}</td>
                  <td>{data["Round 1"] || 0}</td>
                  <td>{data["Round 2"] || 0}</td>
                  <td>{data["Round 3"] || 0}</td>
                  <td>{data["Round 4"] || 0}</td>
                  <td>{data["Round 5"] || 0}</td>
                  <td>{data["Round 6"] || 0}</td>
                  <td>{data["Round 7"] || 0}</td>
                  <td>{data.Total || 0}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="resultHd">{updatedtime}</div>
      </>
    );
  }

  const TextSlide = ({ text }) => {
    return <p>{text}</p>;
  };
  return (
    <>
      <div className="mainCon">
        <Header />

        <div className="innerBanner event">
          <div className="container flexBox">
            <div>
              <div className="innerHd">Events</div>
              <div className="bradcrumb">
                <a href="/">Home</a>
                <a href="/event-management">Events</a>
              </div>
            </div>
          </div>
        </div>

        <div className="pb50 grayBg">
          <div className="container">
            <div className="whtBox">
              {/* <EventTab /> */}
              <ul className="eventTab flexBox">
                <li key="upcoming-event1">
                  <NavLink to="/club-upcoming">Upcoming Events</NavLink>
                </li>
                <li key="all-event1">
                  <NavLink to="/club-result">Completed Events</NavLink>
                </li>
              </ul>

              <div>
                <div className="flexBox">
                  <div className="raceLft">
                    {/* <p className="eventContent">Coming Soon.... </p> */}
                    <div className="flexBox nowrap itemCenter districtSelectBox">
                      District
                      <select onChange={handleDistricFilter} defaultValue="">
                        <option value="">All District</option>
                        {distric.map((distVal) => {
                          return (
                            <option
                              key={distVal.district_id}
                              selected={
                                currentDistric == distVal.district_id
                                  ? "selected"
                                  : ""
                              }
                              value={distVal.district_id}
                            >
                              D{distVal.district_id}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    {isLoder ? (
                      <div className="text-center mt-4 loadMore">
                        <Spinner color="primary" />
                      </div>
                    ) : (
                      <>
                        <ul className="clubList">
                          {clubDetails.length > 0 ? (
                            clubDetails
                              ?.filter(
                                (value) => value.event_status == "completed"
                              )
                              .map((value) => {
                                return (
                                  <li
                                    key={value.id}
                                    className={
                                      selectedClub === value.id
                                        ? "selected"
                                        : ""
                                    }
                                    onClick={() =>
                                      handleClubResult(
                                        value.id,
                                        value.full_title
                                      )
                                    }
                                  >
                                    <div className="Info">
                                      <a
                                        href="#"
                                        className={
                                          selectedClub === value.id
                                            ? "selected"
                                            : "baffff"
                                        }
                                      >
                                        {value.full_title}
                                        <br />
                                        {moment(value.event_start).format(
                                          "  MMM D YYYY"
                                        )}{" "}
                                        -{" "}
                                        {moment(value.event_end).format(
                                          "  MMM D YYYY"
                                        )}
                                        <span>D{value.district_id}</span>
                                      </a>
                                    </div>
                                  </li>
                                );
                              })
                          ) : (
                            <div className="noEvent">
                              Not Found Any Race Result.
                            </div>
                          )}
                        </ul>

                        {totalRecord > 10 && (
                          <Pagination
                            activePage={page}
                            totalItemsCount={totalRecord}
                            onChange={handlePageChange}
                          />
                        )}
                      </>
                    )}
                  </div>
                  <div className="raceRgt">
                    {leaderLoaderDetails.length > 0 && (
                      <div className="flexBox nowrap spacebetween rrTop">
                        <div></div>
                        <div>
                          <a
                            href="#"
                            className="printBtn"
                            onClick={handlePrint}
                          >
                            <span className="material-icons-outlined">
                              print
                            </span>{" "}
                            Print
                          </a>
                        </div>
                      </div>
                    )}

                    <div ref={componentRef}>
                      {isLoder ? (
                        <div className="text-center mt-4 loadMore">
                          <Spinner color="primary" />
                        </div>
                      ) : (
                        <>
                          {scoreRacer?.length !== 0 ? (
                            scoreRacer?.map((value, index) => {
                              if (
                                !uniqueClassNames.includes(value.class_Name)
                              ) {
                                uniqueClassNames.push(value.class_Name);

                                return (
                                  <>
                                    <div className="rsultTable" key={index}>
                                      {index == 0 ? (
                                        <div className="d-flex justify-content-end p-2">
                                          <Button
                                            variant="outlined"
                                            // onClick={handleClickOpen}
                                            onClick={() =>
                                              handleOpenDialog(index)
                                            }
                                          >
                                            Feeds
                                          </Button>
                                          <BootstrapDialog
                                            className="dilogCon"
                                            onClose={handleCloseDialog}
                                            // onClose={() => handleCloseDialog(index)}
                                            aria-labelledby="customized-dialog-title"
                                            open={openDialogIndex === index}
                                          >
                                            <div className="clubDilog">
                                              <DialogTitle
                                                sx={{ m: 0, p: 2 }}
                                                id="customized-dialog-title"
                                              >
                                                {title}
                                              </DialogTitle>
                                              <IconButton
                                                aria-label="close"
                                                onClick={handleCloseDialog}
                                                sx={{
                                                  position: "absolute",
                                                  right: 8,
                                                  top: 8,
                                                  color: (theme) =>
                                                    theme.palette.grey[500],
                                                }}
                                              >
                                                <CloseIcon />
                                              </IconButton>
                                              <DialogContent dividers>
                                                {isLoading ? (
                                                  <div className="text-center mt-4 loadMore">
                                                    <Spinner color="primary" />
                                                  </div>
                                                ) : (
                                                  <div>
                                                    {feedData?.length !== 0 ? (
                                                      feedData?.map(
                                                        (entry, index) => {
                                                          return (
                                                            <div key={index}>
                                                              {entry?.case ===
                                                              8 ? (
                                                                processData(
                                                                  entry?.feed_text,
                                                                  entry?.updated_at
                                                                )
                                                              ) : (
                                                                <TextSlide
                                                                  text={`${
                                                                    entry?.feed_text
                                                                  } ${formatDateTime(
                                                                    entry?.updated_at
                                                                  )}`}
                                                                />
                                                              )}
                                                            </div>
                                                          );
                                                        }
                                                      )
                                                    ) : (
                                                      <div>
                                                        Not Found Any Feed
                                                      </div>
                                                    )}
                                                  </div>
                                                )}
                                              </DialogContent>
                                            </div>
                                          </BootstrapDialog>
                                        </div>
                                      ) : (
                                        ""
                                      )}

                                      <div key={index}>
                                        <h5
                                          style={{
                                            marginLeft: "15px",
                                          }}
                                        >
                                          {value.class_Name}
                                        </h5>

                                        <table cellpadding="0" cellspacing="0">
                                          <tr>
                                            <th
                                              className="tal"
                                              style={{
                                                textAlign: "left",
                                                paddingLeft: "15px",
                                                width: "30%",
                                              }}
                                            >
                                              Drivers
                                            </th>
                                            <th
                                              className="tar"
                                              style={{
                                                textAlign: "right",
                                                width: "10%",
                                              }}
                                            >
                                              R1
                                            </th>
                                            <th
                                              className="tar"
                                              style={{
                                                textAlign: "right",
                                                width: "10%",
                                              }}
                                            >
                                              R2
                                            </th>
                                            <th
                                              className="tar"
                                              style={{
                                                textAlign: "right",
                                                width: "10%",
                                              }}
                                            >
                                              R3
                                            </th>

                                            {totalRounds >= 4 ? (
                                              <th
                                                className="tar"
                                                style={{
                                                  textAlign: "right",
                                                  width: "10%",
                                                }}
                                              >
                                                R4
                                              </th>
                                            ) : (
                                              ""
                                            )}

                                            {totalRounds >= 5 ? (
                                              <th
                                                className="tar"
                                                style={{
                                                  textAlign: "right",
                                                  width: "10%",
                                                }}
                                              >
                                                R5
                                              </th>
                                            ) : (
                                              ""
                                            )}

                                            {totalRounds >= 6 ? (
                                              <th
                                                className="tar"
                                                style={{
                                                  textAlign: "right",
                                                  width: "10%",
                                                }}
                                              >
                                                R6
                                              </th>
                                            ) : (
                                              ""
                                            )}

                                            {totalRounds >= 7 ? (
                                              <th
                                                className="tar"
                                                style={{
                                                  textAlign: "right",
                                                  width: "10%",
                                                }}
                                              >
                                                R7
                                              </th>
                                            ) : (
                                              ""
                                            )}

                                            <th
                                              className="tar"
                                              style={{
                                                textAlign: "right",
                                                paddingRight: "15px",
                                                width: "10%",
                                              }}
                                            >
                                              <b>Total</b>
                                            </th>
                                            <th
                                                className="tar"
                                                style={{
                                                  textAlign: "right",
                                                  width: "10%",
                                                }}
                                              >
                                                FT
                                              </th>
                                          </tr>

                                          {scoreRacer?.map(
                                            (result, innerIndex) => {
                                              if (
                                                value.class_Name ==
                                                result.class_Name
                                              ) {
                                                return (
                                                  <tr key={innerIndex}>
                                                    <td
                                                      className="tal"
                                                      style={{
                                                        textAlign: "left",
                                                        paddingLeft: "15px",
                                                      }}
                                                    >
                                                      {result.participantName}
                                                    </td>
                                                    <td
                                                      className="tar"
                                                      style={{
                                                        textAlign: "right",
                                                      }}
                                                    >
                                                      {result.round1_Score}
                                                    </td>
                                                    <td
                                                      className="tar"
                                                      style={{
                                                        textAlign: "right",
                                                      }}
                                                    >
                                                      {result.round2_Score}
                                                    </td>
                                                    <td
                                                      className="tar"
                                                      style={{
                                                        textAlign: "right",
                                                      }}
                                                    >
                                                      {result.round3_Score}
                                                    </td>

                                                    {totalRounds >= 4 ? (
                                                      <td
                                                        className="tar"
                                                        style={{
                                                          textAlign: "right",
                                                        }}
                                                      >
                                                        {result.round4_Score}
                                                      </td>
                                                    ) : (
                                                      ""
                                                    )}

                                                    {totalRounds >= 5 ? (
                                                      <td
                                                        className="tar"
                                                        style={{
                                                          textAlign: "right",
                                                        }}
                                                      >
                                                        {result.round5_Score}
                                                      </td>
                                                    ) : (
                                                      ""
                                                    )}

                                                    {totalRounds >= 6 ? (
                                                      <td
                                                        className="tar"
                                                        style={{
                                                          textAlign: "right",
                                                        }}
                                                      >
                                                        {result.round6_Score}
                                                      </td>
                                                    ) : (
                                                      ""
                                                    )}

                                                    {totalRounds >= 7 ? (
                                                      <td
                                                        className="tar"
                                                        style={{
                                                          textAlign: "right",
                                                        }}
                                                      >
                                                        {result.round7_Score}
                                                      </td>
                                                    ) : (
                                                      ""
                                                    )}
                                                    <td
                                                      className="tar"
                                                      style={{
                                                        textAlign: "right",
                                                        paddingRight: "15px",
                                                      }}
                                                    >
                                                      <b>{result.total}</b>
                                                    </td>
                                                    <td
                                                        className="tar"
                                                        style={{
                                                          textAlign: "right",
                                                        }}
                                                      >
                                                        {findMinFastTime(result.fast_time)}
                                                      </td>
                                                  </tr>
                                                );
                                              }
                                            }
                                          )}
                                        </table>
                                      </div>
                                    </div>
                                  </>
                                );
                              }
                            })
                          ) : (
                            <div className="resultTbleImg1">
                              <div className="d-flex justify-content-end p-2">
                                <Button
                                  variant="outlined"
                                  onClick={handleClickOpen}
                                >
                                  Feeds
                                </Button>
                                <BootstrapDialog
                                  onClose={handleClose}
                                  aria-labelledby="customized-dialog-title"
                                  open={open}
                                >
                                  <div className="clubDilog">
                                    <DialogTitle
                                      sx={{ m: 0, p: 2 }}
                                      id="customized-dialog-title"
                                    >
                                      {title}
                                    </DialogTitle>
                                    <IconButton
                                      aria-label="close"
                                      onClick={handleCloseDialog}
                                      sx={{
                                        position: "absolute",
                                        right: 8,
                                        top: 8,
                                        color: (theme) =>
                                          theme.palette.grey[500],
                                      }}
                                    >
                                      <CloseIcon />
                                    </IconButton>
                                    <DialogContent dividers>
                                      {/* <div className="dilogSliderBox"> */}
                                      {feedData?.length !== 0 ? (
                                        feedData?.map((entry, index) => {
                                          return (
                                            <div key={index}>
                                              {entry?.case === 8 ? (
                                                processData(
                                                  entry?.feed_text,
                                                  entry?.updated_at
                                                )
                                              ) : (
                                                <TextSlide
                                                  text={`${
                                                    entry?.feed_text
                                                  } ${formatDateTime(
                                                    entry?.updated_at
                                                  )}`}
                                                />
                                              )}
                                            </div>
                                          );
                                        })
                                      ) : (
                                        <div>Not Found Any Feed</div>
                                      )}
                                    </DialogContent>
                                  </div>
                                  <IconButton
                                    aria-label="close"
                                    onClick={handleClose}
                                    sx={{
                                      position: "absolute",
                                      right: 8,
                                      top: 8,
                                      color: (theme) => theme.palette.grey[500],
                                    }}
                                  >
                                    <CloseIcon />
                                  </IconButton>
                                  <DialogContent dividers>
                                    {feedData?.length !== 0
                                      ? feedData?.map((entry, index) => {
                                          return (
                                            <div key={index}>
                                              {entry?.case === 8 ? (
                                                processData(
                                                  entry?.feed_text,
                                                  entry?.updated_at
                                                )
                                              ) : (
                                                <TextSlide
                                                  text={`${
                                                    entry?.feed_text
                                                  } ${formatDateTime(
                                                    entry?.updated_at
                                                  )}`}
                                                />
                                              )}
                                            </div>
                                          );
                                        })
                                      : ""}
                                  </DialogContent>
                                </BootstrapDialog>
                              </div>
                              <img src={scrimg} alt="Score Not Found" />
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};
export default Club_result;
