import React, { useState, useEffect, useRef } from "react";
import Header from "../../Components/Layout/Header/Header";
import Footer from "../../Components/Layout/Footer/Footer";
import EventTab from "../../Components/EventTab/EventTab";
import combinedServices from "../../shared/services/user-service";
import getLocalData from "../../shared/localStorage/getData";
import Pagination from "react-js-pagination";
import { NavLink } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Spinner } from "reactstrap";
import { useReactToPrint, PrintContextConsumer } from "react-to-print";
import moment from "moment-timezone";

import "./Club_result.scss";
const Club_result1 = () => {
  const componentRef = useRef(null);
  const [classes, setClasses] = useState();
  const [selectedClass, setSelectedClass] = useState();
  const [scoreRacer, setScore] = useState();
  const [userId, setUserId] = useState("");
  const [clubDetails, setClubDetails] = useState([]);
  const [distric, setDistric] = useState([]);
  const [pageRecord, setPageRecord] = useState(10);
  const [page, setPage] = useState(1);
  const [totalRecord, setTotalRecord] = useState("");
  const [leaderLoaderDetails, setLeaderLoaderDetails] = useState([]);
  const [title, setTitle] = useState("");
  const [currentDistric, setCurrentDistric] = useState("");
  const [isLoder, setIsLoder] = useState(true);
  const [isPrinter, setIsPrinter] = useState(false);
  const [className, setClassName] = useState();
  const [scrimg, setScrimg] = useState("");
  const [selectedClub, setSelectedClub] = useState(null);
  const params = useParams();
  const [event_id, setEvent_id] = useState(params.id);
  const scorenImg = "https://nambaweb.s3.ap-south-1.amazonaws.com/frontend-static-image/scorenotfound.png"
  const uniqueClassNames = [];
  useEffect(async () => {
    setEvent_id(params.id)
    var data = {
      page: page,
      records_per_page: pageRecord,
    };
    if (getLocalData("user_info", "isLogin")) {
      var user_id = getLocalData("user_info", "all").userData.login_user_detail
        .data.user.id;
      var userDistricId = getLocalData("user_info", "all").userData
        .login_user_detail.data.user.district.uzip_namba_district;
      setCurrentDistric(userDistricId);
      setUserId(user_id);
      data.district_id = userDistricId;
    }

    let clubResponse = await combinedServices.getEventNamesWithClass(data);


    let score = await combinedServices.getScore(event_id);

    if (score.length == 0) {
      setScrimg(scorenImg);
    }

    let val = score;
    const sortedData = score.sort((a, b) => {
      if (a.class_Name === b.class_Name) {
        return b.total - a.total;
      }
      return a.class_Name.localeCompare(b.class_Name);
    });

    setScore(sortedData);

    let event = await combinedServices.getClassByEventId(event_id);
    setClasses(event.data.classes);

    if (clubResponse.status == 200) {

      // clubResponse.data.map((myResult, index) => {
      //   // if (index == 0) {
      //   //   handleClubResult(myResult.id, myResult.full_title);
      //   // }
      // });
      let clubres = clubResponse.data.filter((x) => x.event_status == "completed")
      setIsLoder(false);
      setClubDetails(clubres);
      setTotalRecord(clubres.length);
    }

    getAllDistricList();
  }, []);

  const getAllDistricList = async () => {
    let response = await combinedServices.getAllDistric();
    setDistric(response.data);
  };

  const handleSelectClass = async (e) => {
    setSelectedClass(e.target.value);
  };
  const handleDistricFilter = async (e) => {
    var clubDetails = [];
    setTitle("");
    setIsLoder(true);
    setCurrentDistric(e.target.value);
    var data = {
      page: 1,
      records_per_page: pageRecord,
    };
    if (e.target.value) {
      data.district_id = e.target.value;
    }
    let clubResponse = await combinedServices.getEventNamesWithClass(data);
    if (clubResponse.status == 200) {
      let clubres = clubResponse.data.filter((x) => x.event_status == "completed")
      setIsLoder(false);
      setClubDetails(clubres);
      setTotalRecord(clubres.length);
      clubResponse.data.map((myResult, index) => {
        if (index == 0) {
          handleClubResult(myResult.id, myResult.full_title);
        }
      });
    }
  };

  const handleClubResult = async (event_id, event_title) => {
    setTitle(event_title);
    setSelectedClub(event_id);

    var data = {
      event_id: event_id,
      //user_id: userId
    };

    let myLeaderBoardResponse = await combinedServices.getLeaderBoardDetails(
      data
    );
    let score = await combinedServices.getScore(event_id);

    if (score.length == 0) {
      setScrimg(scorenImg);
    }

    let val = score;
    const sortedData = score.sort((a, b) => {
      if (a.class_Name === b.class_Name) {
        return b.total - a.total;
      }
      return a.class_Name.localeCompare(b.class_Name);
    });

    setScore(sortedData);

    let event = await combinedServices.getClassByEventId(event_id);
    setClasses(event.data.classes);

    setLeaderLoaderDetails(myLeaderBoardResponse.data.leaderboard);
  };

  const handlePageChange = async (pageNumber) => {
    var clubDetails = [];
    setPage(pageNumber);
    setIsLoder(true);

    var data = {
      page: pageNumber,
      records_per_page: pageRecord,
    };
    if (currentDistric) {
      data.district_id = currentDistric;
    }

    let clubResponse = await combinedServices.getEventNamesWithClass(data);
    if (clubResponse.status == 200) {
      let clubres = clubResponse.data.filter((x) => x.event_status == "completed")
      setIsLoder(false);
      setClubDetails(clubres);
      setTotalRecord(clubres.length);
      clubres.map((myResult, index) => {
        if (index == 0) {
          handleClubResult(myResult.id, myResult.full_title);
        }
      });
    }
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <>
      <div className="mainCon">
        <Header />

        <div className="innerBanner event">
          <div className="container flexBox">
            <div>
              <div className="innerHd">Events</div>
              <div className="bradcrumb">
                <a href="/">Home</a>
                <a href="/event-management">Events</a>
              </div>
            </div>
          </div>
        </div>

        <div className="pb50 grayBg">
          <div className="container">
            <div className="whtBox">
              {/* <EventTab /> */}
              <ul className="eventTab flexBox">

                <li key="upcoming-event1"><NavLink to="/upcoming-event">Upcoming Events</NavLink></li>
                <li key="all-event1"><NavLink to="/club-result">Completed Events</NavLink></li>
                {/* <li key="club1"><NavLink to="/club">Clubs</NavLink></li>
                <li key="club-result1"><NavLink to="/club-result">Race Results</NavLink></li> */}
              </ul>

              <div>
                <div className="flexBox">
                  <div className="raceLft">
                    {/* <p className="eventContent">Coming Soon.... </p> */}
                    <div className="flexBox nowrap itemCenter districtSelectBox">
                      District
                      <select onChange={handleDistricFilter} defaultValue="">
                        <option value="">All District</option>
                        {distric.map((distVal) => {
                          return (
                            <option
                              key={distVal.district_id}
                              selected={
                                currentDistric == distVal.district_id
                                  ? "selected"
                                  : ""
                              }
                              value={distVal.district_id}
                            >
                              D{distVal.district_id}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    {isLoder ? (
                      <div className="text-center mt-4 loadMore">
                        <Spinner color="primary" />
                      </div>
                    ) : (
                      <>
                        <ul className="clubList">
                          {clubDetails.length > 0 ? (
                            clubDetails.filter((value) => value.event_status == "completed").map((value) => {
                              return (
                                <li
                                  key={value.id}
                                  className={
                                    selectedClub == null ?
                                      selectedClub === value.id || value.id == event_id ? "selected" : "" :
                                      selectedClub === value.id ? "selected" : null

                                  }
                                  onClick={() =>
                                    handleClubResult(value.id, value.full_title)
                                  }
                                >
                                  <div className="Info">
                                    <a
                                      href="#"
                                      className={
                                        selectedClub == null ?
                                          selectedClub === value.id || value.id == event_id ? "selected" : "" :
                                          selectedClub === value.id ? "selected" : null
                                      }
                                    >
                                      {value.full_title}
                                      <br />
                                      {moment(value.event_start).format(
                                        "  MMM D YYYY"
                                      )}{" "}
                                      -{" "}
                                      {moment(value.event_end).format(
                                        "  MMM D YYYY"
                                      )}
                                      <span>D{value.district_id}</span>
                                    </a>
                                  </div>
                                </li>
                              );
                            })
                          ) : (
                            <div className="noEvent">
                              Not Found Any Race Result.
                            </div>
                          )}
                        </ul>

                        {totalRecord > 10 && (
                          <Pagination
                            activePage={page}
                            totalItemsCount={totalRecord}
                            onChange={handlePageChange}
                          />
                        )}
                      </>
                    )}
                  </div>
                  <div className="raceRgt">
                    {leaderLoaderDetails.length > 0 && (
                      <div className="flexBox nowrap spacebetween rrTop">
                        <div></div>
                        <div>
                          <a
                            href="#"
                            className="printBtn"
                            onClick={handlePrint}
                          >
                            <span className="material-icons-outlined">
                              print
                            </span>{" "}
                            Print
                          </a>
                        </div>
                      </div>
                    )}

                    <div ref={componentRef}>
                      {scoreRacer?.length !== 0 ? (
                        scoreRacer?.map((value, index) => {
                          if (!uniqueClassNames.includes(value.class_Name)) {
                            uniqueClassNames.push(value.class_Name);

                            return (
                              <div className="rsultTable">

                                <div key={index}>
                                  <h5
                                    style={{
                                      marginTop: "25px",
                                      marginLeft: "15px",
                                    }}
                                  >
                                    {value.class_Name}
                                  </h5>

                                  <table cellpadding="0" cellspacing="0">
                                    <tr>
                                      <th
                                        className="tal"
                                        style={{
                                          textAlign: "left",
                                          paddingLeft: "15px",
                                        }}
                                      >
                                        Driver
                                      </th>
                                      <th
                                        className="tar"
                                        style={{ textAlign: "center" }}
                                      >
                                        R1
                                      </th>
                                      <th
                                        className="tar"
                                        style={{ textAlign: "center" }}
                                      >
                                        R2
                                      </th>
                                      <th
                                        className="tar"
                                        style={{ textAlign: "center" }}
                                      >
                                        R3
                                      </th>
                                      <th
                                        className="tar"
                                        style={{ textAlign: "center" }}
                                      >
                                        R4
                                      </th>
                                      <th
                                        className="tar"
                                        style={{
                                          textAlign: "center",
                                          paddingRight: "15px",
                                        }}
                                      >
                                        <b>Total</b>
                                      </th>
                                    </tr>
                                    {scoreRacer?.map((result, innerIndex) => {
                                      if (
                                        value.class_Name == result.class_Name
                                      ) {
                                        return (
                                          <tr key={innerIndex}>
                                            <td
                                              className="tal"
                                              style={{
                                                textAlign: "left",
                                                paddingLeft: "15px",
                                              }}
                                            >
                                              {result.participantName}
                                            </td>
                                            <td
                                              className="tar"
                                              style={{ textAlign: "center" }}
                                            >
                                              {result.round1_Score}
                                            </td>
                                            <td
                                              className="tar"
                                              style={{ textAlign: "center" }}
                                            >
                                              {result.round2_Score}
                                            </td>
                                            <td
                                              className="tar"
                                              style={{ textAlign: "center" }}
                                            >
                                              {result.round3_Score}
                                            </td>
                                            <td
                                              className="tar"
                                              style={{ textAlign: "center" }}
                                            >
                                              {result.round4_Score}
                                            </td>
                                            <td
                                              className="tar"
                                              style={{
                                                textAlign: "center",
                                                paddingRight: "15px",
                                              }}
                                            >
                                              <b>{result.total}</b>
                                            </td>
                                          </tr>
                                        );
                                      }
                                    })}
                                  </table>
                                </div>
                              </div>
                            );
                          }
                        })
                      ) : (
                        <div className="resultTbleImg1">
                          <img src={scrimg} alt="Score Not Found" />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};
export default Club_result1;
