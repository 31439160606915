import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import combinedServices from "../../shared/services/user-service";

import {
  ExcelExport,
  ExcelExportColumn,
} from "@progress/kendo-react-excel-export";

export default function DownloadScoreExcel() {
  const eventId = useLocation().pathname.split("/")[2];
  const [eventName, setEventName] = useState("");
  const [totalRounds, setTotalRounds] = useState(4);
  const [finalScoreArray, setFinalScoreArray] = useState([]);
  const exportRef = React.useRef();
  const exportData = [];

  useEffect(async () => {
    const eventDetails = await combinedServices.getEventDetail(eventId);
    setEventName(eventDetails.data.event_full_title);
    setTotalRounds(eventDetails.data.event_rounds_per_class);
    const scoreResponse = await combinedServices.getScoreForFeed({
      eventId: eventId,
    });


    const headerRow = {
      Driver: "Driver",
      Round1: "R1",
      Round2: "R2",
      Round3: "R3",
      Round4: "R4",
      Round5: "R5",
      Round6: "R6",
      Round7: "R7",
      Total: "Total",
    };
    exportData.push(headerRow);
    if (scoreResponse.data.data.length > 0) {
      for (let cls of scoreResponse.data.data) {
        const data = {
          Driver: cls.class_name,
          Round1: "",
          Round2: "",
          Round3: "",
          Round4: "",
          Round5: "",
          Round6: "",
          Round7: "",
          Total: "",
        };
        exportData.push(data);
        cls.participants.sort((a,b) => {
          // Convert usernames to lowercase for case-insensitive sorting
          let usernameA = a.participantName.toLowerCase();
          let usernameB = b.participantName.toLowerCase();

          if (usernameA < usernameB) {
            return -1;
          }
          if (usernameA > usernameB) {
            return 1;
          }
          return 0; // usernames are equal
        });
        for (let participant of cls.participants) {
          const data = {
            Driver: participant.participantName,
            Round1: participant.round1.Score,
            Round2: participant.round2.Score,
            Round3: participant.round3.Score,
            Round4: participant.round4.Score,
            Round5: participant.round5.Score,
            Round6: participant.round6.Score,
            Round7: participant.round7.Score,
            Total: participant.totalScore,
          };
          exportData.push(data);
        }
        const space = {
          Driver: "",
          Round1: "",
          Round2: "",
          Round3: "",
          Round4: "",
          Round5: "",
          Round6: "",
          Round7: "",
          Total: "",
        };
        exportData.push(space);
      }
      setFinalScoreArray(exportData);
    }
  }, []);

  const handleDownload = () => {
    exportRef.current.save();
  };

  return (
    <div>
      <button
        onClick={handleDownload}
        style={{
          backgroundColor: "#0095fd",
          color: "white",
          padding: "10px",
          borderRadius: "8px",
        }}
      >
        Download Excel
      </button>
      <ExcelExport
        data={finalScoreArray.slice(1)} // Exclude the first row (fixed row)
        ref={exportRef}
        fileName={`${eventName}_score.xlsx`}
        filterable={true}
        groupable={true}
      >
        <ExcelExportColumn
          field="Driver"
          title="Driver"
          width={200}

          // cellOptions={{
          //   bold: true, // Apply bold formatting to the Driver column cells
          // }}
        />

        <ExcelExportColumn
          field="Round1"
          title="R1"
          width={100}
          headerCellOptions={{
            textAlign: "right",
          }}
        />
        <ExcelExportColumn
          field="Round2"
          title="R2"
          width={100}
          headerCellOptions={{
            textAlign: "right",
          }}
        />
        <ExcelExportColumn
          field="Round3"
          title="R3"
          width={100}
          headerCellOptions={{
            textAlign: "right",
          }}
        />
        <ExcelExportColumn
          field="Round4"
          title="R4"
          width={100}
          headerCellOptions={{
            textAlign: "right",
          }}
        />
        {totalRounds >= 5 ? (
          <ExcelExportColumn
            field="Round5"
            title="R5"
            width={100}
            headerCellOptions={{
              textAlign: "right",
            }}
          />
        ) : (
          ""
        )}
        {totalRounds >= 6 ? (
          <ExcelExportColumn
            field="Round6"
            title="R6"
            width={100}
            headerCellOptions={{
              textAlign: "right",
            }}
          />
        ) : (
          ""
        )}
        {totalRounds >= 7 ? (
          <ExcelExportColumn
            field="Round7"
            title="R7"
            width={100}
            headerCellOptions={{
              textAlign: "right",
            }}
          />
        ) : (
          ""
        )}
        <ExcelExportColumn field="Total" title="Total" width={100} />
      </ExcelExport>
    </div>
  );
}
